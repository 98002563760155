<template lang="">
   <div class="wrapper">
      <!-- Sidebar  -->
      <LayoutManu></LayoutManu>

      <!-- Page Content  -->
      <div id="content-page" class="content-page">
         <!-- Page Content  -->
         <LayoutNavbar></LayoutNavbar>

         <div class="container-fluid">
            <div class="row">
               <!-- Left Column -->
               <div class="col-sm-12">
                  <div class="iq-card">
                     <div class="iq-card border border-primary">
                        <div class="iq-card-body">
                           <form>
                              <form>
                                 <div class="row">
                                    <div class="col-md-1">
                                       <label for="branch_cmb" class="">Branch</label>
                                    </div>
                                    <div class="col-md-4">
                                       <Select2 class="js-example-basic-single font-weight-bold" id="branch_cmb" name="branch_cmb"></Select2>
                                    </div>
                                    <div class="col-md-1">
                                       <label for="start_date_dtmp" class="">Date</label>
                                    </div>
                                    <div class="col-md-4">
                                       <input
                                          type="date"
                                          class="form-control form-control-sm font-weight-bold"
                                          id="start_date_dtmp"
                                          :value="getCurrentDate()"
                                       />
                                    </div>
                                    <div class="col-md-1">
                                       <input class="btn btn-danger btn-sm btn-block" type="button" id="refresh_btn" value="Refresh" />
                                    </div>
                                 </div>
                              </form>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="container-fluid">
            <div class="row">
               <div class="col-lg-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-3">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-sharp fa-solid fa-inbox-in text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Total Income</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="total_income_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-3">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-sharp fa-solid fa-inbox-out text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Total Expenses</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="total_expense_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-3">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-solid fa-money-check-dollar text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Total Bank Balance</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="total_bank_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-3">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-sharp fa-solid fa-sack-dollar text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Total Cash Balance</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="total_cash_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 col-lg-12">
                  <div class="iq-card">
                     <div class="iq-card-body">
                        <ul class="nav nav-pills mb-3 nav-fill" id="transaction-history-tabs" role="tablist">
                           <li class="nav-item">
                              <a
                                 class="nav-link active"
                                 id="all-transaction-tab"
                                 data-toggle="pill"
                                 href="#all-transaction-content"
                                 role="tab"
                                 aria-controls="all-transaction"
                                 aria-selected="true"
                                 >All Transaction History</a
                              >
                           </li>
                           <li class="nav-item">
                              <a
                                 class="nav-link"
                                 id="income-history-tab"
                                 data-toggle="pill"
                                 href="#income-history-content"
                                 role="tab"
                                 aria-controls="income-history"
                                 aria-selected="false"
                                 >All Income History</a
                              >
                           </li>
                           <li class="nav-item">
                              <a
                                 class="nav-link"
                                 id="expenses-history-tab"
                                 data-toggle="pill"
                                 href="#expenses-history-content"
                                 role="tab"
                                 aria-controls="expenses-history"
                                 aria-selected="false"
                                 >All Expenses History</a
                              >
                           </li>
                           <li class="nav-item">
                              <a
                                 class="nav-link"
                                 id="transaction-summary-tab"
                                 data-toggle="pill"
                                 href="#transaction-summary-content"
                                 role="tab"
                                 aria-controls="transaction-summary"
                                 aria-selected="false"
                                 >Account Summary</a
                              >
                           </li>
                        </ul>
                        <div class="tab-content" id="transaction-history-tab-content">
                           <div class="tab-pane fade show active" id="all-transaction-content" role="tabpanel" aria-labelledby="all-transaction-tab">
                              <div class="table-responsive">
                                 <table id="lab_all_transaction_table" class="table table-hover table-bordered display nowrap">
                                    <thead>
                                       <tr>
                                          <th>Transaction No</th>
                                          <th>Type</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Description</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>User</th>
                                          <th>Branch</th>
                                       </tr>
                                    </thead>
                                    <tbody> </tbody>
                                    <tfoot>
                                       <tr>
                                          <th>Transaction No</th>
                                          <th>Type</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Description</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>User</th>
                                          <th>Branch</th>
                                       </tr>
                                    </tfoot>
                                 </table>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="income-history-content" role="tabpanel" aria-labelledby="income-history-tab">
                              <div class="table-responsive">
                                 <table id="lab_all_income_transaction_table" class="table table-hover table-bordered display nowrap">
                                    <thead>
                                       <tr>
                                          <th>Transaction No</th>
                                          <th>Type</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Description</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>User</th>
                                          <th>Branch</th>
                                       </tr>
                                    </thead>
                                    <tbody> </tbody>
                                    <tfoot>
                                       <tr>
                                          <th>Transaction No</th>
                                          <th>Type</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Description</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>User</th>
                                          <th>Branch</th>
                                       </tr>
                                    </tfoot>
                                 </table>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="expenses-history-content" role="tabpanel" aria-labelledby="expenses-history-tab">
                              <table id="lab_all_expenses_transaction_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>Transaction No</th>
                                       <th>Type</th>
                                       <th>Debit</th>
                                       <th>Credit</th>
                                       <th>Description</th>
                                       <th>Date</th>
                                       <th>Time</th>
                                       <th>User</th>
                                       <th>Branch</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>Transaction No</th>
                                       <th>Type</th>
                                       <th>Debit</th>
                                       <th>Credit</th>
                                       <th>Description</th>
                                       <th>Date</th>
                                       <th>Time</th>
                                       <th>User</th>
                                       <th>Branch</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                           <div class="tab-pane fade" id="transaction-summary-content" role="tabpanel" aria-labelledby="transaction-summary-tab">
                              <table class="table table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th class="font-weight-bold text-dark">Subject</th>
                                       <th class="font-weight-bold text-dark text-right">Bank</th>
                                       <th class="font-weight-bold text-dark text-right">Cash</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td class="font-weight-bold text-success">Total Income</td>
                                       <td id="td_bank_income_lbl" class="font-weight-bold text-success text-right">0</td>
                                       <td id="td_cash_income_lbl" class="font-weight-bold text-success text-right">0</td>
                                    </tr>
                                    <tr>
                                       <td class="font-weight-bold text-danger">Total Expenses</td>
                                       <td id="td_bank_expenses_lbl" class="font-weight-bold text-danger text-right">0</td>
                                       <td id="td_cash_expenses_lbl" class="font-weight-bold text-danger text-right">0</td>
                                    </tr>
                                    <tr>
                                       <td class="font-weight-bold text-dark">Day End Balance (Income - Expenses)</td>
                                       <td id="td_bank_balance_lbl" class="font-weight-bold text-dark text-right">0</td>
                                       <td id="td_cash_balance_lbl" class="font-weight-bold text-dark text-right">0</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <LayoutFooterVue></LayoutFooterVue>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS
import Chart from 'chart.js/auto'

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'acc-dash', ['active', 'main-active'])
      this.updateElementClasses('sub', 'account-report-daily', ['active'])

      $(document).ready(function () {
         // ? clear values
         clearValues()

         // ? get all branch names
         get_branch_names_id()

         // ? clear values
         function clearValues() {
            $('#total_income_lbl').text('Rs. 0.00')
            $('#total_expense_lbl').text('Rs. 0.00')
            $('#gross_profit_lbl').text('Rs. 0.00')
         }

         // ? get all branch names
         function get_branch_names_id() {
            $.ajax({
               url: `${API_URL}branch/branch-name/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#branch_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // Add the option for "All Branches"
                     const allBranchesOption = new Option('All Branches', 'B')
                     selectDropdown.append(allBranchesOption)

                     // ? Append new options
                     data.forEach(branch => {
                        const option = new Option(branch.brach_name, branch.brach_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     selectDropdown.val(selectDropdown.find('option:eq(0)').val()).trigger('change')
                  }
               },
               error: function (error) {
                  console.error('Error fetching branch name:', error)
               },
            })
         }

         // ? brach select change event
         $('#branch_cmb').on('change', function () {
            // ? clear data
            clearValues()

            view_gl_transaction_data_by_income_expenses()
         })

         $('#all-transaction-tab').on('click', function (e) {
            e.preventDefault()
            view_gl_transaction_data_by_income_expenses()
         })

         $('#income-history-tab').on('click', function (e) {
            e.preventDefault()
            view_gl_transaction_data_by_type('R', 'lab_all_income_transaction_table')
         })

         $('#expenses-history-tab').on('click', function (e) {
            e.preventDefault()
            view_gl_transaction_data_by_type('V', 'lab_all_expenses_transaction_table')
         })

         $('#transaction-summary-tab').on('click', function (e) {
            e.preventDefault()

            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#start_date_dtmp').val()

            get_total_income_expenses_cash_bank_balance_data(start_date, end_date, branch_id)
         })

         // ? get total income expenses gross profit data
         function get_total_income_expenses_cash_bank_balance_data(start_date, end_date, branch_id) {
            $.ajax({
               url: `${API_URL}` + 'account-dashboard/get-total-income-expenses-cash-bank-balance/' + start_date + '/' + end_date + '/' + branch_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#total_income_lbl').text(formatNumberWithCommas(data['total_income']))
                  $('#total_expense_lbl').text(formatNumberWithCommas(data['total_expense']))
                  $('#total_bank_lbl').text(formatNumberWithCommas(data['net_balance_bank']))
                  $('#total_cash_lbl').text(formatNumberWithCommas(data['net_balance_cash']))

                  // ? table value change
                  $('#td_bank_income_lbl').text(formatNumberWithCommas(data['net_income_balance_bank']))
                  $('#td_cash_income_lbl').text(formatNumberWithCommas(data['net_income_balance_cash']))

                  // Update Total Expenses
                  $('#td_bank_expenses_lbl').text(formatNumberWithCommas(data['net_expense_balance_bank']))
                  $('#td_cash_expenses_lbl').text(formatNumberWithCommas(data['net_expense_balance_cash']))

                  // Update Day End Balance
                  $('#td_bank_balance_lbl').text(formatNumberWithCommas(data['net_balance_bank']))
                  $('#td_cash_balance_lbl').text(formatNumberWithCommas(data['net_balance_cash']))
               },
            })
         }

         // ? refresh button click event
         $('#refresh_btn').on('click', () => {
            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#start_date_dtmp').val()

            // ? get total income expenses gross profit data
            get_total_income_expenses_cash_bank_balance_data(start_date, end_date, branch_id)

            view_gl_transaction_data_by_income_expenses()

            view_gl_transaction_data_by_type('V', 'lab_all_expenses_transaction_table')
            view_gl_transaction_data_by_type('R', 'lab_all_income_transaction_table')
         })

         // ? auto load the data
         setInterval(function () {
            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#start_date_dtmp').val()
            // ? get total income expenses gross profit data
            get_total_income_expenses_cash_bank_balance_data(start_date, end_date, branch_id)

            view_gl_transaction_data_by_income_expenses()

            view_gl_transaction_data_by_type('V', 'lab_all_expenses_transaction_table')
            view_gl_transaction_data_by_type('R', 'lab_all_income_transaction_table')
         }, 60000)

         // ?  view all gl transaction data by type
         function view_gl_transaction_data_by_type(tra_type, table_name) {
            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#start_date_dtmp').val()

            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}account-dashboard/view-all-transaction-by-type-monthly-dashboard/` +
                     start_date +
                     '/' +
                     end_date +
                     '/' +
                     branch_id +
                     '/' +
                     tra_type,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'gl_tra_id',
                  },
                  {
                     data: 'gl_tra_type',
                     render: function (data, type, row) {
                        if (data === 'V') {
                           return '<span class="badge badge-danger">Expenses</span>'
                        } else if (data === 'R') {
                           return '<span class="badge badge-success">Income</span>'
                        }
                     },
                  },
                  {
                     data: 'gl_tra_debit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'gl_tra_credit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'gl_tra_description',
                  },
                  {
                     data: 'gl_tra_date',
                  },
                  {
                     data: 'gl_tra_time',
                  },
                  {
                     data: 'user_fname',
                  },
                  {
                     data: 'brach_name',
                  },
               ],
               lengthMenu: [
                  [10, 20, 30, 50, 10000000],
                  ['10 rows', '20 rows', '30 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#' + table_name).DataTable(dataTableOptions)
         }

         // ?  view all gl transaction data by income and expenses
         function view_gl_transaction_data_by_income_expenses() {
            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#start_date_dtmp').val()

            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}account-dashboard/view-all-transaction-by-income-expenses-monthly-dashboard/` +
                     start_date +
                     '/' +
                     end_date +
                     '/' +
                     branch_id,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'gl_tra_id',
                  },
                  {
                     data: 'gl_tra_type',
                     render: function (data, type, row) {
                        if (data === 'V') {
                           return '<span class="badge badge-danger">Expenses</span>'
                        } else if (data === 'R') {
                           return '<span class="badge badge-success">Income</span>'
                        }
                     },
                  },
                  {
                     data: 'gl_tra_debit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'gl_tra_credit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'gl_tra_description',
                  },
                  {
                     data: 'gl_tra_date',
                  },
                  {
                     data: 'gl_tra_time',
                  },
                  {
                     data: 'user_fname',
                  },
                  {
                     data: 'brach_name',
                  },
               ],
               lengthMenu: [
                  [10, 20, 30, 50, 10000000],
                  ['10 rows', '20 rows', '30 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#lab_all_transaction_table').DataTable(dataTableOptions)
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
