import SmsPatientLogings from "../pages/sms/SmsPatientLogings.vue";
import SmsTestResults from "../pages/sms/SmsTestResults.vue";
import SmsBalanceHistory from "../pages/sms/SmsBalanceHistory.vue";

const SmsRoutes = [
    // ? patient login credentials send sms page showing
    {
        path: '/send-patient-sms',
        name: '/send-patient-sms',
        component: SmsPatientLogings,
        meta: { requiresAuth: true },
    },

    // ? patient lab test results send sms page showing
    {
        path: '/send-patient-lab-test-results-sms',
        name: '/send-patient-lab-test-results-sms',
        component: SmsTestResults,
        meta: { requiresAuth: true },
    },

    // ? sms balance and history page showing
    {
        path: '/sms-balance-history',
        name: '/sms-balance-history',
        component: SmsBalanceHistory,
        meta: { requiresAuth: true },
    }
]

export default SmsRoutes;