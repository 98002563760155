<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Branch</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div>
                              <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target=".bd-example-modal-lg">
                                 Add New Branch's Details
                              </button>
                           </div>
                           <div class="table-responsive mt-3 mb-2">
                              <table id="branch_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Edit</th>
                                       <th>Business Name</th>
                                       <th>Branch Name</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Email</th>
                                       <th>Fax</th>
                                       <th>Web</th>
                                       <th>Header</th>
                                       <th>Footer</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Edit</th>
                                       <th>Business Name</th>
                                       <th>Branch Name</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Email</th>
                                       <th>Fax</th>
                                       <th>Web</th>
                                       <th>Header</th>
                                       <th>Footer</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content" style="max-height: 80vh; overflow-y: auto">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Add New Branch</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="add_branch_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="branch_name_tbx" class="font-weight-bold"> Branch Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Branch Name" id="branch_name_tbx" required />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="business_name_tbx" class="font-weight-bold"> Business Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Business Name" id="business_name_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Location" id="location_tbx" required />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Contact" id="contact_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="fax_tbx" class="font-weight-bold"> Fax <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Fax" id="fax_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="web_tbx" class="font-weight-bold"> Web <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Web" id="web_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="email_tbx" class="font-weight-bold"> Email <span class="text-danger">*</span> </label>
                                    <input type="email" class="form-control" placeholder="Email" id="email_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="header_tbx" class="font-weight-bold"> Header <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Header" id="header_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="footer_tbx" class="font-weight-bold"> Footer <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Footer" id="footer_tbx" required />
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-primary" type="submit" id="branch_add_btn" value="Submit" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="update_branch_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content" style="max-height: 80vh; overflow-y: auto">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Update Existing Branch</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="update_branch_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_branch_name_tbx" class="font-weight-bold"> Branch Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Branch Name" id="upd_branch_name_tbx" required />
                                    <input type="hidden" class="form-control" placeholder="First Name" id="upd_branch_id_tbx" required />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_business_name_tbx" class="font-weight-bold">
                                       Business Name <span class="text-danger">*</span>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Business Name" id="upd_business_name_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Location" id="upd_location_tbx" required />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Contact" id="upd_contact_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_fax_tbx" class="font-weight-bold"> Fax <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Fax" id="upd_fax_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_web_tbx" class="font-weight-bold"> Web <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Web" id="upd_web_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_email_tbx" class="font-weight-bold"> Email <span class="text-danger">*</span> </label>
                                    <input type="email" class="form-control" placeholder="Email" id="upd_email_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_header_tbx" class="font-weight-bold"> Header <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Header" id="upd_header_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_footer_tbx" class="font-weight-bold"> Footer <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Footer" id="upd_footer_tbx" required />
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-primary" type="submit" id="branch_update_btn" value="Submit" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
// Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'
/* eslint-disable */
import $ from 'jquery'

// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'branch', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-branch', ['active'])

      // ? Load data table when the page loads
      this.$nextTick(() => {
         this.load_data_table()
      })

      // ? Attach click event to the add branch button using jQuery
      $('#branch_add_btn').on('click', () => {
         const form = document.getElementById('add_branch_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#branch_add_btn').prop('disabled', true).val('wait...')

            // ? Extract values from various input fields
            var branchName = $('#branch_name_tbx').val()
            var businessName = $('#business_name_tbx').val()
            var location = $('#location_tbx').val()
            var contact = $('#contact_tbx').val()
            var fax = $('#fax_tbx').val()
            var web = $('#web_tbx').val()
            var email = $('#email_tbx').val()
            var header = $('#header_tbx').val()
            var footer = $('#footer_tbx').val()

            // ? Create an object with the extracted values
            const branchData = {
               brach_name: branchName,
               brach_contact: contact,
               brach_header: header,
               brach_footer: footer,
               brach_location: location,
               brach_email: email,
               brach_fax: fax,
               brach_web: web,
               brach_business_name: businessName,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}branch/`,
               type: 'POST',
               contentType: 'application/json',
               data: JSON.stringify(branchData),
               success: response => {
                  // ? Reset the form
                  $('#add_branch_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'New Branch added successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#branch_add_btn').prop('disabled', false).val('Submit')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#branch_add_btn').prop('disabled', false).val('Submit')
               },
            })
         }
      })

      // ? data table update button click event handler
      $('#branch_data_table').on('click', '#edit_btn', function () {
         // ? Get the data-id attribute of the clicked button
         var branchId = $(this).data('id')

         // ? get branch data to edit and update
         get_branch_details(branchId)

         const modalElement = document.getElementById('update_branch_model')
         const modal = new bootstrap.Modal(modalElement)
         modal.show()
      })

      // ? get lab test data to edit and update
      function get_branch_details(branch_id) {
         $.ajax({
            url: `${API_URL}` + 'branch/' + branch_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#upd_branch_id_tbx').val(branch_id)
               $('#upd_branch_name_tbx').val(data['brach_name'])
               $('#upd_business_name_tbx').val(data['brach_business_name'])
               $('#upd_location_tbx').val(data['brach_location'])
               $('#upd_contact_tbx').val(data['brach_contact'])
               $('#upd_fax_tbx').val(data['brach_fax'])
               $('#upd_web_tbx').val(data['brach_web'])
               $('#upd_email_tbx').val(data['brach_email'])
               $('#upd_header_tbx').val(data['brach_header'])
               $('#upd_footer_tbx').val(data['brach_footer'])
            },
         })
      }

      // ? update lab test data click button handler
      $('#branch_update_btn').on('click', () => {
         const form = document.getElementById('update_branch_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#branch_update_btn').prop('disabled', true).val('wait...')

            // ? Extract values from various input fields
            var branchID = $('#upd_branch_id_tbx').val()
            var branchName = $('#upd_branch_name_tbx').val()
            var businessName = $('#upd_business_name_tbx').val()
            var location = $('#upd_location_tbx').val()
            var contact = $('#upd_contact_tbx').val()
            var fax = $('#upd_fax_tbx').val()
            var web = $('#upd_web_tbx').val()
            var email = $('#upd_email_tbx').val()
            var header = $('#upd_header_tbx').val()
            var footer = $('#upd_footer_tbx').val()

            // ? Create an object with the extracted values
            const branchData = {
               brach_name: branchName,
               brach_contact: contact,
               brach_header: header,
               brach_footer: footer,
               brach_location: location,
               brach_email: email,
               brach_fax: fax,
               brach_web: web,
               brach_business_name: businessName,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}branch/` + branchID,
               type: 'PUT',
               contentType: 'application/json',
               data: JSON.stringify(branchData),
               success: response => {
                  // ? Reset the form
                  $('#update_branch_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'Branch data updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#branch_update_btn').prop('disabled', false).val('Update')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#branch_update_btn').prop('disabled', false).val('Update')
               },
            })
         }
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },

      // ? view all data in data table
      load_data_table() {
         // ? DataTable options
         const dataTableOptions = {
            destroy: true,
            processing: true,
            serverSide: true,
            ajax: {
               url: `${API_URL}branch/`,
               type: 'GET',
            },
            columns: [
               { data: 'brach_id' },
               {
                  render: function (data, type, row) {
                     // ? You can customize the button or link here
                     return (
                        '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                        row.brach_id +
                        '" ><i class="fa-solid fa-pen-to-square"></i></button>'
                     )
                  },
               },
               { data: 'brach_business_name' },
               { data: 'brach_name' },
               { data: 'brach_location' },
               { data: 'brach_contact' },
               { data: 'brach_email' },
               { data: 'brach_fax' },
               { data: 'brach_web' },
               { data: 'brach_header' },
               { data: 'brach_footer' },
            ],

            lengthMenu: [
               [10, 25, 50, 10000000],
               ['10 rows', '25 rows', '50 rows', 'Show all'],
            ],
            columnDefs: [
               { width: '35%', targets: 2 },
               { width: '35%', targets: 3 },
               { width: '30%', targets: 4 },
            ],
         }

         // ? Initialize DataTable
         $('#branch_data_table').DataTable(dataTableOptions)
      },
   },
}
</script>

<style lang=""></style>
