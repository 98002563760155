<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Lab Test List</h4>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/12.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Anna Mull</h4>
                              <p class="mb-0">Cardiologists</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/13.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Paul Molive</h4>
                              <p class="mb-0">Heart Surgeons</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/14.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Terry Aki</h4>
                              <p class="mb-0">Medicine Specialists</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/15.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Poppa Cherry</h4>
                              <p class="mb-0">Family Physicians</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/12.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Anna Mull</h4>
                              <p class="mb-0">Cardiologists</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/13.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Paul Molive</h4>
                              <p class="mb-0">Heart Surgeons</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/14.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Terry Aki</h4>
                              <p class="mb-0">Medicine Specialists</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <div class="iq-card">
                        <div class="iq-card-body text-center">
                           <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" src="Assets/images/user/15.jpg" alt="profile" />
                           </div>
                           <div class="iq-doc-info mt-3">
                              <h4> Dr. Poppa Cherry</h4>
                              <p class="mb-0">Family Physicians</p>
                              <a href="javascript:void();">www.demo.com</a>
                           </div>
                           <div class="iq-doc-description mt-2">
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat</p>
                           </div>
                           <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                 <li
                                    ><a href="#"><i class="ri-facebook-fill"></i></a
                                 ></li>
                                 <li
                                    ><a href="#"><i class="ri-twitter-fill"></i></a>
                                 </li>
                                 <li
                                    ><a href="#"><i class="ri-google-fill"></i></a
                                 ></li>
                              </ul>
                           </div>
                           <a href="profile.html" class="btn btn-primary">View Profile</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- ? -->
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

export default {
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'test', ['active', 'main-active'])
      this.updateElementClasses('sub', 'all-test', ['active'])
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
