<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Lab Test Transaction Correction</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-12 mb-1">
                                 <label class="">Search By Transaction No <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="test_tra_cmb" name="test_tra_cmb"> </Select2>
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-2 mb-1">
                                 <label for="patient_contact_tbx" class="">Transaction Date</label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_contact_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Total Test Count </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_email_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="last_updated_tbx" class="">Total Payment </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="last_updated_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="due_balance_tbx" class="">Due Balance </label>
                                 <input type="number" class="form-control form-control-sm font-weight-bold" id="due_balance_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="due_balance_tbx" class="">Other Hospital Payments </label>
                                 <input type="number" class="form-control form-control-sm font-weight-bold" id="due_balance_tbx" readonly />
                              </div>
                              <div class="col-md-2 mt-4">
                                 <input class="btn btn-success" type="submit" id="correction_btn" value="Submit" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12 col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <ul class="nav nav-pills mb-3 nav-fill" id="transaction-history-tabs" role="tablist">
                              <li class="nav-item">
                                 <a
                                    class="nav-link active"
                                    id="all-transaction-tab"
                                    data-toggle="pill"
                                    href="#all-transaction-content"
                                    role="tab"
                                    aria-controls="all-transaction"
                                    aria-selected="true"
                                    >Lab Test Transaction</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="income-history-tab"
                                    data-toggle="pill"
                                    href="#income-history-content"
                                    role="tab"
                                    aria-controls="income-history"
                                    aria-selected="false"
                                    >Patient Bill Payments</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="expenses-history-tab"
                                    data-toggle="pill"
                                    href="#expenses-history-content"
                                    role="tab"
                                    aria-controls="expenses-history"
                                    aria-selected="false"
                                    >Hospital Bill Payments</a
                                 >
                              </li>
                           </ul>
                           <div class="tab-content" id="transaction-history-tab-content">
                              <div
                                 class="tab-pane fade show active"
                                 id="all-transaction-content"
                                 role="tabpanel"
                                 aria-labelledby="all-transaction-tab"
                              >
                                 <div class="table-responsive">
                                    <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Test Name</th>
                                             <th>Status</th>
                                             <th>Action</th>
                                             <th>Price</th>
                                             <th>Dis. Rt</th>
                                             <th>Dis</th>
                                             <th>Amount</th>
                                             <th>Doctor</th>
                                             <th>Hospital</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Test Name</th>
                                             <th>Status</th>
                                             <th>Action</th>
                                             <th>Price</th>
                                             <th>Dis. Rt</th>
                                             <th>Dis</th>
                                             <th>Amount</th>
                                             <th>Doctor</th>
                                             <th>Hospital</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="income-history-content" role="tabpanel" aria-labelledby="income-history-tab">
                                 <div class="table-responsive">
                                    <table id="lab_all_income_transaction_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Transaction No</th>
                                             <th>Type</th>
                                             <th>Debit</th>
                                             <th>Credit</th>
                                             <th>Description</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>User</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Transaction No</th>
                                             <th>Type</th>
                                             <th>Debit</th>
                                             <th>Credit</th>
                                             <th>Description</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>User</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="expenses-history-content" role="tabpanel" aria-labelledby="expenses-history-tab">
                                 <table id="lab_all_expenses_transaction_table" class="table table-hover table-bordered display nowrap">
                                    <thead>
                                       <tr>
                                          <th>Transaction No</th>
                                          <th>Type</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Description</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>User</th>
                                          <th>Branch</th>
                                       </tr>
                                    </thead>
                                    <tbody> </tbody>
                                    <tfoot>
                                       <tr>
                                          <th>Transaction No</th>
                                          <th>Type</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                          <th>Description</th>
                                          <th>Date</th>
                                          <th>Time</th>
                                          <th>User</th>
                                          <th>Branch</th>
                                       </tr>
                                    </tfoot>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'correction', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-test-correction', ['active'])

      $(document).ready(function () {
         // ? get all lab test transactions to dropdown
         get_lab_test_transaction_id()

         // ? get all lab test transactions to dropdown
         function get_lab_test_transaction_id() {
            $.ajax({
               url: `${API_URL}correction-entry/all-lab-transaction-with-patient-data-to-correction`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#test_tra_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(labtest => {
                        const option = new Option(labtest.lab_tra_id + ' - ' + labtest.pat_name + ' - ' + labtest.pat_contact, labtest.lab_tra_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#test_tra_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? lab test transaction select box enter button click event selected change event
         $('#test_tra_cmb').on('change', function () {
            // ? Your code for Select Patient select2 change event
            var id = $(this).val()

            // ? view lab test transaction to table model view
            lab_test_transaction_load_data_table(id)
         })

         // ? view lab test transaction to table model view
         function lab_test_transaction_load_data_table(traID) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test-transaction/after-view-data-table/` + traID,
                  type: 'GET',
               },
               columns: [
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        if (data === 'Y') {
                           return '<span class="badge badge-success">Completed</span>'
                        } else if (data === 'R') {
                           return '<span class="badge" style="background-color: darkorange; color: white;">Need To Retest</span>'
                        } else if (data === 'A') {
                           return '<span class="badge badge-primary">To Be Approved</span>'
                        } else if (data === 'C') {
                           return '<span class="badge badge-danger">Canceled</span>'
                        } else {
                           return '<span class="badge badge-warning">Pending</span>'
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        if (row.lab_tra_complete_status === 'C') {
                           return '<span class="badge badge-warning">Canceled</span>'
                        } else {
                           return '<button class="btn btn-danger btn-sm" id="remove_btn" data-id="' + row.id + '" >Remove</button>'
                        }
                     },
                  },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_discount_rate',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${
                                parseFloat(data).toLocaleString(undefined, {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                }) + '%'
                             }</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_total_discount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'amount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'doct_name' },
                  { data: 'hos_name' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // ? Initialize DataTable
            $('#lab_test_data_table').DataTable(dataTableOptions)
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
