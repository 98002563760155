import PatientManagement from '../pages/patient/PatientManagement.vue'
import ViewAllPatient from '../pages/patient/ViewAllPatients.vue'

const PatientRoutes = [
   // ? Patient management page view rout
   {
      path: '/patient-management',
      name: '/patient-management',
      component: PatientManagement,
      meta: { requiresAuth: true },
   },
   // ? view all patient view page view rout
   {
      path: '/view-all-patient',
      name: '/view-all-patient',
      component: ViewAllPatient,
      meta: { requiresAuth: true },
   },
]

export default PatientRoutes
