<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <!-- Left Column -->
                  <div class="col-md-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Doctor's Payments</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-6 mb-1">
                                 <label class="">Select Doctor <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="doctor_ids_cmb" name="doctor_ids_cmb"> </Select2>
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">Total Payable Amount</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="total_to_be_paid_amount_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">Transaction Count</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="row_count_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">To Be Paid (Now Selected)</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="to_be_paid_amount_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="doctor_account_tbx" readonly />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="doctor_id_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-3 mb-1">
                                 <label class="">Cash Payment <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="cash_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-3 mb-1">
                                 <label class="">Bank Payment <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="bank_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-3 mb-1">
                                 <label class="">Cheque No </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="cheque_no_tbx" />
                              </div>
                              <div class="col-md-3 mb-1">
                                 <label class="">Cheque Post-date </label>
                                 <input
                                    type="date"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="cheque_post_date_pkr"
                                    :value="getCurrentDate()"
                                 />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-5">
                                 <label class="">Description <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="description_tbx" />
                              </div>
                              <div class="col-md-auto mt-4">
                                 <input class="btn btn-success btn-block" type="submit" id="submit_btn" value="Submit" />
                              </div>
                              <div class="col-md-auto mt-4">
                                 <input class="btn btn-success btn-block" type="submit" id="check_all_tra_btn" value="Select All" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12 col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                              <li class="nav-item">
                                 <a
                                    class="nav-link active"
                                    id="pills-home-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-home-fill"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                    >To Be Paid Lab Test Transactions</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="pills-profile-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-profile-fill"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                    >Account Transaction History</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="pills-contact-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-contact-fill"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false"
                                    >Lab Test Transaction History</a
                                 >
                              </li>
                           </ul>
                           <div class="tab-content" id="pills-tabContent-1">
                              <div class="tab-pane fade show active" id="pills-home-fill" role="tabpanel" aria-labelledby="pills-home-tab-fill">
                                 <div class="table-responsive">
                                    <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Select</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Actual Price</th>
                                             <th>Discount</th>
                                             <th>Rate</th>
                                             <th>Reference Fee</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Select</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Actual Price</th>
                                             <th>Discount</th>
                                             <th>Rate</th>
                                             <th>Reference Fee</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="pills-profile-fill" role="tabpanel" aria-labelledby="pills-profile-tab-fill">
                                 <div class="table-responsive">
                                    <table id="transaction_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th style="width: 20%">Bill No</th>
                                             <th>Debit</th>
                                             <th>Credit</th>
                                             <th>Date Time</th>
                                             <th>Bill Type</th>
                                             <th>User</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Bill No</th>
                                             <th>Debit</th>
                                             <th>Credit</th>
                                             <th>Date Time</th>
                                             <th>Bill Type</th>
                                             <th>User</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="pills-contact-fill" role="tabpanel" aria-labelledby="pills-contact-tab-fill">
                                 <div class="table-responsive">
                                    <table id="history_lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Status</th>
                                             <th>Pay Date</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Actual Price</th>
                                             <th>Discount</th>
                                             <th>Rate</th>
                                             <th>Reference Fee</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Status</th>
                                             <th>Pay Date</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Actual Price</th>
                                             <th>Discount</th>
                                             <th>Rate</th>
                                             <th>Reference Fee</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'doctor', ['active', 'main-active'])
      this.updateElementClasses('sub', 'doctor-pay', ['active'])

      $(document).ready(function () {
         let selectedIds = []
         let selectedTraId = []

         // ? get all doctor names and ids into select doctor dropdown
         get_doctor_names_id()

         clearValues()

         // ? clear values in change select doctor
         function clearValues() {
            // ? Clear text inputs by ID
            $('#total_to_be_paid_amount_tbx').val(0.0)
            $('#row_count_tbx').val(0)
            $('#to_be_paid_amount_tbx').val(0)
            $('#doctor_account_tbx').val('')
            $('#doctor_id_tbx').val('')

            // Set number inputs to 0 by ID
            $('#cash_tbx').val('0.00')
            $('#bank_tbx').val('0.00')

            $('#cheque_no_tbx').val('')
            $('#description_tbx').val('')
         }

         // ? get all doctor names and ids into select doctor dropdown
         function get_doctor_names_id() {
            $.ajax({
               url: `${API_URL}doctor/doctor-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#doctor_ids_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(doctor => {
                        const option = new Option(doctor.show_name_2, doctor.doct_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#doctor_ids_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? get pl account by tracker id
         function get_pl_account_data_pl_acc_id(tracker_id) {
            $.ajax({
               url: `${API_URL}pl-account/tracker/` + tracker_id,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#doctor_account_tbx').val(data['pl_acc_no'])
                  $('#total_to_be_paid_amount_tbx').val(data['pl_acc_avb_balance'])
                  // $('#last_updated_tbx').val(data['pl_acc_date_time'])
               },
               error: function (error) {
                  console.error('Error fetching doctor names:', error)
               },
            })
         }

         // ? doctor select box enter button click event selected change event
         $('#doctor_ids_cmb').on('change', function () {
            clearValues()
            // ? Your code for Select doctor select2 change event
            var doctor_id = $(this).val()
            $('#doctor_id_tbx').val(doctor_id)

            // ? get tracker pl account
            get_pl_account_data_pl_acc_id(doctor_id)

            // ? load data to table
            lab_test_transaction_load_data_table(doctor_id, ['P'])
            load_data_table(doctor_id)
            history_lab_test_transaction_load_data_table(doctor_id, ['P', 'Z', 'C', 'N', 'Y'])

            // ? Focus and select the content of input
            setTimeout(function () {
               $('#cash_tbx').focus().select()
            }, 0)
         })

         // ? remove duplicates from array
         function removeDuplicates(array) {
            return [...new Set(array)]
         }

         // ? view lab test transaction to table model view
         function lab_test_transaction_load_data_table(refID, type) {
            // Update the selectedIds array with all data-ids
            selectedIds = []
            selectedTraId = []

            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test-transaction/doctor-ref-fee-test-transaction-view-data-table/` + refID + `?Type=` + type,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                     render: function (data, type, row) {
                        return (
                           '<input type="checkbox" class="select-checkbox" id="select_tra_chb" data-id="' +
                           data +
                           '" data-traid="' +
                           row.lab_tra_id +
                           '">'
                        )
                     },
                  },
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_actual_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_total_discount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_doct_rate',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${
                                parseFloat(data).toLocaleString(undefined, {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                }) + '%'
                             }</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_ref_fee',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'lab_tra_date' },
                  { data: 'lab_tra_time' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [[10000000], ['Show all']],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal

                  $('#row_count_tbx').val(totalRecords)
               }
            }

            // ? Initialize DataTable
            $('#lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? load data to table
         function load_data_table(doctor_id) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}account-transaction/all-pl-bill-transaction-data/` + doctor_id,
                  type: 'GET',
               },
               columns: [
                  { data: 'pl_tra_bill_no' },
                  {
                     data: 'pl_tra_debit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pl_tra_credit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'pl_tra_date' },
                  {
                     data: 'pl_tra_type',
                     render: function (data, type, row) {
                        if (data === 'DC') {
                           return '<span class="badge badge-warning">Doctor Payment Calculation</span>'
                        } else if (data === 'DP') {
                           return '<span class="badge badge-success">Doctor Payment</span>'
                        }
                     },
                  },
                  { data: 'user_fname' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#transaction_data_table').DataTable(dataTableOptions)
         }

         // ? view lab test transaction to table model view
         function history_lab_test_transaction_load_data_table(refID, type) {
            // Update the selectedIds array with all data-ids
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test-transaction/doctor-ref-fee-test-transaction-view-data-table/` + refID + `?Type=` + type,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'lab_tra_ref_fee_update',
                     render: function (data, type, row) {
                        if (data === 'P') {
                           return '<span class="badge badge-danger">Not Paid Yet</span>'
                        } else if (data === 'Y') {
                           return '<span class="badge badge-success">Paid</span>'
                        } else if (data === 'Z') {
                           return '<span class="badge badge-warning">No Payment To Be Made</span>'
                        }
                     },
                  },
                  { data: 'lab_tra_ref_fee_pay_date' },
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_actual_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_total_discount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_doct_rate',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${
                                parseFloat(data).toLocaleString(undefined, {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                }) + '%'
                             }</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_ref_fee',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'lab_tra_date' },
                  { data: 'lab_tra_time' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#history_lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? get all reference fee total by ids array
         function get_total_reference_fee_by_ids(ids_set) {
            $.ajax({
               url: `${API_URL}lab-test-transaction/total-reference-fee-by-id-set?ids=` + ids_set,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#to_be_paid_amount_tbx').val(data['total_ref_fee'])
                  // $('#last_updated_tbx').val(data['pl_acc_date_time'])
               },
               error: function (error) {
                  console.error('Error fetching doctor names:', error)
               },
            })
         }

         // Handle checkbox selection
         $('#lab_test_data_table').on('click', '.select-checkbox', function () {
            const selectedId = $(this).data('id')
            const labTraId = $(this).data('traid') // assuming 'data-traid' attribute is present

            const isSelected = $(this).prop('checked')

            if (isSelected) {
               // Add to selection
               selectedIds.push(selectedId)
               selectedTraId.push(labTraId)
            } else {
               // Remove from selection
               selectedIds = selectedIds.filter(id => id !== selectedId)
               selectedTraId = selectedTraId.filter(id => id !== labTraId)
            }

            // ? remove duplicates in array
            selectedTraId = removeDuplicates(selectedTraId)

            // Display selected data-ids in an alert
            if (selectedIds.length > 0) {
               get_total_reference_fee_by_ids(selectedIds.join(', '))
            } else {
               $('#to_be_paid_amount_tbx').val(0)

               Swal.fire({
                  icon: 'info',
                  title: 'Alert',
                  text: 'No Data Selected.',
                  timer: 2000, // Set the timer for 2 seconds
                  showConfirmButton: false,
                  timerProgressBar: true,
               })
            }

            // ? Focus and select the content of input
            setTimeout(function () {
               $('#cash_tbx').focus().select()
            }, 0)
         })

         $('#check_all_tra_btn').on('click', function () {
            // Check all checkboxes
            $('input[type="checkbox"]').prop('checked', true)

            // Update the selectedIds and selectedTraId arrays with all data-ids and lab_tra_ids
            selectedIds = []
            selectedTraId = []
            $('.select-checkbox').each(function () {
               const id = $(this).data('id')
               const labTraId = $(this).data('traid') // assuming 'data-traid' attribute is present
               selectedIds.push(id)
               selectedTraId.push(labTraId)
            })

            // ? remove duplicates in array
            selectedTraId = removeDuplicates(selectedTraId)

            // Display selected data-ids in an alert
            if (selectedIds.length > 0) {
               get_total_reference_fee_by_ids(selectedIds.join(', '))
            } else {
               Swal.fire({
                  icon: 'info',
                  title: 'Alert',
                  text: 'No Data Selected.',
                  timer: 2000, // Set the timer for 2 seconds
                  showConfirmButton: false,
                  timerProgressBar: true,
               })
            }
         })

         // ? Trigger action on "Enter" key press in cash_tbx
         $('#cash_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#bank_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in bank_tbx
         $('#bank_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#cheque_no_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_no_tbx
         $('#cheque_no_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#cheque_post_date_pkr').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_post_date_pkr
         $('#cheque_post_date_pkr').on('keyup', function (event) {
            if (event.which === 13) {
               $('#description_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_post_date_pkr
         $('#description_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               document.getElementById('submit_btn').click()
            }
         })

         // ? submit button click event
         $('#submit_btn').on('click', () => {
            var cashValue = parseFloat($('#cash_tbx').val()) || 0
            var bankValue = parseFloat($('#bank_tbx').val()) || 0
            var to_be_paid_amount = parseFloat($('#to_be_paid_amount_tbx').val()) || 0
            var cheque_no = $('#cheque_no_tbx').val()
            var cheque_post_date = $('#cheque_post_date_pkr').val()
            var description = $('#description_tbx').val()

            var doctor_id = $('#doctor_id_tbx').val()
            var doctor_acc = $('#doctor_account_tbx').val()
            var tra_count = $('#row_count_tbx').val()

            // Calculate total and assign it to the readonly input
            var totalPayment = cashValue + bankValue

            var requestData = {
               ids: selectedIds.join(', '),
               pl_tra_ref_id: doctor_id,
               pl_tra_ref_acc: doctor_acc,
               gl_tra_branch: window.globalData.branchId,
               amount: totalPayment,
               cash_bal: cashValue,
               bank_bal: bankValue,
               post_date: cheque_post_date,
               cheque_no: cheque_no,
               description: description,
               user_id: window.globalData.id,
            }

            if (!doctor_id) {
               // Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select a doctor name!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (tra_count == 0) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'No amount to be paid!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (selectedIds.length === 0) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select a transaction for payment!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (totalPayment === 0) {
               // Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Both cash and bank payments cannot be zero!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (totalPayment !== to_be_paid_amount) {
               // Display SweetAlert for entering a description
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Total payment should be equal to payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (!description) {
               // Display SweetAlert for entering a description
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please enter a description!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else {
               if (bankValue > 0) {
                  if (cheque_no === '') {
                     Swal.fire({
                        icon: 'warning',
                        title: 'Oops!',
                        text: 'Cheque No can not be null!',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                     })
                  } else if (!cheque_post_date) {
                     Swal.fire({
                        icon: 'warning',
                        title: 'Oops!',
                        text: 'Cheque post-date can not be null!',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                     })
                  } else {
                     $('#submit_btn').prop('disabled', true).val('wait...')

                     // Make the API call using jQuery AJAX
                     $.ajax({
                        type: 'POST',
                        url: `${API_URL}account-transaction/doctor-ref-fee-payment-transaction-insert/`, // Replace with your actual API endpoint
                        contentType: 'application/json',
                        data: JSON.stringify(requestData),
                        success: function (response) {
                           $('#submit_btn').prop('disabled', false).val('Submit')
                           // Display SweetAlert for success
                           Swal.fire({
                              icon: 'success',
                              title: 'Success',
                              text: 'Doctor reference fee pay transaction successfully added.',
                              timer: 2000,
                              showConfirmButton: false,
                              timerProgressBar: true,
                           })

                           pl_account_balance_update()

                           // ? load data to table
                           get_doctor_names_id()
                           clearValues()

                           lab_test_transaction_load_data_table('1'['1'])
                           load_data_table('1')
                           history_lab_test_transaction_load_data_table('1', ['1'])
                           // You can perform additional actions after a successful API call if needed
                        },
                        error: function (error) {
                           $('#submit_btn').prop('disabled', false).val('Submit')
                           // Display SweetAlert for error
                           Swal.fire({
                              icon: 'error',
                              title: 'Error',
                              text: error.responseText,
                           })
                        },
                     })
                  }
               } else {
                  $('#submit_btn').prop('disabled', true).val('wait...')

                  // Make the API call using jQuery AJAX
                  $.ajax({
                     type: 'POST',
                     url: `${API_URL}account-transaction/doctor-ref-fee-payment-transaction-insert/`, // Replace with your actual API endpoint
                     contentType: 'application/json',
                     data: JSON.stringify(requestData),
                     success: function (response) {
                        $('#submit_btn').prop('disabled', false).val('Submit')
                        // Display SweetAlert for success
                        Swal.fire({
                           icon: 'success',
                           title: 'Success',
                           text: 'Doctor reference fee pay transaction successfully added.',
                           timer: 2000,
                           showConfirmButton: false,
                           timerProgressBar: true,
                        })

                        clearValues()

                        lab_test_transaction_load_data_table('1'['1'])
                        load_data_table('1')
                        history_lab_test_transaction_load_data_table('1', ['1'])

                        // ? load data to table
                        setTimeout(function () {
                           get_doctor_names_id()
                        }, 2000)
                        // You can perform additional actions after a successful API call if needed
                     },
                     error: function (error) {
                        $('#submit_btn').prop('disabled', false).val('Submit')
                        // Display SweetAlert for error
                        Swal.fire({
                           icon: 'error',
                           title: 'Error',
                           text: error.responseText,
                        })
                     },
                  })
               }
            }
         })

         function pl_account_balance_update() {
            $.ajax({
               url: `${API_URL}account-transaction/call-pl-account-balance-update-store-procedure`,
               type: 'GET',
               success: function (response) {
                  //console.log('Stored procedure executed successfully', response)
               },
               error: function (xhr, status, error) {
                  //console.error('Error executing stored procedure', error)
                  //alert('Error executing stored procedure')
               },
            })
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
