export function formatNumberWithCommas(num) {
   // Convert to string and round to 2 decimal places
   let rounded = Number(Math.round(num + 'e2') + 'e-2').toFixed(2)

   // Split the string into integer and decimal parts
   let parts = rounded.split('.')

   // Add commas for thousands
   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

   // Join the integer and decimal parts with a dot
   return 'Rs. ' + parts.join('.')
}
