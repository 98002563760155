import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './config/store'

const app = createApp(App)

app.use(router)
app.use(store)

// Mount the app
app.mount('#app')
