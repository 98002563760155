<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="row">
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-sharp fa-solid fa-inbox-in text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Total Income</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="total_income_lbl">0.00</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-sharp fa-solid fa-inbox-out text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Total Expenses</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="total_expense_lbl">0.00</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-solid fa-money-check-dollar text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Total Bank Balance</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="total_bank_lbl">0.00</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-3">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-sharp fa-solid fa-sack-dollar text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Total Cash Balance</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="total_cash_lbl">0.00</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-12 col-lg-12">
                     <div class="row">
                        <div class="col-md-12 col-lg-4">
                           <div class="row">
                              <div class="col-md-6 col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div class="iq-card-body">
                                       <div class="iq-info-box d-flex align-items-center p-3">
                                          <div class="info-image mr-3">
                                             <img
                                                src="Assets/images/page-img/online-appointment.png"
                                                class="img-fluid"
                                                alt="image-box"
                                                width="75"
                                                height="75"
                                             />
                                          </div>
                                          <div class="info-text">
                                             <h3 id="total_lab_test_lbl">0</h3>
                                             <span>Total Lab Test</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div class="iq-card-body">
                                       <div class="iq-info-box d-flex align-items-center p-3">
                                          <div class="info-image mr-3">
                                             <img
                                                src="Assets/images/page-img/compliance.png"
                                                class="img-fluid"
                                                alt="image-box"
                                                width="75"
                                                height="75"
                                             />
                                          </div>
                                          <div class="info-text">
                                             <h3 id="completed_lab_test_lbl">0</h3>
                                             <span>Completed Lab Test</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div class="iq-card-body">
                                       <div class="iq-info-box d-flex align-items-center p-3">
                                          <div class="info-image mr-3">
                                             <img src="Assets/images/page-img/fail.png" class="img-fluid" alt="image-box" width="75" height="75" />
                                          </div>
                                          <div class="info-text">
                                             <h3 id="not_completed_lab_test_lbl">0</h3>
                                             <span>Not Completed Lab Test</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div class="iq-card-body">
                                       <div class="iq-info-box d-flex align-items-center p-3">
                                          <div class="info-image mr-3">
                                             <img src="Assets/images/page-img/patient.png" class="img-fluid" alt="image-box" width="75" height="75" />
                                          </div>
                                          <div class="info-text">
                                             <h3 id="today_visitors_lbl">0</h3>
                                             <span>Today Visitors</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-8">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-header d-flex justify-content-between">
                                 <div class="iq-header-title">
                                    <h4 class="card-title">Today Lab Test Progress</h4>
                                 </div>
                              </div>
                              <div class="iq-card-body">
                                 <div><canvas id="acquisitions"></canvas></div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
// Import the LayoutMenu component
import LayoutManu from '../components/LayoutManu.vue'
import LayoutNavbar from '../components/LayoutNavbar.vue'
import LayoutFooterVue from '../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../config/config.js'
import { dateTimeMixin } from '../config/datetime'
import { formatNumberWithCommas } from '../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS
import Chart from 'chart.js/auto'

export default {
   mixins: [dateTimeMixin],
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('sub', 'home', ['active'])

      $(document).ready(function () {
         // Function to get GL account summary data
         get_gl_account_summary_data()

         // Function to get lab test counts summary data
         getLabTestCountsSummaryData()

         updateChartWithData('acquisitions')

         // Set up interval to call functions every 1 minute (60,000 milliseconds)
         setInterval(function () {
            get_gl_account_summary_data()
            getLabTestCountsSummaryData()
            updateChartWithData('acquisitions')
         }, 60000)

         // Function to get GL account summary data
         function get_gl_account_summary_data() {
            $.ajax({
               url: `${API_URL}account/gl-account-income-expenses-cash-bank-bal-daily-dash`, // Replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Update HTML elements with the received data
                  $('#total_income_lbl').text(formatNumberWithCommas(data['total_income']))
                  $('#total_expense_lbl').text(formatNumberWithCommas(data['total_expense']))
                  $('#total_bank_lbl').text(formatNumberWithCommas(data['net_balance_bank']))
                  $('#total_cash_lbl').text(formatNumberWithCommas(data['net_balance_cash']))
               },
               error: function (error) {
                  console.error(error)
                  // Handle error, e.g., display an error message
               },
            })
         }

         // Function to get lab test counts summary data
         function getLabTestCountsSummaryData() {
            $.ajax({
               url: `${API_URL}lab-test-transaction/lab-test-transaction-count-summery-daily`, // Replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Update HTML elements with the received data
                  $('#total_lab_test_lbl').text(data['total_count'])
                  $('#completed_lab_test_lbl').text(data['completed_count'])
                  $('#not_completed_lab_test_lbl').text(data['not_completed_count'])
                  $('#today_visitors_lbl').text(data['customer_count'])
               },
               error: function (error) {
                  console.error(error)
                  // Handle error, e.g., display an error message
               },
            })
         }

         function updateChartWithData(chartElement) {
            // Check if the chart instance already exists and destroy it
            const existingChart = Chart.getChart(chartElement)
            if (existingChart) {
               existingChart.destroy()
            }

            // Make the AJAX request
            $.ajax({
               url: `${API_URL}lab-test-transaction/lab-test-transaction-hourly-count-daily`,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Handle the successful response here

                  // Extract the data from the API response
                  const hours = data.map(entry => entry.hour)
                  const hourlyCounts = data.map(entry => entry.hourly_count)

                  // Update the Chart data
                  const chart = new Chart(chartElement, {
                     type: 'line',
                     data: {
                        labels: hours,
                        datasets: [
                           {
                              label: 'Lab Test Hourly Counts',
                              data: hourlyCounts,
                              tension: 0.4,
                           },
                        ],
                     },
                  })
               },
               error: function (error) {
                  // Handle errors here
                  console.error(error)
               },
            })
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
