<template lang="">
   <!-- TOP Nav Bar -->
   <div class="iq-top-navbar">
      <div class="iq-navbar-custom">
         <div class="iq-sidebar-logo">
            <div class="top-logo">
               <a href="index.html" class="logo">
                  <img src="Assets/images/logo.png" class="img-fluid" alt="" />
                  <span>Lab +</span>
               </a>
            </div>
         </div>
         <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-search-bar">
               <form action="#" class="searchbox">
                  <h6 id="branch_name"></h6>
               </form>
            </div>
            <button
               class="navbar-toggler"
               type="button"
               data-toggle="collapse"
               data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent"
               aria-expanded="false"
               aria-label="Toggle navigation"
            >
               <i class="ri-menu-3-line"></i>
            </button>
            <div class="iq-menu-bt align-self-center">
               <div class="wrapper-menu">
                  <div class="main-circle"><i class="ri-more-fill"></i></div>
                  <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
               </div>
            </div>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
               <ul class="navbar-nav ml-auto navbar-list">
                  <li class="nav-item">
                     <a href="#" class="search-toggle iq-waves-effect">
                        <i class="ri-notification-3-fill"></i>
                        <span class="bg-danger dots"></span>
                     </a>
                     <div class="iq-sub-dropdown">
                        <div class="iq-card shadow-none m-0">
                           <div class="iq-card-body p-0">
                              <div class="bg-primary p-3">
                                 <h5 class="mb-0 text-white">All Notifications<small class="badge badge-light float-right pt-1">4</small></h5>
                              </div>

                              <a href="#" class="iq-sub-card">
                                 <div class="media align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="Assets/images/user/01.jpg" alt="" />
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0">Emma Watson Bini</h6>
                                       <small class="float-right font-size-12">Just Now</small>
                                       <p class="mb-0">95 MB</p>
                                    </div>
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>
                  </li>
                  <li class="nav-item dropdown">
                     <a href="#" class="search-toggle iq-waves-effect">
                        <i class="ri-mail-open-fill"></i>
                        <span class="bg-primary count-mail"></span>
                     </a>
                     <div class="iq-sub-dropdown">
                        <div class="iq-card shadow-none m-0">
                           <div class="iq-card-body p-0">
                              <div class="bg-primary p-3">
                                 <h5 class="mb-0 text-white">All Messages<small class="badge badge-light float-right pt-1">5</small></h5>
                              </div>
                              <a href="#" class="iq-sub-card">
                                 <div class="media align-items-center">
                                    <div class="">
                                       <img class="avatar-40 rounded" src="Assets/images/user/01.jpg" alt="" />
                                    </div>
                                    <div class="media-body ml-3">
                                       <h6 class="mb-0">Bini Emma Watson</h6>
                                       <small class="float-left font-size-12">13 Jun</small>
                                    </div>
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
            <ul class="navbar-list">
               <li>
                  <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                     <img v-if="gender === 'M'" src="Assets/images/user/12.jpg" class="img-fluid rounded mr-3" alt="user" />
                     <img v-else-if="gender === 'F'" src="Assets/images/user/1.jpg" class="img-fluid rounded mr-3" alt="user" />
                     <div class="caption">
                        <h6 class="mb-0 line-height">{{ userName }}</h6>
                        <span class="font-size-12">Available</span>
                     </div>
                  </a>
                  <div class="iq-sub-dropdown iq-user-dropdown">
                     <div class="iq-card shadow-none m-0">
                        <div class="iq-card-body p-0">
                           <div class="bg-primary p-3">
                              <h5 class="mb-0 text-white line-height">Hello {{ userName }}</h5>
                              <span class="text-white font-size-12">Available</span>
                           </div>
                           <a href="profile.html" class="iq-sub-card iq-bg-primary-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-file-user-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0">My Profile</h6>
                                    <p class="mb-0 font-size-12">View personal profile details.</p>
                                 </div>
                              </div>
                           </a>
                           <a href="profile-edit.html" class="iq-sub-card iq-bg-primary-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-profile-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0">Edit Profile</h6>
                                    <p class="mb-0 font-size-12">Modify your personal details.</p>
                                 </div>
                              </div>
                           </a>
                           <a href="account-setting.html" class="iq-sub-card iq-bg-primary-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-account-box-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0">Account settings</h6>
                                    <p class="mb-0 font-size-12">Manage your account parameters.</p>
                                 </div>
                              </div>
                           </a>
                           <a href="privacy-setting.html" class="iq-sub-card iq-bg-primary-hover">
                              <div class="media align-items-center">
                                 <div class="rounded iq-card-icon iq-bg-primary">
                                    <i class="ri-lock-line"></i>
                                 </div>
                                 <div class="media-body ml-3">
                                    <h6 class="mb-0">Privacy Settings</h6>
                                    <p class="mb-0 font-size-12">Control your privacy parameters.</p>
                                 </div>
                              </div>
                           </a>
                           <div class="d-inline-block w-100 text-center p-3">
                              <a class="bg-primary iq-sign-btn" href="sign-in.html" role="button">Sign out<i class="ri-login-box-line ml-2"></i></a>
                           </div>
                        </div>
                     </div>
                  </div>
               </li>
            </ul>
         </nav>
      </div>
   </div>
   <!-- TOP Nav Bar END -->
</template>
<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import { API_URL } from '../config/config.js'

export default {
   name: 'LayoutNavbar',
   computed: {
      ...mapState({
         isAuthenticated: 'isAuthenticated',
         user: 'user',
      }),
      // Separate computed properties for individual state values
      userName() {
         return this.user.username
      },
      gender() {
         return this.user.gender
      },
      userID() {
         return this.user.id
      },
      branchID() {
         return this.user.branchId
      },
   },
   mounted() {
      // ? Load data table when the page loads
      this.$nextTick(() => {
         // ? get all branch names and ids into select branch dropdown
         get_branch_names_id()
      })

      // ? get all branch names and ids into select branch dropdown
      function get_branch_names_id() {
         $.ajax({
            url: `${API_URL}branch/` + window.globalData.branchId, // corrected the URL concatenation
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#branch_name').text(data['brach_name'])
            },
            error: function (error) {
               console.error('Error fetching branch names:', error)
            },
         })
      }
   },
   methods: {
      logout() {
         // Perform logout logic
         this.$store.dispatch('logout')
         this.$router.push('/login')
      },
   },
}
</script>
<style lang=""></style>
