<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Bill Re-Print</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-4 mb-1">
                                 <label class="">Select Patient <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="patient_ids_cmb" name="patient_ids_cmb"> </Select2>
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_gender_tbx" class="">Patient Gender </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_gender_tbx" readonly />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="patient_account_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_contact_tbx" class="">Patient Contact</label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_contact_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Patient Age </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_age_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Patient Email </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_email_tbx" readonly />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table id="transaction_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>Transaction Id</th>
                                       <th>View Tests</th>
                                       <th>Print</th>
                                       <th>Date Time</th>
                                       <th>Bill Type</th>
                                       <th>Total Amount</th>
                                       <th>Payment</th>
                                       <th>Balance</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>Transaction Id</th>
                                       <th>View Tests</th>
                                       <th>Print</th>
                                       <th>Date Time</th>
                                       <th>Bill Type</th>
                                       <th>Total Amount</th>
                                       <th>Payment</th>
                                       <th>Balance</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="myModal">
               <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                     <!-- Modal Header -->
                     <div class="modal-header">
                        <h4 class="modal-title">Lab Test View</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                     </div>
                     <!-- Modal body -->
                     <div class="modal-body">
                        <div class="container-fluid">
                           <div class="row">
                              <div class="col-sm-12">
                                 <div class="iq-card border border-primary">
                                    <div class="iq-card-body">
                                       <div class="table-responsive">
                                          <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                             <thead>
                                                <tr>
                                                   <th>Test Name</th>
                                                   <th>Price</th>
                                                   <th>Dis. Rt</th>
                                                   <th>Dis</th>
                                                   <th>Amount</th>
                                                   <th>Doctor</th>
                                                   <th>Hospital</th>
                                                </tr>
                                             </thead>
                                             <tbody> </tbody>
                                             <tfoot>
                                                <tr>
                                                   <th>Test Name</th>
                                                   <th>Price</th>
                                                   <th>Dis. Rt</th>
                                                   <th>Dis</th>
                                                   <th>Amount</th>
                                                   <th>Doctor</th>
                                                   <th>Hospital</th>
                                                </tr>
                                             </tfoot>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'payment', ['active', 'main-active'])
      this.updateElementClasses('sub', 'bill-reprint', ['active'])

      $(document).ready(function () {
         // ? get all patient names and ids into select patient dropdown
         get_patient_names_id()

         // ? get all patient names and ids into select patient dropdown
         function get_patient_names_id() {
            $.ajax({
               url: `${API_URL}patient/patient-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#patient_ids_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(patient => {
                        const option = new Option(patient.show_name, patient.pat_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#patient_ids_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? get patient details after select patient
         function get_patient_details(patient_id) {
            $.ajax({
               url: `${API_URL}` + 'patient/' + patient_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data['pat_gender'] == 'M') {
                     $('#patient_gender_tbx').val('Male')
                  } else {
                     $('#patient_gender_tbx').val('Female')
                  }

                  $('#patient_contact_tbx').val(data['pat_contact'])
                  $('#patient_age_tbx').val(data['pat_age'] + ' Years')
                  $('#patient_email_tbx').val(data['pat_email'])
               },
            })
         }

         // ? load data to table
         function load_data_table(cus_id) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}account-transaction/all-bill-transaction-data/` + cus_id,
                  type: 'GET',
               },
               columns: [
                  {
                     data: 'pltb_lab_tra_id',
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        if (row.pltb_lab_tra_id === '') {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-id="' +
                              row.pltb_lab_tra_id +
                              '" disabled><i class="fa-sharp fa-solid fa-eye-slash"></i></button>'
                           )
                        } else {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-id="' +
                              row.pltb_lab_tra_id +
                              '" ><i class="fa-solid fa-eye"></i></button>'
                           )
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-success btn-sm" id="print_btn" data-id="' +
                           row.id +
                           '" ><i class="fa-solid fa-print-magnifying-glass"></i></button>'
                        )
                     },
                  },
                  { data: 'pltb_datetime_formatted' },
                  {
                     data: 'pltb_bill_type',
                     render: function (data, type, row) {
                        if (data === 'PA') {
                           return '<span class="badge badge-warning">Advance Payment</span>'
                        } else if (data === 'PF') {
                           return '<span class="badge badge-success">Full Payment</span>'
                        } else if (data === 'PR') {
                           return '<span class="badge badge-danger">Payment Recovery</span>'
                        } else {
                           // Handle other cases if needed
                           return '<span class="badge badge-primary">Transaction Amount Log</span>'
                        }
                     },
                  },
                  {
                     data: 'pltb_total_payable',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pltb_total_payment',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pltb_balance',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal

                  $('#row_count_lbl').text(totalRecords)
               }
            }

            // ? Initialize DataTable
            $('#transaction_data_table').DataTable(dataTableOptions)
         }

         // ? view lab test transaction to table model view
         function lab_test_transaction_load_data_table(traID) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test-transaction/after-view-data-table/` + traID,
                  type: 'GET',
               },
               columns: [
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_discount_rate',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${
                                parseFloat(data).toLocaleString(undefined, {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                }) + '%'
                             }</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_total_discount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'amount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'doct_name' },
                  { data: 'hos_name' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal

                  $('#row_count_lbl').text(totalRecords)
               }
            }

            // ? Initialize DataTable
            $('#lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? patient select box enter button click event selected change event
         $('#patient_ids_cmb').on('change', function () {
            // ? Your code for Select Patient select2 change event
            var patient_id = $(this).val()

            // ? get patient details after select patient
            get_patient_details(patient_id)
            load_data_table(patient_id)
         })

         // ? view lab test transactions after view button click
         $('#transaction_data_table').on('click', '#view_btn', function () {
            var row_id = $(this).data('id')
            const modalElement = document.getElementById('myModal')
            const modal = new bootstrap.Modal(modalElement)
            // Show the modal
            modal.show()

            lab_test_transaction_load_data_table(row_id)
         })

         // ? re print after click print button
         $('#transaction_data_table').on('click', '#print_btn', function () {
            var row_id = $(this).data('id')
            var url = 'https://health-care-lab-pannala.hashloopit.com/bill-print/cash-posbill-reprint.php?bill_no=' + row_id
            window.open(url, '_blank')
         })
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
