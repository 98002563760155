import LabTestTransaction from '../pages/lab-test-transaction/LabTestTransaction.vue'

const LabTestTransactionRoutes = [
   // ? lab test transaction page show rout
   {
      path: '/lab-test-transaction',
      name: '/lab-test-transaction',
      component: LabTestTransaction,
      meta: { requiresAuth: true },
   },
]

export default LabTestTransactionRoutes
