<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Patients Due Payments</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-6 mb-1">
                                 <label class="">Search By Patient Name <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="patient_ids_cmb" name="patient_ids_cmb"> </Select2>
                              </div>
                              <div class="col-md-6 mb-1">
                                 <label class="">Search By Transaction No <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="test_tra_cmb" name="test_tra_cmb"> </Select2>
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-2 mb-1">
                                 <label for="patient_gender_tbx" class="">Patient Gender </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_gender_tbx" readonly />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="patient_account_tbx" readonly />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="patient_id_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_contact_tbx" class="">Patient Contact</label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_contact_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Patient Age </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_age_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Patient Email </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_email_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="due_balance_tbx" class="">Due Balance </label>
                                 <input type="number" class="form-control form-control-sm font-weight-bold" id="due_balance_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="last_updated_tbx" class="">Last Updated Time </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="last_updated_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-2 mb-1">
                                 <label class="">Cash </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="pf_cash_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">Bank </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="pf_bank_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">Balance </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="pf_balance_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-3 mt-4">
                                 <input class="btn btn-success" type="submit" id="due_payment_collect_btn" value="Due Payment Collect" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table id="transaction_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>Bill No</th>
                                       <th>Transaction Id</th>
                                       <th>View Tests</th>
                                       <th>Date Time</th>
                                       <th>Total Amount</th>
                                       <th>Total Discount</th>
                                       <th>Payable Amount</th>
                                       <th>Payment</th>
                                       <th>Balance</th>
                                       <th>Bill Type</th>
                                       <th>User</th>
                                       <th>Branch</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>Bill No</th>
                                       <th>Transaction Id</th>
                                       <th>View Tests</th>
                                       <th>Date Time</th>
                                       <th>Total Amount</th>
                                       <th>Total Discount</th>
                                       <th>Payable Amount</th>
                                       <th>Payment</th>
                                       <th>Balance</th>
                                       <th>Bill Type</th>
                                       <th>User</th>
                                       <th>Branch</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="myModal">
               <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                     <!-- Modal Header -->
                     <div class="modal-header">
                        <h4 class="modal-title">Lab Test View</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                     </div>
                     <!-- Modal body -->
                     <div class="modal-body">
                        <div class="container-fluid">
                           <div class="row">
                              <div class="col-sm-12">
                                 <div class="iq-card border border-primary">
                                    <div class="iq-card-body">
                                       <div class="table-responsive">
                                          <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                             <thead>
                                                <tr>
                                                   <th>Test Name</th>
                                                   <th>Price</th>
                                                   <th>Dis. Rt</th>
                                                   <th>Dis</th>
                                                   <th>Amount</th>
                                                   <th>Doctor</th>
                                                   <th>Hospital</th>
                                                </tr>
                                             </thead>
                                             <tbody> </tbody>
                                             <tfoot>
                                                <tr>
                                                   <th>Test Name</th>
                                                   <th>Price</th>
                                                   <th>Dis. Rt</th>
                                                   <th>Dis</th>
                                                   <th>Amount</th>
                                                   <th>Doctor</th>
                                                   <th>Hospital</th>
                                                </tr>
                                             </tfoot>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, BILL_PRINT_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'payment', ['active', 'main-active'])
      this.updateElementClasses('sub', 'patient-due-payments', ['active'])

      $(document).ready(function () {
         // ? get all patient names and ids into select patient dropdown
         get_patient_names_id()

         // ? get all lab test transactions to dropdown
         get_lab_test_transaction()

         // ? clear values
         clearValues()

         // ? clear values in change select patient or transaction
         function clearValues() {
            // ? Clear text inputs by ID
            $('#patient_gender_tbx').val('')
            $('#patient_contact_tbx').val('')
            $('#patient_age_tbx').val('')
            $('#patient_email_tbx').val('')
            $('#due_balance_tbx').val('0.00')
            $('#last_updated_tbx').val('')

            // Set number inputs to 0 by ID
            $('#pf_bank_tbx').val(0.0)
            $('#pf_cash_tbx').val(0.0)
            $('#pf_balance_tbx').val(0.0)
         }

         // ? clear data after save
         function clear_values_after_save() {
            // Set number inputs to 0 by ID
            $('#pf_bank_tbx').val(0.0)
            $('#pf_cash_tbx').val(0.0)
            $('#pf_balance_tbx').val(0.0)
         }

         // ? cal payment return balance
         function cal_payment_return_balance(total, cash, bank) {
            var return_balance = parseFloat(total - cash - bank)
            return parseFloat(return_balance.toFixed(2))
         }

         // ? get all patient names and ids into select patient dropdown
         function get_patient_names_id() {
            $.ajax({
               url: `${API_URL}patient/patient-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#patient_ids_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(patient => {
                        const option = new Option(patient.show_name, patient.pat_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#patient_ids_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? get all lab test transactions to dropdown
         function get_lab_test_transaction() {
            $.ajax({
               url: `${API_URL}account/view-all-test-transaction`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#test_tra_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(labtest => {
                        const option = new Option(labtest.lab_tra_id, labtest.lab_tra_cus_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#test_tra_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? get patient details after select patient
         function get_patient_details(patient_id) {
            $.ajax({
               url: `${API_URL}` + 'patient/' + patient_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data['pat_gender'] == 'M') {
                     $('#patient_gender_tbx').val('Male')
                  } else {
                     $('#patient_gender_tbx').val('Female')
                  }

                  $('#patient_contact_tbx').val(data['pat_contact'])
                  $('#patient_age_tbx').val(data['pat_age'] + ' Years')
                  $('#patient_email_tbx').val(data['pat_email'])
               },
            })
         }

         // ? get pl account by tracker id
         function get_pl_account_data_pl_acc_id(tracker_id) {
            $.ajax({
               url: `${API_URL}pl-account/tracker/` + tracker_id,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#patient_account_tbx').val(data['pl_acc_no'])
                  $('#due_balance_tbx').val(data['pl_acc_avb_balance'])
                  $('#last_updated_tbx').val(data['pl_acc_date_time'])
               },
               error: function (error) {
                  console.error('Error fetching doctor names:', error)
               },
            })
         }

         // ? lab test transaction select box enter button click event selected change event
         $('#test_tra_cmb').on('change', function () {
            clearValues()
            // ? Your code for Select Patient select2 change event
            var patient_id = $(this).val()
            $('#patient_ids_cmb').val(patient_id).trigger('change.select2')
            $('#patient_id_tbx').val(patient_id)

            // ? get patient details after select patient
            get_patient_details(patient_id)

            // ? get tracker pl account
            get_pl_account_data_pl_acc_id(patient_id)

            // ? load data to table
            load_data_table(patient_id)

            // ? Focus and select the content of input
            setTimeout(function () {
               $('#pf_cash_tbx').focus().select()
            }, 0)
         })

         // ? patient select box enter button click event selected change event
         $('#patient_ids_cmb').on('change', function () {
            clearValues()
            // ? Your code for Select Patient select2 change event
            var patient_id = $(this).val()
            $('#patient_id_tbx').val(patient_id)

            // ? get patient details after select patient
            get_patient_details(patient_id)

            // ? get tracker pl account
            get_pl_account_data_pl_acc_id(patient_id)

            // ? load data to table
            load_data_table(patient_id)

            // ? Focus and select the content of input
            setTimeout(function () {
               $('#pf_cash_tbx').focus().select()
            }, 0)
         })

         // ? load data to table
         function load_data_table(cus_id) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}account-transaction/all-pl-bill-transaction-data/` + cus_id,
                  type: 'GET',
               },
               columns: [
                  { data: 'pl_tra_bill_no' },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        if (row.pl_tra_lab_test_tra === '') {
                           return '<span class="badge badge-success">Payment Recovery</span>'
                        } else {
                           return row.pl_tra_lab_test_tra
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        if (row.pl_tra_lab_test_tra === '') {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-id="' +
                              row.pl_tra_lab_test_tra +
                              '" disabled><i class="fa-sharp fa-solid fa-eye-slash"></i></button>'
                           )
                        } else {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-id="' +
                              row.pl_tra_lab_test_tra +
                              '" ><i class="fa-solid fa-eye"></i></button>'
                           )
                        }
                     },
                  },
                  { data: 'pl_tra_date' },
                  {
                     data: 'pl_tra_ttl_amount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pl_tra_ttl_discount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pl_tra_payable_amount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pl_tra_total_payment',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pl_tra_balance',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pl_tra_type',
                     render: function (data, type, row) {
                        if (data === 'PA') {
                           return '<span class="badge badge-warning">Advance Payment</span>'
                        } else if (data === 'PF') {
                           return '<span class="badge badge-success">Full Payment</span>'
                        } else if (data === 'PR') {
                           return '<span class="badge badge-danger">Payment Recovery</span>'
                        } else {
                           // Handle other cases if needed
                           return '<span class="badge badge-primary">Transaction Amount Log</span>'
                        }
                     },
                  },
                  { data: 'user_fname' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
               order: [[3, 'asc']],
            }
            // ? Initialize DataTable
            $('#transaction_data_table').DataTable(dataTableOptions)
         }

         // ? view lab test transaction to table model view
         function lab_test_transaction_load_data_table(traID) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test-transaction/after-view-data-table/` + traID,
                  type: 'GET',
               },
               columns: [
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_discount_rate',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${
                                parseFloat(data).toLocaleString(undefined, {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                }) + '%'
                             }</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_total_discount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'amount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'doct_name' },
                  { data: 'hos_name' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // ? Initialize DataTable
            $('#lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? view lab test transactions after view button click
         $('#transaction_data_table').on('click', '#view_btn', function () {
            var row_id = $(this).data('id')
            const modalElement = document.getElementById('myModal')
            const modal = new bootstrap.Modal(modalElement)
            // Show the modal
            modal.show()

            lab_test_transaction_load_data_table(row_id)
         })

         // ? re print after click print button
         $('#transaction_data_table').on('click', '#print_btn', function () {
            var row_id = $(this).data('id')
            var url = `${BILL_PRINT_URL}` + row_id
            window.open(url, '_blank')
         })

         $('#pf_cash_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var ttl_payable = parseFloat($('#due_balance_tbx').val()) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount)

            $('#pf_balance_tbx').val(returnbl)
         })

         // For bank input
         $('#pf_bank_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var ttl_payable = parseFloat($('#due_balance_tbx').val()) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount)

            $('#pf_balance_tbx').val(returnbl)
         })

         // ?  due payment collect button click event
         $('#due_payment_collect_btn').on('click', () => {
            // ? value assign to variable
            var bankValue = parseFloat($('#pf_bank_tbx').val()) || 0
            var cashValue = parseFloat($('#pf_cash_tbx').val()) || 0
            var due_balance = parseFloat($('#due_balance_tbx').val()) || 0
            var patientAccount = $('#patient_account_tbx').val()
            var patientID = $('#patient_id_tbx').val()

            var actual_cash_payment = 0

            var returnbl = cal_payment_return_balance(due_balance, cashValue, bankValue)
            $('#pf_balance_tbx').val(returnbl)
            var balance = parseFloat($('#pf_balance_tbx').val(), 2) || 0

            // ? Calculate total and assign it to the readonly input
            var totalPayment = cashValue + bankValue

            if (balance < 0) {
               actual_cash_payment = parseFloat(cashValue + balance).toFixed(2)
            } else {
               actual_cash_payment = cashValue
            }

            const TraData = {
               gl_tra_branch: window.globalData.branchId,
               pl_tra_ref_id: patientID,
               pl_tra_ref_acc: patientAccount,
               pl_tra_ttl_amount: due_balance,
               pl_tra_payable_amount: due_balance,
               pl_tra_cash_bal: cashValue,
               pl_tra_bank_bal: bankValue,
               pl_tra_balance: balance,
               pl_tra_user: window.globalData.id,
               pl_tra_total_payment: totalPayment,
               actual_cash_bal: actual_cash_payment,
            }

            // ? Check for conditions
            if (bankValue > due_balance) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Bank payment can not exceed the payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (due_balance <= 0) {
               // ? Display SweetAlert for zero due balance
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'You have no balance to pay!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (totalPayment === 0) {
               // ? Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Both bank and cash payments cannot be zero!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (totalPayment < due_balance) {
               // ? Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Payment can not be less than the payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else {
               $('#due_payment_collect_btn').prop('disabled', true).val('wait...')

               $.ajax({
                  url: `${API_URL}account-transaction/customer-due-payment-collect/`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(TraData),
                  success: function (response) {
                     $('#due_payment_collect_btn').prop('disabled', false).val('Due Payment Collect')

                     // ? pl account balance update function call
                     pl_account_balance_update()

                     Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Payment successful!',
                        timer: 2000,
                        timerProgressBar: true,
                     })

                     clear_values_after_save()

                     // ? get tracker pl account
                     get_pl_account_data_pl_acc_id(patientID)

                     // ? load data to table
                     load_data_table(patientID)
                  },
                  error: function (error) {
                     $('#due_payment_collect_btn').prop('disabled', false).val('Due Payment Collect')
                     // Handle error, display an error message or perform other actions here
                  },
               })
            }
         })

         // Trigger action on "Enter" key press in pf_cash_tbx
         $('#pf_cash_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#pf_bank_tbx').focus().select()
            }
         })

         // Trigger action on "Enter" key press in pf_cash_tbx
         $('#pf_bank_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               document.getElementById('due_payment_collect_btn').click()
            }
         })

         function pl_account_balance_update() {
            $.ajax({
               url: `${API_URL}account-transaction/call-pl-account-balance-update-store-procedure`,
               type: 'GET',
               success: function (response) {
                  //console.log('Stored procedure executed successfully', response)
               },
               error: function (xhr, status, error) {
                  //console.error('Error executing stored procedure', error)
                  //alert('Error executing stored procedure')
               },
            })
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
