<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Users</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div>
                              <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target=".bd-example-modal-lg">
                                 Add New User's Details
                              </button>
                           </div>
                           <div class="table-responsive mt-3 mb-2">
                              <table id="user_data_table" class="table table-hover table-bordered mb-3">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>First Name</th>
                                       <th>Last Name</th>
                                       <th>Contact</th>
                                       <th>Edit</th>
                                       <th>Change Password</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>First Name</th>
                                       <th>Last Name</th>
                                       <th>Contact</th>
                                       <th>Edit</th>
                                       <th>Change Password</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                        <br />
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="add_user_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Add New User</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <form class="needs-validation" id="add_user_data_form" onsubmit="return false" novalidate>
                           <div class="modal-body">
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="fname_tbx" class="font-weight-bold">First Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="First Name" id="fname_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="lname_tbx" class="font-weight-bold">Last Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Last Name" id="lname_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="contact_tbx" class="font-weight-bold">Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Contact" id="contact_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="password_tbx" class="font-weight-bold">Password <span class="text-danger">*</span></label>
                                    <input type="password" class="form-control" id="password_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="user_type_cmb" class="font-weight-bold">User Type <span class="text-danger">*</span></label>
                                    <select class="form-control" id="user_type_cmb" required>
                                       <option value="UA">User Admin</option>
                                       <option value="UE">User</option>
                                    </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="gender_cmb" class="font-weight-bold">Gender <span class="text-danger">*</span></label>
                                    <select class="form-control" id="gender_cmb" required>
                                       <option value="M">Male</option>
                                       <option value="F">Female</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                              <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                              <input class="btn btn-primary" type="submit" id="user_add_btn" value="Submit" />
                           </div>
                        </form>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="update_user_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Update Existing User</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <form class="needs-validation" id="update_user_data_form" onsubmit="return false" novalidate>
                           <div class="modal-body">
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_fname_tbx" class="font-weight-bold">First Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="First Name" id="upd_fname_tbx" required />
                                    <input type="hidden" class="form-control" placeholder="First Name" id="upd_user_id_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_lname_tbx" class="font-weight-bold">Last Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Last Name" id="upd_lname_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_contact_tbx" class="font-weight-bold">Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Contact" id="upd_contact_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_gender_cmb" class="font-weight-bold">Gender <span class="text-danger">*</span></label>
                                    <select class="form-control" id="upd_gender_cmb" required>
                                       <option value="M">Male</option>
                                       <option value="F">Female</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                              <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                              <input class="btn btn-primary" type="submit" id="user_update_btn" value="Update" />
                           </div>
                        </form>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="reset_pass_user_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Reset User Password</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <form class="needs-validation" id="update_user_pass_form" onsubmit="return false" novalidate>
                           <div class="modal-body">
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="rst_password_tbx" class="font-weight-bold">Password <span class="text-danger">*</span> </label>
                                    <input type="password" class="form-control" placeholder="Password" id="rst_password_tbx" required />
                                    <input type="hidden" class="form-control" id="rst_user_id_tbx" required />
                                 </div>
                              </div>
                           </div>
                           <div class="modal-footer">
                              <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                              <input class="btn btn-primary" type="submit" id="pass_reset_btn" value="Reset" />
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
// Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mixins: [dateTimeMixin],
   mounted() {
      // ? Set selected menu item highlight
      this.updateElementClasses('main', 'user', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-user', ['active'])

      // ? Load data table when the page loads
      this.$nextTick(() => {
         this.load_data_table()
      })

      // ? Attach click event to the add user button using jQuery
      $('#user_add_btn').on('click', () => {
         const form = document.getElementById('add_user_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#user_add_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const fname = $('#fname_tbx').val()
            const lname = $('#lname_tbx').val()
            const contact = $('#contact_tbx').val()
            const password = $('#password_tbx').val()
            const user_type = $('#user_type_cmb').val()
            const gender = $('#gender_cmb').val()

            // ? Create user data object
            const userData = {
               user_fname: fname,
               user_lname: lname,
               user_contact: contact,
               user_password: password,
               user_create_date: this.getCurrentDateTime(),
               user_update_date: this.getCurrentDateTime(),
               user_type: user_type,
               user_gender: gender,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}users/`,
               type: 'POST',
               contentType: 'application/json',
               data: JSON.stringify(userData),
               success: response => {
                  // ? Reset the form
                  $('#add_user_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'User created successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#user_add_btn').prop('disabled', false).val('Submit')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#user_add_btn').prop('disabled', false).val('Submit')
               },
            })
         }
      })

      // ? data table update button click event handler
      $('#user_data_table').on('click', '#edit_btn', function () {
         // ? Get the data-id attribute of the clicked button
         var userId = $(this).data('id')

         // ? get user data to edit and update
         get_user_details(userId)

         const modalElement = document.getElementById('update_user_model')
         const modal = new bootstrap.Modal(modalElement)
         modal.show()
      })

      // ? data table password reset button click event handler
      $('#user_data_table').on('click', '#pass_edit_btn', function () {
         // ? Get the data-id attribute of the clicked button
         var userId = $(this).data('id')

         $('#rst_user_id_tbx').val(userId)

         const modalElement = document.getElementById('reset_pass_user_model')
         const modal = new bootstrap.Modal(modalElement)
         modal.show()
      })

      // ? get user data to edit and update
      function get_user_details(usr_id) {
         $.ajax({
            url: `${API_URL}` + 'users/' + usr_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#upd_user_id_tbx').val(usr_id)
               $('#rst_user_id_tbx').val(usr_id)
               $('#upd_fname_tbx').val(data['user_fname'])
               $('#upd_lname_tbx').val(data['user_lname'])
               $('#upd_contact_tbx').val(data['user_contact'])
               $('#upd_gender_cmb').val(data['user_gender'])
            },
         })
      }

      // ? update user data click button handler
      $('#user_update_btn').on('click', () => {
         const form = document.getElementById('update_user_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#user_update_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const usr_id = $('#upd_user_id_tbx').val()
            const fname = $('#upd_fname_tbx').val()
            const lname = $('#upd_lname_tbx').val()
            const contact = $('#upd_contact_tbx').val()
            const gender = $('#upd_gender_cmb').val()

            // ? Create user data object
            const userData = {
               user_fname: fname,
               user_lname: lname,
               user_contact: contact,
               user_update_date: this.getCurrentDateTime(),
               user_gender: gender,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}users/update_user/` + usr_id,
               type: 'PUT',
               contentType: 'application/json',
               data: JSON.stringify(userData),
               success: response => {
                  // ? Reset the form
                  $('#update_user_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'User data updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#user_update_btn').prop('disabled', false).val('Update')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#user_update_btn').prop('disabled', false).val('Update')
               },
            })
         }
      })

      // ? update user password click button handler
      $('#pass_reset_btn').on('click', () => {
         const form = document.getElementById('update_user_pass_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#pass_reset_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const usr_id = $('#rst_user_id_tbx').val()
            const user_password = $('#rst_password_tbx').val()

            // ? Create user data object
            const userData = {
               user_password: user_password,
               user_update_date: this.getCurrentDateTime(),
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}users/reset_password/` + usr_id,
               type: 'PUT',
               contentType: 'application/json',
               data: JSON.stringify(userData),
               success: response => {
                  // ? Reset the form
                  $('#update_user_pass_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'User password updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#pass_reset_btn').prop('disabled', false).val('Reset')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#pass_reset_btn').prop('disabled', false).val('Reset')
               },
            })
         }
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // ? Select li elements with specified id and data-id
         const elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // ? Select other li elements with specified id and remove the classes
         const otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // ? Loop through each other element
         otherElements.forEach(otherElement => {
            // ? Remove the classes from other elements
            classesToRemove.forEach(className => {
               otherElement.classList.remove(className)
            })
         })

         // ? Loop through each element
         elements.forEach(element => {
            // Add the desired classes to the selected element
            classesToRemove.forEach(className => {
               element.classList.add(className)
            })
         })
      },

      load_data_table() {
         // ? DataTable options
         const dataTableOptions = {
            destroy: true,
            processing: true,
            serverSide: true,
            ajax: {
               url: `${API_URL}users/`,
               type: 'GET',
            },
            columns: [
               { data: 'user_id' },
               { data: 'user_fname' },
               { data: 'user_lname' },
               { data: 'user_contact' },
               {
                  render: function (data, type, row) {
                     // ? You can customize the button or link here
                     return (
                        '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                        row.user_id +
                        '" ><i class="fa-solid fa-pen-to-square"></i></button>'
                     )
                  },
               },
               {
                  render: function (data, type, row) {
                     // ? You can customize the button or link here
                     return (
                        '<button class="btn btn-warning btn-sm" id="pass_edit_btn" data-id="' +
                        row.user_id +
                        '"><i class="fa-solid fa-lock"></i></button>'
                     )
                  },
               },
            ],
            lengthMenu: [
               [10, 25, 50, 10000000],
               ['10 rows', '25 rows', '50 rows', 'Show all'],
            ],
         }

         // ? Initialize DataTable
         $('#user_data_table').DataTable(dataTableOptions)
      },
   },
}
</script>

<style lang=""></style>
