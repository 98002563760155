<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Create Lab Test Worksheet</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-2 mb-1">
                                 <label class="font-weight-bold">Select Lab Test</label>
                              </div>
                              <div class="col-md-8 mb-1">
                                 <Select2 class="js-example-basic-single font-weight-bold" id="lab_test_cmb" name="lab_test_cmb"></Select2>
                              </div>
                              <div class="col-md-2 mb-1">
                                 <input class="btn btn-danger btn-sm btn-block" type="button" id="load_btn" value="Load" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <!-- Nav tabs -->
               <ul class="nav nav-pills mb-1 nav-fill" id="myTab" role="tablist">
                  <li class="nav-item">
                     <a
                        class="nav-link active"
                        id="worksheet-tab"
                        data-toggle="tab"
                        href="#worksheet"
                        role="tab"
                        aria-controls="worksheet"
                        aria-selected="true"
                        >Worksheet Data</a
                     >
                  </li>
                  <li class="nav-item">
                     <a
                        class="nav-link"
                        id="comments-tab"
                        data-toggle="tab"
                        href="#comments"
                        role="tab"
                        aria-controls="comments"
                        aria-selected="false"
                        >Comments</a
                     >
                  </li>
               </ul>

               <!-- Tab panes -->
               <div class="tab-content mt-3">
                  <div class="tab-pane fade show active" id="worksheet" role="tabpanel" aria-labelledby="worksheet-tab">
                     <!-- Content for Worksheet Data tab goes here -->
                     <div class="row">
                        <div class="col-sm-12">
                           <div class="iq-card">
                              <div class="iq-card-body">
                                 <form id="add_lab_test_worksheet_data_form">
                                    <div class="form-row">
                                       <input type="hidden" class="form-control form-control-sm" id="result_id_tbx" />
                                       <div class="col-md-4 mb-2">
                                          <label for="result_name_tbx" class="font-weight-bold">
                                             Result Name <span class="text-danger">*</span>
                                          </label>
                                          <input type="text" class="form-control form-control-sm" id="result_name_tbx" required />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="sub_topic_tbx" class="font-weight-bold"> Sub Topic </label>
                                          <input type="text" class="form-control form-control-sm" id="sub_topic_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="unit_category_tbx" class="font-weight-bold">
                                             Unit Category <span class="text-danger">*</span>
                                          </label>
                                          <input type="text" class="form-control form-control-sm" id="unit_category_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row">
                                       <div class="col-md-2 mb-2">
                                          <label for="default_ref_range_tbx" class="font-weight-bold"> Default Ref Range</label>
                                          <input type="text" class="form-control form-control-sm" id="default_ref_range_tbx" />
                                       </div>
                                       <div class="col-md-2 mb-2">
                                          <label for="default_val_tbx" class="font-weight-bold"> Default Value </label>
                                          <input type="text" class="form-control form-control-sm" id="default_val_tbx" />
                                       </div>
                                       <div class="col-md-2 mb-2">
                                          <label for="flug_range_min_female_tbx" class="font-weight-bold"> Flug Min Range Female </label>
                                          <input type="number" class="form-control form-control-sm" id="flug_range_min_female_tbx" />
                                       </div>
                                       <div class="col-md-2 mb-2">
                                          <label for="flug_range_max_female_tbx" class="font-weight-bold"> Flug Max Range Female </label>
                                          <input type="number" class="form-control form-control-sm" id="flug_range_max_female_tbx" />
                                       </div>
                                       <div class="col-md-2 mb-2">
                                          <label for="flug_range_min_male_tbx" class="font-weight-bold"> Flug Min Range Male </label>
                                          <input type="number" class="form-control form-control-sm" id="flug_range_min_male_tbx" />
                                       </div>
                                       <div class="col-md-2 mb-2">
                                          <label for="flug_range_max_male_tbx" class="font-weight-bold"> Flug Max Range Male </label>
                                          <input type="number" class="form-control form-control-sm" id="flug_range_max_male_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row" id="ro_1">
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_1_tbx" class="font-weight-bold"> Ref. Range Topic 01</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_1_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_1_tbx" class="font-weight-bold"> Ref. Range Name 01 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_1_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_1_tbx" class="font-weight-bold"> Ref. Range 01 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_1_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_2">
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_2_tbx" class="font-weight-bold"> Ref. Range Topic 02</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_2_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_2_tbx" class="font-weight-bold"> Ref. Range Name 02 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_2_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_2_tbx" class="font-weight-bold"> Ref. Range 02 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_2_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_3">
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_3_tbx" class="font-weight-bold"> Ref. Range Topic 03</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_3_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_3_tbx" class="font-weight-bold"> Ref. Range Name 03 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_3_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_3_tbx" class="font-weight-bold"> Ref. Range 03 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_3_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_4">
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_4_tbx" class="font-weight-bold"> Ref. Range Topic 04</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_4_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_4_tbx" class="font-weight-bold"> Ref. Range Name 04 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_4_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_4_tbx" class="font-weight-bold"> Ref. Range 04 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_4_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_5">
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_5_tbx" class="font-weight-bold"> Ref. Range Topic 05</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_5_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_5_tbx" class="font-weight-bold"> Ref. Range Name 05 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_5_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_5_tbx" class="font-weight-bold"> Ref. Range 05 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_5_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_6">
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_6_tbx" class="font-weight-bold"> Ref. Range Topic 06</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_6_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_6_tbx" class="font-weight-bold"> Ref. Range Name 06 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_6_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_6_tbx" class="font-weight-bold"> Ref. Range 06 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_6_tbx" />
                                       </div>
                                    </div>

                                    <div class="form-row d-none" id="ro_7">
                                       <!-- Input 07 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_7_tbx" class="font-weight-bold"> Ref. Range Topic 07</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_7_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_7_tbx" class="font-weight-bold"> Ref. Range Name 07 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_7_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_7_tbx" class="font-weight-bold"> Ref. Range 07 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_7_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_8">
                                       <!-- Input 08 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_8_tbx" class="font-weight-bold"> Ref. Range Topic 08</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_8_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_8_tbx" class="font-weight-bold"> Ref. Range Name 08 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_8_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_8_tbx" class="font-weight-bold"> Ref. Range 08 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_8_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_9">
                                       <!-- Input 09 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_9_tbx" class="font-weight-bold"> Ref. Range Topic 09</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_9_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_9_tbx" class="font-weight-bold"> Ref. Range Name 09 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_9_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_9_tbx" class="font-weight-bold"> Ref. Range 09 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_9_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_10">
                                       <!-- Input 10 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_10_tbx" class="font-weight-bold"> Ref. Range Topic 10</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_10_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_10_tbx" class="font-weight-bold"> Ref. Range Name 10 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_10_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_10_tbx" class="font-weight-bold"> Ref. Range 10 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_10_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_11">
                                       <!-- Input 11 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_11_tbx" class="font-weight-bold"> Ref. Range Topic 11</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_11_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_11_tbx" class="font-weight-bold"> Ref. Range Name 11 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_11_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_11_tbx" class="font-weight-bold"> Ref. Range 11 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_11_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_12">
                                       <!-- Input 12 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_12_tbx" class="font-weight-bold"> Ref. Range Topic 12</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_12_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_12_tbx" class="font-weight-bold"> Ref. Range Name 12 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_12_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_12_tbx" class="font-weight-bold"> Ref. Range 12 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_12_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_13">
                                       <!-- Input 13 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_13_tbx" class="font-weight-bold"> Ref. Range Topic 13</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_13_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_13_tbx" class="font-weight-bold"> Ref. Range Name 13 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_13_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_13_tbx" class="font-weight-bold"> Ref. Range 13 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_13_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row d-none" id="ro_14">
                                       <!-- Input 14 -->
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_topic_14_tbx" class="font-weight-bold"> Ref. Range Topic 14</label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_topic_14_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_name_14_tbx" class="font-weight-bold"> Ref. Range Name 14 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_name_14_tbx" />
                                       </div>
                                       <div class="col-md-4 mb-2">
                                          <label for="ref_range_14_tbx" class="font-weight-bold"> Ref. Range 14 </label>
                                          <input type="text" class="form-control form-control-sm" id="ref_range_14_tbx" />
                                       </div>
                                    </div>
                                    <div class="form-row">
                                       <div class="col-md-4 mb-2">
                                          <label for="show_status_cmb" class="font-weight-bold">Ref Range Show Status</label>
                                          <select class="form-control form-control-sm" id="show_status_cmb" required>
                                             <option value="Y">Show</option>
                                             <option value="N">Do Not Show</option>
                                          </select>
                                       </div>
                                    </div>
                                    <div class="form-row">
                                       <div class="col-md-2 mb-1">
                                          <input
                                             class="btn btn-warning btn-sm btn-block"
                                             type="button"
                                             id="add_new_ref_btn"
                                             value="Add New Ref Range"
                                          />
                                       </div>
                                       <div class="col-md-2">
                                          <input class="btn btn-success btn-sm btn-block" type="button" id="submit_btn" value="Submit" />
                                       </div>
                                       <div class="col-md-2">
                                          <input class="btn btn-info btn-sm btn-block" type="button" id="new_btn" value="New" />
                                       </div>
                                       <div class="col-md-2">
                                          <input class="btn btn-warning btn-sm btn-block" type="button" id="update_btn" value="Update" />
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-sm-12 col-lg-12">
                           <div class="iq-card">
                              <div class="iq-card-body">
                                 <div class="table-responsive">
                                    <table id="lab_test_worksheet_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th class="text-nowrap">Action</th>
                                             <th class="text-nowrap">Result Name</th>
                                             <th class="text-nowrap">Sub Topic</th>
                                             <th class="text-nowrap">Unit Category</th>
                                             <th class="text-nowrap">Default Ref Range</th>
                                             <th class="text-nowrap">Default Value</th>
                                             <th class="text-nowrap">Flug Min Range Female</th>
                                             <th class="text-nowrap">Flug Max Range Female</th>
                                             <th class="text-nowrap">Flug Min Range Male</th>
                                             <th class="text-nowrap">Flug Max Range Male</th>
                                             <th class="text-nowrap">Ref. Range Topic 01</th>
                                             <th class="text-nowrap">Ref. Range Name 01</th>
                                             <th class="text-nowrap">Ref. Range 01</th>
                                             <th class="text-nowrap">Ref. Range Topic 02</th>
                                             <th class="text-nowrap">Ref. Range Name 02</th>
                                             <th class="text-nowrap">Ref. Range 02</th>
                                             <th class="text-nowrap">Ref. Range Topic 03</th>
                                             <th class="text-nowrap">Ref. Range Name 03</th>
                                             <th class="text-nowrap">Ref. Range 03</th>
                                             <th class="text-nowrap">Ref. Range Topic 04</th>
                                             <th class="text-nowrap">Ref. Range Name 04</th>
                                             <th class="text-nowrap">Ref. Range 04</th>
                                             <th class="text-nowrap">Ref. Range Topic 05</th>
                                             <th class="text-nowrap">Ref. Range Name 05</th>
                                             <th class="text-nowrap">Ref. Range 05</th>
                                             <th class="text-nowrap">Ref. Range Topic 06</th>
                                             <th class="text-nowrap">Ref. Range Name 06</th>
                                             <th class="text-nowrap">Ref. Range 06</th>
                                             <th class="text-nowrap">Ref. Range Topic 07</th>
                                             <th class="text-nowrap">Ref. Range Name 07</th>
                                             <th class="text-nowrap">Ref. Range 07</th>
                                             <th class="text-nowrap">Ref. Range Topic 08</th>
                                             <th class="text-nowrap">Ref. Range Name 08</th>
                                             <th class="text-nowrap">Ref. Range 08</th>
                                             <th class="text-nowrap">Ref. Range Topic 09</th>
                                             <th class="text-nowrap">Ref. Range Name 09</th>
                                             <th class="text-nowrap">Ref. Range 09</th>
                                             <th class="text-nowrap">Ref. Range Topic 10</th>
                                             <th class="text-nowrap">Ref. Range Name 10</th>
                                             <th class="text-nowrap">Ref. Range 10</th>
                                             <th class="text-nowrap">Ref. Range Topic 11</th>
                                             <th class="text-nowrap">Ref. Range Name 11</th>
                                             <th class="text-nowrap">Ref. Range 11</th>
                                             <th class="text-nowrap">Ref. Range Topic 12</th>
                                             <th class="text-nowrap">Ref. Range Name 12</th>
                                             <th class="text-nowrap">Ref. Range 12</th>
                                             <th class="text-nowrap">Ref. Range Topic 13</th>
                                             <th class="text-nowrap">Ref. Range Name 13</th>
                                             <th class="text-nowrap">Ref. Range 13</th>
                                             <th class="text-nowrap">Ref. Range Topic 14</th>
                                             <th class="text-nowrap">Ref. Range Name 14</th>
                                             <th class="text-nowrap">Ref. Range 14</th>
                                             <th class="text-nowrap">Ref Range Show Status</th>
                                          </tr>
                                       </thead>
                                       <tbody> <tr> </tr> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th class="text-nowrap">Action</th>
                                             <th class="text-nowrap">Result Name</th>
                                             <th class="text-nowrap">Sub Topic</th>
                                             <th class="text-nowrap">Unit Category</th>
                                             <th class="text-nowrap">Default Ref Range</th>
                                             <th class="text-nowrap">Default Value</th>
                                             <th class="text-nowrap">Flug Min Range Female</th>
                                             <th class="text-nowrap">Flug Max Range Female</th>
                                             <th class="text-nowrap">Flug Min Range Male</th>
                                             <th class="text-nowrap">Flug Max Range Male</th>
                                             <th class="text-nowrap">Ref. Range Topic 01</th>
                                             <th class="text-nowrap">Ref. Range Name 01</th>
                                             <th class="text-nowrap">Ref. Range 01</th>
                                             <th class="text-nowrap">Ref. Range Topic 02</th>
                                             <th class="text-nowrap">Ref. Range Name 02</th>
                                             <th class="text-nowrap">Ref. Range 02</th>
                                             <th class="text-nowrap">Ref. Range Topic 03</th>
                                             <th class="text-nowrap">Ref. Range Name 03</th>
                                             <th class="text-nowrap">Ref. Range 03</th>
                                             <th class="text-nowrap">Ref. Range Topic 04</th>
                                             <th class="text-nowrap">Ref. Range Name 04</th>
                                             <th class="text-nowrap">Ref. Range 04</th>
                                             <th class="text-nowrap">Ref. Range Topic 05</th>
                                             <th class="text-nowrap">Ref. Range Name 05</th>
                                             <th class="text-nowrap">Ref. Range 05</th>
                                             <th class="text-nowrap">Ref. Range Topic 06</th>
                                             <th class="text-nowrap">Ref. Range Name 06</th>
                                             <th class="text-nowrap">Ref. Range 06</th>
                                             <th class="text-nowrap">Ref. Range Topic 07</th>
                                             <th class="text-nowrap">Ref. Range Name 07</th>
                                             <th class="text-nowrap">Ref. Range 07</th>
                                             <th class="text-nowrap">Ref. Range Topic 08</th>
                                             <th class="text-nowrap">Ref. Range Name 08</th>
                                             <th class="text-nowrap">Ref. Range 08</th>
                                             <th class="text-nowrap">Ref. Range Topic 09</th>
                                             <th class="text-nowrap">Ref. Range Name 09</th>
                                             <th class="text-nowrap">Ref. Range 09</th>
                                             <th class="text-nowrap">Ref. Range Topic 10</th>
                                             <th class="text-nowrap">Ref. Range Name 10</th>
                                             <th class="text-nowrap">Ref. Range 10</th>
                                             <th class="text-nowrap">Ref. Range Topic 11</th>
                                             <th class="text-nowrap">Ref. Range Name 11</th>
                                             <th class="text-nowrap">Ref. Range 11</th>
                                             <th class="text-nowrap">Ref. Range Topic 12</th>
                                             <th class="text-nowrap">Ref. Range Name 12</th>
                                             <th class="text-nowrap">Ref. Range 12</th>
                                             <th class="text-nowrap">Ref. Range Topic 13</th>
                                             <th class="text-nowrap">Ref. Range Name 13</th>
                                             <th class="text-nowrap">Ref. Range 13</th>
                                             <th class="text-nowrap">Ref. Range Topic 14</th>
                                             <th class="text-nowrap">Ref. Range Name 14</th>
                                             <th class="text-nowrap">Ref. Range 14</th>
                                             <th class="text-nowrap">Ref Range Show Status</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                     <!-- Content for Comments tab goes here -->
                     <div class="row">
                        <!-- Left Column -->
                        <div class="col-md-12">
                           <div class="iq-card">
                              <div class="iq-card-body">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <h2>Text Editor</h2>
                                       <input type="hidden" class="form-control form-control-sm" id="comment_id_tbx" />
                                       <div class="editor-container">
                                          <div class="btn-group" role="group" aria-label="Text editor controls">
                                             <!-- Bold -->
                                             <button type="button" class="btn btn-secondary" onclick="document.execCommand('bold', false, null)">
                                                <strong>B</strong>
                                             </button>
                                             <!-- Underline -->
                                             <button type="button" class="btn btn-secondary" onclick="document.execCommand('underline', false, null)">
                                                <u>U</u>
                                             </button>
                                             <!-- Italic -->
                                             <button type="button" class="btn btn-secondary" onclick="document.execCommand('italic', false, null)">
                                                <em>I</em>
                                             </button>
                                             <!-- Unordered list -->
                                             <button
                                                type="button"
                                                class="btn btn-secondary"
                                                onclick="document.execCommand('insertUnorderedList', false, null)"
                                             >
                                                <span>&#8226;</span>
                                             </button>
                                             <!-- Text Color -->
                                             <!-- <input type="color" id="text-color" class="btn btn-secondary" /> -->
                                             <!-- Font Size -->
                                          </div>
                                          <div contenteditable="true" class="form-control mt-3" id="editor" style="min-height: 200px"></div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-2 mt-2">
                                       <input class="btn btn-success btn-sm btn-block" type="button" id="comment_submit_btn" value="Submit" />
                                    </div>
                                    <div class="col-md-2 mt-2">
                                       <input class="btn btn-info btn-sm btn-block" type="button" id="comment_new_btn" value="New" />
                                    </div>
                                    <div class="col-md-2 mt-2">
                                       <input class="btn btn-warning btn-sm btn-block" type="button" id="comment_update_btn" value="Update" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-sm-12 col-lg-12">
                           <div class="iq-card">
                              <div class="iq-card-body">
                                 <div class="table-responsive">
                                    <table id="lab_test_worksheet_comments_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th class="text-nowrap">ID</th>
                                             <th class="text-nowrap">Action</th>
                                             <th class="text-nowrap">Comment</th>
                                          </tr>
                                       </thead>
                                       <tbody> <tr> </tr> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th class="text-nowrap">ID</th>
                                             <th class="text-nowrap">Action</th>
                                             <th class="text-nowrap">Comment</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="container-fluid"> </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'test', ['active', 'main-active'])
      this.updateElementClasses('sub', 'create-worksheet-test', ['active'])

      $(document).ready(function () {
         var range_show_count = 1

         // ? get all lab test names and ids into select lab_test dropdown
         get_lab_test_names_id()

         $('#comment_new_btn').hide()
         $('#comment_update_btn').hide()
         $('#new_btn').hide()
         $('#update_btn').hide()

         // Function to handle button click
         $('#add_new_ref_btn').click(function () {
            // Increment the count
            range_show_count++

            $('#ro_' + range_show_count).removeClass('d-none')

            // Check if count exceeds 14
            if (range_show_count > 14) {
               // Show SweetAlert message
               Swal.fire({
                  icon: 'warning',
                  title: 'You Reach the max count',
                  showConfirmButton: false,
                  timer: 2000, // Auto close after 2 seconds
               })
            }
         })

         $('#load_btn').click(function () {
            var testId = $('#lab_test_cmb').val()

            if (!testId) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Lab test is required!',
                  text: 'Please select a lab test.',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else {
               // ? view all lab test worksheet data by lab test id in to table view
               view_all_lab_worksheet_data_by_test_id(testId)

               // ? view all lab test worksheet comments by lab test id in to table view
               view_all_lab_worksheet_comments_by_test_id(testId)

               $('#comment_new_btn').hide()
               $('#comment_update_btn').hide()
            }
         })

         $('#comment_new_btn').click(function () {
            $('#editor').html('')
            $('#comment_submit_btn').show()
            $('#comment_new_btn').hide()
            $('#comment_update_btn').hide()
         })

         $('#new_btn').click(function () {
            $('#add_new_ref_btn').show()
            $('#submit_btn').show()

            $('#new_btn').hide()
            $('#update_btn').hide()

            $('#add_lab_test_worksheet_data_form')[0].reset()
            for (var range_show_count = 2; range_show_count <= 14; range_show_count++) {
               $('#ro_' + range_show_count).addClass('d-none')
            }
         })

         // Function to handle button click
         $('#submit_btn').click(function () {
            var labTest = $('#lab_test_cmb').val()

            // Variables for the first set of inputs
            var resultName = $('#result_name_tbx').val()
            var subTopic = $('#sub_topic_tbx').val()
            var unitCategory = $('#unit_category_tbx').val()

            // Variables for the third set of inputs
            var defaultRefRange = $('#default_ref_range_tbx').val()
            var defaultVal = $('#default_val_tbx').val()

            var flugRangeMinFemale = $('#flug_range_min_female_tbx').val()
            var flugRangeMaxFemale = $('#flug_range_max_female_tbx').val()

            var flugRangeMinMale = $('#flug_range_min_male_tbx').val()
            var flugRangeMaxMale = $('#flug_range_max_male_tbx').val()

            // Example for the 1st set of inputs
            var refRangeTopic1 = $('#ref_range_topic_1_tbx').val()
            var refRangeName1 = $('#ref_range_name_1_tbx').val()
            var refRange1 = $('#ref_range_1_tbx').val()

            // Example for the 2nd set of inputs
            var refRangeTopic2 = $('#ref_range_topic_2_tbx').val()
            var refRangeName2 = $('#ref_range_name_2_tbx').val()
            var refRange2 = $('#ref_range_2_tbx').val()

            // Example for the 3rd set of inputs
            var refRangeTopic3 = $('#ref_range_topic_3_tbx').val()
            var refRangeName3 = $('#ref_range_name_3_tbx').val()
            var refRange3 = $('#ref_range_3_tbx').val()

            // Example for the 4th set of inputs
            var refRangeTopic4 = $('#ref_range_topic_4_tbx').val()
            var refRangeName4 = $('#ref_range_name_4_tbx').val()
            var refRange4 = $('#ref_range_4_tbx').val()

            // Example for the 5th set of inputs
            var refRangeTopic5 = $('#ref_range_topic_5_tbx').val()
            var refRangeName5 = $('#ref_range_name_5_tbx').val()
            var refRange5 = $('#ref_range_5_tbx').val()

            // Example for the 6th set of inputs
            var refRangeTopic6 = $('#ref_range_topic_6_tbx').val()
            var refRangeName6 = $('#ref_range_name_6_tbx').val()
            var refRange6 = $('#ref_range_6_tbx').val()

            // Example for the 7th set of inputs
            var refRangeTopic7 = $('#ref_range_topic_7_tbx').val()
            var refRangeName7 = $('#ref_range_name_7_tbx').val()
            var refRange7 = $('#ref_range_7_tbx').val()

            // Example for the 8th set of inputs
            var refRangeTopic8 = $('#ref_range_topic_8_tbx').val()
            var refRangeName8 = $('#ref_range_name_8_tbx').val()
            var refRange8 = $('#ref_range_8_tbx').val()

            // Example for the 9th set of inputs
            var refRangeTopic9 = $('#ref_range_topic_9_tbx').val()
            var refRangeName9 = $('#ref_range_name_9_tbx').val()
            var refRange9 = $('#ref_range_9_tbx').val()

            // Example for the 10th set of inputs
            var refRangeTopic10 = $('#ref_range_topic_10_tbx').val()
            var refRangeName10 = $('#ref_range_name_10_tbx').val()
            var refRange10 = $('#ref_range_10_tbx').val()

            // Example for the 11th set of inputs
            var refRangeTopic11 = $('#ref_range_topic_11_tbx').val()
            var refRangeName11 = $('#ref_range_name_11_tbx').val()
            var refRange11 = $('#ref_range_11_tbx').val()

            // Example for the 12th set of inputs
            var refRangeTopic12 = $('#ref_range_topic_12_tbx').val()
            var refRangeName12 = $('#ref_range_name_12_tbx').val()
            var refRange12 = $('#ref_range_12_tbx').val()

            // Example for the 13th set of inputs
            var refRangeTopic13 = $('#ref_range_topic_13_tbx').val()
            var refRangeName13 = $('#ref_range_name_13_tbx').val()
            var refRange13 = $('#ref_range_13_tbx').val()

            // Example for the 14th set of inputs
            var refRangeTopic14 = $('#ref_range_topic_14_tbx').val()
            var refRangeName14 = $('#ref_range_name_14_tbx').val()
            var refRange14 = $('#ref_range_14_tbx').val()

            var refRangeShowStatus = $('#show_status_cmb').val()
            // ? Create an object with the extracted values
            const testData = {
               wk_lab_test_id: labTest,
               wk_test_sub_result_name: resultName,
               wk_test_sub_topic: subTopic,
               wk_test_units: unitCategory,
               wk_test_default_range: defaultRefRange,
               wk_test_default_value: defaultVal,
               wk_test_flug_range_min_female: flugRangeMinFemale,
               wk_test_flug_range_max_female: flugRangeMaxFemale,
               wk_test_flug_range_min_male: flugRangeMinMale,
               wk_test_flug_range_max_male: flugRangeMaxMale,
               wk_test_ref_range_topic_1: refRangeTopic1,
               wk_test_ref_range_name_1: refRangeName1,
               wk_test_ref_range_1: refRange1,
               wk_test_ref_range_topic_2: refRangeTopic2,
               wk_test_ref_range_name_2: refRangeName2,
               wk_test_ref_range_2: refRange2,
               wk_test_ref_range_topic_3: refRangeTopic3,
               wk_test_ref_range_name_3: refRangeName3,
               wk_test_ref_range_3: refRange3,
               wk_test_ref_range_topic_4: refRangeTopic4,
               wk_test_ref_range_name_4: refRangeName4,
               wk_test_ref_range_4: refRange4,
               wk_test_ref_range_topic_5: refRangeTopic5,
               wk_test_ref_range_name_5: refRangeName5,
               wk_test_ref_range_5: refRange5,
               wk_test_ref_range_topic_6: refRangeTopic6,
               wk_test_ref_range_name_6: refRangeName6,
               wk_test_ref_range_6: refRange6,
               wk_test_ref_range_topic_7: refRangeTopic7,
               wk_test_ref_range_name_7: refRangeName7,
               wk_test_ref_range_7: refRange7,
               wk_test_ref_range_topic_8: refRangeTopic8,
               wk_test_ref_range_name_8: refRangeName8,
               wk_test_ref_range_8: refRange8,
               wk_test_ref_range_topic_9: refRangeTopic9,
               wk_test_ref_range_name_9: refRangeName9,
               wk_test_ref_range_9: refRange9,
               wk_test_ref_range_topic_10: refRangeTopic10,
               wk_test_ref_range_name_10: refRangeName10,
               wk_test_ref_range_10: refRange10,
               wk_test_ref_range_topic_11: refRangeTopic11,
               wk_test_ref_range_name_11: refRangeName11,
               wk_test_ref_range_11: refRange11,
               wk_test_ref_range_topic_12: refRangeTopic12,
               wk_test_ref_range_name_12: refRangeName12,
               wk_test_ref_range_12: refRange12,
               wk_test_ref_range_topic_13: refRangeTopic13,
               wk_test_ref_range_name_13: refRangeName13,
               wk_test_ref_range_13: refRange13,
               wk_test_ref_range_topic_14: refRangeTopic14,
               wk_test_ref_range_name_14: refRangeName14,
               wk_test_ref_range_14: refRange14,
               wk_test_ref_range_show_status: refRangeShowStatus,
            }

            // Check if labTest is empty
            if (!labTest) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Lab test is required!',
                  text: 'Please select a lab test.',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else if (!resultName) {
               // Check if resultName is empty
               Swal.fire({
                  icon: 'warning',
                  title: 'Result name is required!',
                  text: 'Please enter a result name.',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else {
               $('#submit_btn').prop('disabled', true).val('wait...')
               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test/create-lab-test-result-worksheet`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(testData),
                  success: response => {
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'New lab test worksheet result added successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     // ? Enable the button
                     $('#submit_btn').prop('disabled', false).val('Submit')

                     $('#add_lab_test_worksheet_data_form')[0].reset()

                     // ? Reload the data table
                     view_all_lab_worksheet_data_by_test_id(labTest)
                  },
                  error: error => {
                     // ? Handle error
                     $('#submit_btn').prop('disabled', false).val('Submit')
                  },
               })
            }
         })

         // ? view all lab test worksheet data by lab test id in to table view
         function view_all_lab_worksheet_data_by_test_id(labTestId) {
            // Update the selectedIds array with all data-ids
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test/view-all-lab-test-worksheet-data-by-lab-test-id/` + labTestId,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'wk_test_sub_result_id',
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                           row.wk_test_sub_result_id +
                           '"><i class="fa-solid fa-pen-to-square"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'wk_test_sub_result_name',
                  },
                  {
                     data: 'wk_test_sub_topic',
                  },
                  {
                     data: 'wk_test_units',
                  },
                  {
                     data: 'wk_test_default_range',
                  },
                  {
                     data: 'wk_test_default_value',
                  },
                  {
                     data: 'wk_test_flug_range_min_female',
                  },
                  {
                     data: 'wk_test_flug_range_max_female',
                  },
                  {
                     data: 'wk_test_flug_range_min_male',
                  },
                  {
                     data: 'wk_test_flug_range_max_male',
                  },
                  {
                     data: 'wk_test_ref_range_topic_1',
                  },
                  {
                     data: 'wk_test_ref_range_name_1',
                  },
                  {
                     data: 'wk_test_ref_range_1',
                  },
                  {
                     data: 'wk_test_ref_range_topic_2',
                  },
                  {
                     data: 'wk_test_ref_range_name_2',
                  },
                  {
                     data: 'wk_test_ref_range_2',
                  },
                  {
                     data: 'wk_test_ref_range_topic_3',
                  },
                  {
                     data: 'wk_test_ref_range_name_3',
                  },
                  {
                     data: 'wk_test_ref_range_3',
                  },
                  {
                     data: 'wk_test_ref_range_topic_4',
                  },
                  {
                     data: 'wk_test_ref_range_name_4',
                  },
                  {
                     data: 'wk_test_ref_range_4',
                  },
                  {
                     data: 'wk_test_ref_range_topic_5',
                  },
                  {
                     data: 'wk_test_ref_range_name_5',
                  },
                  {
                     data: 'wk_test_ref_range_5',
                  },
                  {
                     data: 'wk_test_ref_range_topic_6',
                  },
                  {
                     data: 'wk_test_ref_range_name_6',
                  },
                  {
                     data: 'wk_test_ref_range_6',
                  },
                  {
                     data: 'wk_test_ref_range_topic_7',
                  },
                  {
                     data: 'wk_test_ref_range_name_7',
                  },
                  {
                     data: 'wk_test_ref_range_7',
                  },
                  {
                     data: 'wk_test_ref_range_topic_8',
                  },
                  {
                     data: 'wk_test_ref_range_name_8',
                  },
                  {
                     data: 'wk_test_ref_range_8',
                  },
                  {
                     data: 'wk_test_ref_range_topic_9',
                  },
                  {
                     data: 'wk_test_ref_range_name_9',
                  },
                  {
                     data: 'wk_test_ref_range_9',
                  },
                  {
                     data: 'wk_test_ref_range_topic_10',
                  },
                  {
                     data: 'wk_test_ref_range_name_10',
                  },
                  {
                     data: 'wk_test_ref_range_10',
                  },
                  {
                     data: 'wk_test_ref_range_topic_11',
                  },
                  {
                     data: 'wk_test_ref_range_name_11',
                  },
                  {
                     data: 'wk_test_ref_range_11',
                  },
                  {
                     data: 'wk_test_ref_range_topic_12',
                  },
                  {
                     data: 'wk_test_ref_range_name_12',
                  },
                  {
                     data: 'wk_test_ref_range_12',
                  },
                  {
                     data: 'wk_test_ref_range_topic_13',
                  },
                  {
                     data: 'wk_test_ref_range_name_13',
                  },
                  {
                     data: 'wk_test_ref_range_13',
                  },
                  {
                     data: 'wk_test_ref_range_topic_14',
                  },
                  {
                     data: 'wk_test_ref_range_name_14',
                  },
                  {
                     data: 'wk_test_ref_range_14',
                  },
                  {
                     data: 'wk_test_ref_range_show_status',
                  },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#lab_test_worksheet_data_table').DataTable(dataTableOptions)
         }

         // ? get all lab test names and ids into select lab_test dropdown
         function get_lab_test_names_id() {
            $.ajax({
               url: `${API_URL}lab-test/view-all-own-test-name-id`, // corrected the URL concatenation
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `lab_id` and `show_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#lab_test_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(lab_test => {
                        const option = new Option(lab_test.lab_test_name, lab_test.lab_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#lab_test_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching lab test names:', error)
               },
            })
         }

         $('#comment_submit_btn').click(function () {
            // Get the HTML content of the text editor
            var editorContent = $('#editor').html()
            var labTest = $('#lab_test_cmb').val()

            // Check if labTest is empty
            if (!labTest) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Lab test is required!',
                  text: 'Please select a lab test.',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else if (editorContent.trim() === '') {
               // Show SweetAlert with auto-close on 2 seconds
               Swal.fire({
                  title: 'Empty Content',
                  text: 'The text editor content is empty.',
                  icon: 'warning',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else {
               const testData = {
                  wk_com_lab_test_id: labTest,
                  wk_comment: editorContent,
               }
               $('#comment_submit_btn').prop('disabled', true).val('wait...')
               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test/add-new-lab-test-result-worksheet-comment`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(testData),
                  success: response => {
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'New lab test worksheet Comment added successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     // ? view all lab test worksheet comments by lab test id in to table view
                     view_all_lab_worksheet_comments_by_test_id(labTest)

                     // ? Enable the button
                     $('#comment_submit_btn').prop('disabled', false).val('Submit')
                     $('#editor').html('')
                  },
                  error: error => {
                     // ? Handle error
                     $('#comment_submit_btn').prop('disabled', false).val('Submit')
                  },
               })
            }
         })

         // ? view all lab test worksheet comments by lab test id in to table view
         function view_all_lab_worksheet_comments_by_test_id(labTestId) {
            // Update the selectedIds array with all data-ids
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test/view-lab-test-worksheet-comment-by-comment-lab-test-id/` + labTestId,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'wk_com_id',
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-success btn-sm" id="com_edit_btn" data-id="' +
                           row.wk_com_id +
                           '"><i class="fa-solid fa-pen-to-square"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'wk_com_id',
                  },
                  {
                     data: 'wk_comment',
                  },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#lab_test_worksheet_comments_table').DataTable(dataTableOptions)
         }

         // ? lab test worksheet comment data edit to view button
         $('#lab_test_worksheet_comments_table').on('click', '#com_edit_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var comment_id = $(this).data('id')

            $.ajax({
               url: `${API_URL}` + 'lab-test/view-lab-test-worksheet-comment-data-by-comment-id/' + comment_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  var editorContent = data['wk_comment']
                  $('#editor').html(editorContent)
                  $('#comment_id_tbx').val(data['wk_com_id'])

                  $('#comment_submit_btn').hide()
                  $('#comment_new_btn').show()
                  $('#comment_update_btn').show()
               },
            })
         })

         // ? lab test worksheet result data edit to view button
         $('#lab_test_worksheet_data_table').on('click', '#edit_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var result_id = $(this).data('id')

            $.ajax({
               url: `${API_URL}` + 'lab-test/view-lab-test-worksheet-data-by-sub-result-id/' + result_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#result_id_tbx').val(data['wk_test_sub_result_id'])
                  $('#result_name_tbx').val(data['wk_test_sub_result_name'])
                  $('#sub_topic_tbx').val(data['wk_test_sub_topic'])
                  $('#unit_category_tbx').val(data['wk_test_units'])

                  // Variables for the third set of inputs
                  $('#default_ref_range_tbx').val(data['wk_test_default_range'])
                  $('#default_val_tbx').val(data['wk_test_default_value'])

                  $('#flug_range_min_female_tbx').val(data['wk_test_flug_range_min_female'])
                  $('#flug_range_max_female_tbx').val(data['wk_test_flug_range_max_female'])

                  $('#flug_range_min_male_tbx').val(data['wk_test_flug_range_min_male'])
                  $('#flug_range_max_male_tbx').val(data['wk_test_flug_range_max_male'])

                  for (var range_show_count = 1; range_show_count <= 14; range_show_count++) {
                     $('#ro_' + range_show_count).removeClass('d-none')
                     $('#ref_range_topic_' + range_show_count + '_tbx').val(data['wk_test_ref_range_topic_' + range_show_count + ''])
                     $('#ref_range_name_' + range_show_count + '_tbx').val(data['wk_test_ref_range_name_' + range_show_count + ''])
                     $('#ref_range_' + range_show_count + '_tbx').val(data['wk_test_ref_range_' + range_show_count + ''])
                  }

                  $('#add_new_ref_btn').hide()
                  $('#submit_btn').hide()

                  $('#new_btn').show()
                  $('#update_btn').show()
               },
            })
         })

         // ? comment update button click event
         $('#comment_update_btn').click(function () {
            var editorContent = $('#editor').html()
            var com_id = $('#comment_id_tbx').val()
            var labTest = $('#lab_test_cmb').val()

            if (editorContent.trim() === '') {
               // Show SweetAlert with auto-close on 2 seconds
               Swal.fire({
                  title: 'Empty Content',
                  text: 'The text editor content is empty.',
                  icon: 'warning',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else {
               const testData = {
                  wk_comment: editorContent,
               }
               $('#comment_update_btn').prop('disabled', true).val('wait...')
               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test/lab-test-worksheet-comment-update/` + com_id,
                  type: 'PUT',
                  contentType: 'application/json',
                  data: JSON.stringify(testData),
                  success: response => {
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'Lab test worksheet Comment Updated successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     // ? view all lab test worksheet comments by lab test id in to table view
                     view_all_lab_worksheet_comments_by_test_id(labTest)

                     // ? Enable the button
                     $('#comment_update_btn').prop('disabled', false).val('Update')
                     $('#editor').html('')
                     $('#comment_submit_btn').show()
                     $('#comment_new_btn').hide()
                     $('#comment_update_btn').hide()
                  },
                  error: error => {
                     // ? Handle error
                     $('#comment_update_btn').prop('disabled', false).val('Update')
                  },
               })
            }
         })

         // ? result update button click event
         $('#update_btn').click(function () {
            var result_id = $('#result_id_tbx').val()

            var labTest = $('#lab_test_cmb').val()

            // Variables for the first set of inputs
            var resultName = $('#result_name_tbx').val()
            var subTopic = $('#sub_topic_tbx').val()
            var unitCategory = $('#unit_category_tbx').val()

            // Variables for the third set of inputs
            var defaultRefRange = $('#default_ref_range_tbx').val()
            var defaultVal = $('#default_val_tbx').val()

            var flugRangeMinFemale = $('#flug_range_min_female_tbx').val()
            var flugRangeMaxFemale = $('#flug_range_max_female_tbx').val()

            var flugRangeMinMale = $('#flug_range_min_male_tbx').val()
            var flugRangeMaxMale = $('#flug_range_max_male_tbx').val()

            // Example for the 1st set of inputs
            var refRangeTopic1 = $('#ref_range_topic_1_tbx').val()
            var refRangeName1 = $('#ref_range_name_1_tbx').val()
            var refRange1 = $('#ref_range_1_tbx').val()

            // Example for the 2nd set of inputs
            var refRangeTopic2 = $('#ref_range_topic_2_tbx').val()
            var refRangeName2 = $('#ref_range_name_2_tbx').val()
            var refRange2 = $('#ref_range_2_tbx').val()

            // Example for the 3rd set of inputs
            var refRangeTopic3 = $('#ref_range_topic_3_tbx').val()
            var refRangeName3 = $('#ref_range_name_3_tbx').val()
            var refRange3 = $('#ref_range_3_tbx').val()

            // Example for the 4th set of inputs
            var refRangeTopic4 = $('#ref_range_topic_4_tbx').val()
            var refRangeName4 = $('#ref_range_name_4_tbx').val()
            var refRange4 = $('#ref_range_4_tbx').val()

            // Example for the 5th set of inputs
            var refRangeTopic5 = $('#ref_range_topic_5_tbx').val()
            var refRangeName5 = $('#ref_range_name_5_tbx').val()
            var refRange5 = $('#ref_range_5_tbx').val()

            // Example for the 6th set of inputs
            var refRangeTopic6 = $('#ref_range_topic_6_tbx').val()
            var refRangeName6 = $('#ref_range_name_6_tbx').val()
            var refRange6 = $('#ref_range_6_tbx').val()

            // Example for the 7th set of inputs
            var refRangeTopic7 = $('#ref_range_topic_7_tbx').val()
            var refRangeName7 = $('#ref_range_name_7_tbx').val()
            var refRange7 = $('#ref_range_7_tbx').val()

            // Example for the 8th set of inputs
            var refRangeTopic8 = $('#ref_range_topic_8_tbx').val()
            var refRangeName8 = $('#ref_range_name_8_tbx').val()
            var refRange8 = $('#ref_range_8_tbx').val()

            // Example for the 9th set of inputs
            var refRangeTopic9 = $('#ref_range_topic_9_tbx').val()
            var refRangeName9 = $('#ref_range_name_9_tbx').val()
            var refRange9 = $('#ref_range_9_tbx').val()

            // Example for the 10th set of inputs
            var refRangeTopic10 = $('#ref_range_topic_10_tbx').val()
            var refRangeName10 = $('#ref_range_name_10_tbx').val()
            var refRange10 = $('#ref_range_10_tbx').val()

            // Example for the 11th set of inputs
            var refRangeTopic11 = $('#ref_range_topic_11_tbx').val()
            var refRangeName11 = $('#ref_range_name_11_tbx').val()
            var refRange11 = $('#ref_range_11_tbx').val()

            // Example for the 12th set of inputs
            var refRangeTopic12 = $('#ref_range_topic_12_tbx').val()
            var refRangeName12 = $('#ref_range_name_12_tbx').val()
            var refRange12 = $('#ref_range_12_tbx').val()

            // Example for the 13th set of inputs
            var refRangeTopic13 = $('#ref_range_topic_13_tbx').val()
            var refRangeName13 = $('#ref_range_name_13_tbx').val()
            var refRange13 = $('#ref_range_13_tbx').val()

            // Example for the 14th set of inputs
            var refRangeTopic14 = $('#ref_range_topic_14_tbx').val()
            var refRangeName14 = $('#ref_range_name_14_tbx').val()
            var refRange14 = $('#ref_range_14_tbx').val()

            var refRangeShowStatus = $('#show_status_cmb').val()

            const testData = {
               wk_lab_test_id: labTest,
               wk_test_sub_result_name: resultName,
               wk_test_sub_topic: subTopic,
               wk_test_units: unitCategory,
               wk_test_default_range: defaultRefRange,
               wk_test_default_value: defaultVal,
               wk_test_flug_range_min_female: flugRangeMinFemale,
               wk_test_flug_range_max_female: flugRangeMaxFemale,
               wk_test_flug_range_min_male: flugRangeMinMale,
               wk_test_flug_range_max_male: flugRangeMaxMale,
               wk_test_ref_range_topic_1: refRangeTopic1,
               wk_test_ref_range_name_1: refRangeName1,
               wk_test_ref_range_1: refRange1,
               wk_test_ref_range_topic_2: refRangeTopic2,
               wk_test_ref_range_name_2: refRangeName2,
               wk_test_ref_range_2: refRange2,
               wk_test_ref_range_topic_3: refRangeTopic3,
               wk_test_ref_range_name_3: refRangeName3,
               wk_test_ref_range_3: refRange3,
               wk_test_ref_range_topic_4: refRangeTopic4,
               wk_test_ref_range_name_4: refRangeName4,
               wk_test_ref_range_4: refRange4,
               wk_test_ref_range_topic_5: refRangeTopic5,
               wk_test_ref_range_name_5: refRangeName5,
               wk_test_ref_range_5: refRange5,
               wk_test_ref_range_topic_6: refRangeTopic6,
               wk_test_ref_range_name_6: refRangeName6,
               wk_test_ref_range_6: refRange6,
               wk_test_ref_range_topic_7: refRangeTopic7,
               wk_test_ref_range_name_7: refRangeName7,
               wk_test_ref_range_7: refRange7,
               wk_test_ref_range_topic_8: refRangeTopic8,
               wk_test_ref_range_name_8: refRangeName8,
               wk_test_ref_range_8: refRange8,
               wk_test_ref_range_topic_9: refRangeTopic9,
               wk_test_ref_range_name_9: refRangeName9,
               wk_test_ref_range_9: refRange9,
               wk_test_ref_range_topic_10: refRangeTopic10,
               wk_test_ref_range_name_10: refRangeName10,
               wk_test_ref_range_10: refRange10,
               wk_test_ref_range_topic_11: refRangeTopic11,
               wk_test_ref_range_name_11: refRangeName11,
               wk_test_ref_range_11: refRange11,
               wk_test_ref_range_topic_12: refRangeTopic12,
               wk_test_ref_range_name_12: refRangeName12,
               wk_test_ref_range_12: refRange12,
               wk_test_ref_range_topic_13: refRangeTopic13,
               wk_test_ref_range_name_13: refRangeName13,
               wk_test_ref_range_13: refRange13,
               wk_test_ref_range_topic_14: refRangeTopic14,
               wk_test_ref_range_name_14: refRangeName14,
               wk_test_ref_range_14: refRange14,
               wk_test_ref_range_show_status: refRangeShowStatus,
            }

            if (!resultName) {
               // Check if resultName is empty
               Swal.fire({
                  icon: 'warning',
                  title: 'Result name is required!',
                  text: 'Please enter a result name.',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else {
               $('#update_btn').prop('disabled', true).val('wait...')
               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test/lab-test-worksheet-data-update/` + result_id,
                  type: 'PUT',
                  contentType: 'application/json',
                  data: JSON.stringify(testData),
                  success: response => {
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'Lab test worksheet Result Data Updated successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     $('#add_lab_test_worksheet_data_form')[0].reset()

                     // ? view all lab test worksheet data by lab test id in to table view
                     view_all_lab_worksheet_data_by_test_id(labTest)

                     // ? Enable the button
                     $('#update_btn').prop('disabled', false).val('Update')
                     $('#add_new_ref_btn').show()
                     $('#submit_btn').show()

                     $('#new_btn').hide()
                     $('#update_btn').hide()

                     for (var range_show_count = 2; range_show_count <= 14; range_show_count++) {
                        $('#ro_' + range_show_count).addClass('d-none')
                     }
                  },
                  error: error => {
                     // ? Handle error
                     $('#update_btn').prop('disabled', false).val('Update')
                  },
               })
            }
         })
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })

         // Function to call the getHospitalNamesAndID API
      },
   },
}
</script>
<style lang=""></style>
