<template>
   <div id="App">
      <router-view></router-view>
   </div>
</template>

<script></script>

<style>
/* ... styles if needed */
</style>
