import PatientDuePayments from '../pages/payments/PatientDuePayments.vue'
import RePrint from '../pages/payments/BillReprint.vue'
const PaymentsRoutes = [
   // ? view all patient due payments page view rout
   {
      path: '/patient-due-payments',
      name: '/patient-due-payments',
      component: PatientDuePayments,
      meta: { requiresAuth: true },
   },

   // ? view all reprint page view rout
   {
      path: '/reprint',
      name: '/reprint',
      component: RePrint,
      meta: { requiresAuth: true },
   },
]

export default PaymentsRoutes
