<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>
            <div class="container-fluid">
               <div class="row">
                  <!-- Left Column -->
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card border border-primary">
                           <div class="iq-card-body">
                              <form>
                                 <form>
                                    <div class="row">
                                       <div class="col-md-1">
                                          <label for="start_date_dtmp" class="">From Date</label>
                                       </div>
                                       <div class="col-md-4">
                                          <input
                                             type="date"
                                             class="form-control form-control-sm font-weight-bold"
                                             id="start_date_dtmp"
                                             :value="getDateWithOffset(-360)"
                                          />
                                       </div>
                                       <div class="col-md-1">
                                          <label for="end_date_dtmp" class="">To Date</label>
                                       </div>
                                       <div class="col-md-4">
                                          <input
                                             type="date"
                                             class="form-control form-control-sm font-weight-bold"
                                             id="end_date_dtmp"
                                             :value="getCurrentDate()"
                                          />
                                       </div>
                                       <div class="col-md-1">
                                          <input class="btn btn-danger btn-sm btn-block" type="button" id="refresh_btn" value="Refresh" />
                                       </div>
                                    </div>
                                 </form>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="form-row mb-3">
                  <div class="col">
                     <input type="text" id="search" class="form-control form-control-sm font-weight-bold" placeholder="Search..." />
                  </div>
                  <div class="col">
                     <select id="sortColumn" class="form-control form-control-sm font-weight-bold">
                        <option value="lab_tra_date">Date</option>
                        <option value="pat_name">Patient Name</option>
                        <option value="lab_test_name">Lab Test Name</option>
                     </select>
                  </div>
                  <div class="col">
                     <select id="sortOrder" class="form-control form-control-sm font-weight-bold">
                        <option value="ASC">Ascending</option>
                        <option value="DESC">Descending</option>
                     </select>
                  </div>
                  <div class="col">
                     <select id="allTestCompletedStatus" class="form-control form-control-sm font-weight-bold">
                        <option value="">All</option>
                        <option value="Y">Completed</option>
                        <option value="N">Not Completed</option>
                     </select>
                  </div>
               </div>
               <div id="labTestData" class="row"></div>
               <nav>
                  <ul class="pagination justify-content-center">
                     <li class="page-item"><a class="page-link" href="#" id="prevPage">Previous</a></li>
                     <li class="page-item"><a class="page-link" href="#" id="nextPage">Next</a></li>
                  </ul>
               </nav>
            </div>

            <div class="modal fade bd-example-modal-lg" id="send_sms_model" tabindex="-1" role="dialog" aria-hidden="true">
               <div class="modal-dialog modal-md">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h4 class="modal-title font-weight-bold">Send SMS</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <form class="needs-validation" id="send_sms_form" onsubmit="return false" novalidate>
                           <div class="form-row">
                              <div class="col-md-12 mb-3">
                                 <label for="name_tbx" class="font-weight-bold"> Name <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control" placeholder="Name" id="name_tbx" readonly />
                              </div>
                              <div class="col-md-12 mb-3">
                                 <label for="bill_no_tbx" class="font-weight-bold"> Bill No <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control" placeholder="Bill No" id="bill_no_tbx" readonly />
                              </div>
                              <div class="col-md-12 mb-3">
                                 <label for="contact_no_tbx" class="font-weight-bold"> Contact No <span class="text-danger">*</span> </label>
                                 <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Contact"
                                    id="contact_no_tbx"
                                    maxlength="10"
                                    minlength="10"
                                    pattern="07[0-9]{8}"
                                    required
                                 />
                                 <small class="form-text text-muted">Enter a valid Sri Lankan mobile number (e.g., 0712345678).</small>
                              </div>
                              <div class="invalid-feedback"> Please enter a valid mobile number (e.g., 0701234567). </div>
                           </div>
                           <div class="modal-footer">
                              <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                              <input class="btn btn-primary" type="submit" id="send_btn" value="Send" />
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, ENCRYPT_SCRT_KEY, PATIENT_REPORT_LINK, SMS_LINK } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS
import Chart from 'chart.js/auto'
import CryptoJS from 'crypto-js'

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'sms', ['active', 'main-active'])
      this.updateElementClasses('sub', 'send-test-results-sms', ['active'])

      $(document).ready(function () {
         let currentPage = 1
         let limit = 20
         let startDate = $('#start_date_dtmp').val() // Initialize start date from input
         let endDate = $('#end_date_dtmp').val() // Initialize end date from input

         // Function to fetch lab test data
         function fetchLabTestData() {
            let search = $('#search').val()
            let sortColumn = $('#sortColumn').val()
            let sortOrder = $('#sortOrder').val()
            let allTestCompletedStatus = $('#allTestCompletedStatus').val()

            // AJAX request
            $.ajax({
               url: `${API_URL}lab-test-transaction/view-all-lab-test-transaction-data-to-send-sms`,
               data: {
                  search: search,
                  sortColumn: sortColumn,
                  sortOrder: sortOrder,
                  page: currentPage,
                  limit: limit,
                  startDate: startDate,
                  endDate: endDate,
                  allTestCompletedStatus: allTestCompletedStatus,
               },
               success: function (data) {
                  $('#labTestData').empty()

                  for (let lab_tra_id in data) {
                     let group = data[lab_tra_id]
                     let firstTest = group.tests[0]

                     let smsStatusText = ''
                     let smsStatusClass = ''

                     if (group.smsStatus === 'Y') {
                        smsStatusText = 'SMS Already Sent'
                        smsStatusClass = 'badge-success'
                     } else if (group.smsStatus === 'N') {
                        smsStatusText = 'SMS Not Sent Yet'
                        smsStatusClass = 'badge-warning'
                     } else {
                        smsStatusText = 'Unknown' // Handle any other cases if needed
                        smsStatusClass = 'badge-secondary'
                     }

                     let allTestCompletedStatusText = group.allTestCompletedStatus === 'Y' ? 'All Tests Completed' : 'Not Completed Yet'
                     let allTestCompletedStatusClass = group.allTestCompletedStatus === 'Y' ? 'badge-success' : 'badge-danger'

                     // Function to format the date to YYYY-MM-DD
                     const formatDate = dateString => {
                        let date = new Date(dateString)
                        let year = date.getFullYear()
                        let month = ('0' + (date.getMonth() + 1)).slice(-2)
                        let day = ('0' + date.getDate()).slice(-2)
                        return `${year}-${month}-${day}`
                     }

                     let groupHtml = `
<div class="col-md-12 mb-4">
   <div class="card rounded border-primary shadow-sm">
      <div class="card-body bg-light">
         <div class="row mb-3">
            <div class="col">
               <h5 class="font-weight-bold mb-0">${firstTest.pat_name}</h5>
               <small>Age: ${firstTest.pat_age}</small> || <small>Contact: ${firstTest.pat_contact}</small>
            </div>
            <div class="col text-right">
               <span class="badge badge-warning">Total Tests: ${group.count}</span>
               <span class="badge badge-success">Completed: ${group.completeCount}</span>
            </div>
         </div>
         <div class="row">
            <div class="col">
               <small>Date: ${formatDate(firstTest.lab_tra_date)}</small><br>
               <small>Bill No: ${lab_tra_id}</small><br>
               <small>Due Balance: Rs. ${firstTest.pl_acc_avb_balance}</small><br>
            </div>
            <div class="col text-right">
               <span class="badge ${allTestCompletedStatusClass}">${allTestCompletedStatusText}</span>
            </div>
         </div>
      </div>
      <div class="card-header bg-primary text-white">
         <div class="row">
            <div class="col text-right">
               <span class="badge ${smsStatusClass}">${smsStatusText}</span>
               <button id="send_sms_btn" data-id=${firstTest.lab_tra_cus_id} class="btn btn-sm btn-danger mr-2">Send SMS</button>
            </div>
         </div>
      </div>
      <div class="card-body">
   `

                     group.tests.forEach(test => {
                        let statusText = ''
                        let statusClass = ''

                        if (test.lab_tra_complete_status === 'Y') {
                           statusText = 'Complete'
                           statusClass = 'badge badge-success'
                        } else if (test.lab_tra_complete_status === 'N') {
                           statusText = 'Pending'
                           statusClass = 'badge bg-primary'
                        } else if (test.lab_tra_complete_status === 'A') {
                           statusText = 'Need To Be Approved'
                           statusClass = 'badge badge-warning'
                        } else if (test.lab_tra_complete_status === 'R') {
                           statusText = 'Need To Be Approved'
                           statusClass = 'badge bg-secondary'
                        } else if (test.lab_tra_complete_status === 'C') {
                           statusText = 'Need To Be Approved'
                           statusClass = 'badge badge-danger'
                        }

                        groupHtml += `
<div class="mb-3">
   <h6 class="mb-0"><b>${test.lab_test_name}</b></h6>
   <p class="mb-0">Status: <span class="${statusClass}">${statusText}</span></p>
</div>`
                     })

                     groupHtml += `
      </div>
      <div class="card-footer text-muted text-right">
         Last Updated: ${formatDate(new Date())}
      </div>
   </div>
</div>`

                     $('#labTestData').append(groupHtml)
                  }
               },
            })
         }

         // Event handlers
         $('#search, #sortColumn, #sortOrder, #start_date_dtmp, #end_date_dtmp, #allTestCompletedStatus').on('change', function () {
            startDate = $('#start_date_dtmp').val() // Update start date on change
            endDate = $('#end_date_dtmp').val() // Update end date on change
            fetchLabTestData() // Fetch data when any of these inputs change
         })

         $('#refresh_btn').on('click', function () {
            fetchLabTestData() // Fetch data when Refresh button is clicked
         })

         // Pagination handlers
         $('#prevPage').on('click', function (e) {
            e.preventDefault()
            if (currentPage > 1) {
               currentPage--
               fetchLabTestData()
            }
         })

         $('#nextPage').on('click', function (e) {
            e.preventDefault()
            currentPage++
            fetchLabTestData()
         })

         fetchLabTestData()

         $('#labTestData').on('click', '#send_sms_btn', function () {
            // Get the data-id attribute value
            let lab_tra_cus_id = $(this).data('id')

            // Find the closest card container and retrieve the required values
            let card = $(this).closest('.card')
            let pat_name = card.find('.font-weight-bold').text()
            let pat_contact = card.find('small:contains("Contact:")').text().replace('Contact: ', '').trim()
            let bill_no = card.find('small:contains("Bill No:")').text().replace('Bill No: ', '').trim()

            $('#contact_no_tbx').val(pat_contact)
            $('#name_tbx').val(pat_name)
            $('#bill_no_tbx').val(bill_no)

            const modalElement = document.getElementById('send_sms_model')
            const modal = new bootstrap.Modal(modalElement)
            modal.show()
         })

         function encryptValue(value) {
            // Replace with your secret key (must be kept secure and never hardcode it)
            var secretKey = `${ENCRYPT_SCRT_KEY}`

            // Encrypt using AES encryption with CBC mode
            var encrypted = CryptoJS.AES.encrypt(value, secretKey).toString()

            // Replace URL-unsafe characters with safe ones
            encrypted = encrypted.replace(/\//g, '_').replace(/\+/g, '-')

            return encrypted
         }

         // ? contact number validate
         $('#contact_no_tbx').on('input', function () {
            $(this).val($(this).val().replace(/\D/g, '')) // Remove non-digit characters
         })

         // ? send sms to button click event
         $('#send_btn').on('click', e => {
            const input = $('#contact_no_tbx').val()
            const pattern = /^07[0-9]{8}$/

            if (!pattern.test(input)) {
               // Prevent form submission
               Swal.fire({
                  icon: 'error',
                  title: 'Invalid Number',
                  text: 'Please enter a valid Sri Lankan mobile number (e.g., 0712345678).',
                  timer: 3000,
                  showConfirmButton: false,
               }).then(function () {
                  $('#contact_no_tbx').focus() // Focus on the input field after alert is closed
               })
            } else {
               $('#send_btn').prop('disabled', true).val('sending...')

               var pat_name = $('#name_tbx').val()
               var contact = $('#contact_no_tbx').val()
               var bill_no = $('#bill_no_tbx').val()
               var message = ''

               var encrypt_bill_no = encryptValue(bill_no)
               var report_view_link = `${SMS_LINK}` + encrypt_bill_no

               console.log(report_view_link)

               message =
                  'Dear ' +
                  pat_name +
                  ',\n\n' +
                  'Your medical test results are ready!\n\n' +
                  'Please visit : ' +
                  report_view_link +
                  '\n' +
                  'You can also visit our branches for further details.\n\n' +
                  'Thank you!'

               var data_pack = {
                  contact_no: contact,
                  sms_text: message,
               }

               $.ajax({
                  url: `${API_URL}` + 'sms-api/send-sms',
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(data_pack),
                  success: function (response) {
                     var data_pack2 = {
                        trSmsType: 'S',
                        trSmsDebit: response.trSmsDebit,
                        trSmsCredit: '0',
                        trSmsDescription: 'Patient Test Results Send',
                        trSmsSms: message,
                        trContact: contact,
                     }

                     $.ajax({
                        url: `${API_URL}` + 'sms-api/sms-balance-transaction-data-insert/',
                        type: 'POST',
                        contentType: 'application/json',
                        data: JSON.stringify(data_pack2),
                        success: function (response) {
                           $.ajax({
                              url: `${API_URL}` + 'sms-api/sms-balance-update-sp-call/',
                              type: 'POST',
                              contentType: 'application/json',
                              success: function (response) {
                                 // ? Show success message
                                 $.ajax({
                                    url: `${API_URL}` + 'lab-test-transaction/update-test-complete-send-sms-status/' + bill_no,
                                    type: 'PUT',
                                    contentType: 'application/json',
                                    success: function (response) {
                                       fetchLabTestData()
                                       // ? Show success message
                                       Swal.fire({
                                          icon: 'success',
                                          title: 'SMS sent successfully!',
                                          text: 'well done..',
                                          timer: 2000,
                                          showConfirmButton: true,
                                          allowOutsideClick: true,
                                       })
                                       // ? Enable the button
                                       $('#patient_add_btn').prop('disabled', false).val('Send')
                                    },
                                    error: function (xhr, status, error) {
                                       Swal.fire({
                                          title: 'Failed to send SMS',
                                          text: xhr.responseText,
                                          icon: 'error',
                                          timer: 2000,
                                          showConfirmButton: true,
                                          allowOutsideClick: true,
                                       })
                                       // ? Enable the button
                                       $('#patient_add_btn').prop('disabled', false).val('Send')
                                    },
                                 })
                              },
                              error: function (xhr, status, error) {
                                 Swal.fire({
                                    title: 'Failed to send SMS',
                                    text: xhr.responseText,
                                    icon: 'error',
                                    timer: 2000,
                                    showConfirmButton: true,
                                    allowOutsideClick: true,
                                 })

                                 // ? Enable the button
                                 $('#patient_add_btn').prop('disabled', false).val('Send')
                              },
                           })
                        },
                        error: function (xhr, status, error) {
                           Swal.fire({
                              title: 'Failed to send SMS',
                              text: xhr.responseText,
                              icon: 'error',
                              timer: 2000,
                              showConfirmButton: true,
                              allowOutsideClick: true,
                           })
                        },
                     })
                  },
                  error: function (xhr, status, error) {
                     Swal.fire({
                        title: 'Failed to send SMS',
                        text: xhr.responseText,
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })
                  },
               })
            }
         })
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>

<style></style>
