<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Update Lab Test Results</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-8 mb-1">
                                 <label class="">Patient Name <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="patient_id_cmb" name="patient_id_cmb"> </Select2>
                              </div>
                              <div class="col-md-4 mb-1">
                                 <label class="">Test Reference ID <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="reference_id_search_tbx" />
                              </div>
                           </div>
                           <div class="form-row"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12 col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                              <li class="nav-item">
                                 <a
                                    class="nav-link active"
                                    id="pills-home-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-home-fill"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                    >To Be Update</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="pills-profile-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-profile-fill"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                    >To Be Approved</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="pills-contact-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-contact-fill"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false"
                                    >Completed</a
                                 >
                              </li>
                           </ul>
                           <div class="tab-content" id="pills-tabContent-1">
                              <div class="tab-pane fade show active" id="pills-home-fill" role="tabpanel" aria-labelledby="pills-home-tab-fill">
                                 <div class="table-responsive">
                                    <table id="to_be_update_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Status</th>
                                             <th>Action</th>
                                             <th>Test</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Patient Name</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody></tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Status</th>
                                             <th>Action</th>
                                             <th>Test</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Patient Name</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="pills-profile-fill" role="tabpanel" aria-labelledby="pills-profile-tab-fill">
                                 <div class="table-responsive">
                                    <table id="to_be_approved_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Status</th>
                                             <th>Action</th>
                                             <th>Test</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Patient Name</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody></tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Status</th>
                                             <th>Action</th>
                                             <th>Test</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Patient Name</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="pills-contact-fill" role="tabpanel" aria-labelledby="pills-contact-tab-fill">
                                 <div class="table-responsive">
                                    <table id="completed_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Status</th>
                                             <th>View Results</th>
                                             <th>Test</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Patient Name</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody></tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Status</th>
                                             <th>View Results</th>
                                             <th>Test</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Patient Name</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade bd-example-modal-xl" id="resultUpdateModal" tabindex="-1" role="dialog" aria-hidden="true">
               <div class="modal-dialog modal-xl" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="fileUploadModalLabel"> Result Update Page </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <ul class="nav nav-pills mb-3 nav-fill" id="myTab" role="tablist">
                           <li class="nav-item">
                              <a
                                 class="nav-link active"
                                 id="results-tab"
                                 data-toggle="tab"
                                 href="#results"
                                 role="tab"
                                 aria-controls="results"
                                 aria-selected="true"
                                 >Results</a
                              >
                           </li>
                           <li class="nav-item">
                              <a
                                 class="nav-link"
                                 id="comments-tab"
                                 data-toggle="tab"
                                 href="#comments"
                                 role="tab"
                                 aria-controls="comments"
                                 aria-selected="false"
                                 >Comments</a
                              >
                           </li>
                        </ul>
                        <div class="tab-content">
                           <div class="tab-pane fade show active" id="results" role="tabpanel" aria-labelledby="results-tab">
                              <form id="result_update_form">
                                 <input type="hidden" id="ref_id_tbx" value="" />
                                 <input type="hidden" id="test_id_tbx" value="" />
                                 <div id="result_div"></div>
                              </form>
                           </div>
                           <div class="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                              <!-- Add your comments form or content here -->
                              <div class="row">
                                 <!-- Left Column -->
                                 <div class="col-md-12">
                                    <div class="iq-card">
                                       <div class="row">
                                          <div class="col-md-12">
                                             <input type="hidden" class="form-control form-control-sm" id="comment_id_tbx" />
                                             <div class="editor-container">
                                                <div class="btn-group" role="group" aria-label="Text editor controls">
                                                   <!-- Bold -->
                                                   <button
                                                      type="button"
                                                      class="btn btn-secondary"
                                                      onclick="document.execCommand('bold', false, null)"
                                                   >
                                                      <strong>B</strong>
                                                   </button>
                                                   <!-- Underline -->
                                                   <button
                                                      type="button"
                                                      class="btn btn-secondary"
                                                      onclick="document.execCommand('underline', false, null)"
                                                   >
                                                      <u>U</u>
                                                   </button>
                                                   <!-- Italic -->
                                                   <button
                                                      type="button"
                                                      class="btn btn-secondary"
                                                      onclick="document.execCommand('italic', false, null)"
                                                   >
                                                      <em>I</em>
                                                   </button>
                                                   <!-- Unordered list -->
                                                   <button
                                                      type="button"
                                                      class="btn btn-secondary"
                                                      onclick="document.execCommand('insertUnorderedList', false, null)"
                                                   >
                                                      <span>&#8226;</span>
                                                   </button>
                                                   <!-- Text Color -->
                                                   <!-- <input type="color" id="text-color" class="btn btn-secondary" /> -->
                                                   <!-- Font Size -->
                                                </div>
                                                <div contenteditable="true" class="form-control mt-3" id="editor" style="min-height: 200px"></div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-md-2 mt-2">
                                             <input class="btn btn-success btn-sm btn-block" type="button" id="comment_submit_btn" value="Submit" />
                                          </div>
                                          <div class="col-md-2 mt-2">
                                             <input class="btn btn-info btn-sm btn-block" type="button" id="comment_new_btn" value="New" />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <form id="comments_form">
                                 <div id="comments-container"></div>
                              </form>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer d-flex justify-content-between">
                        <!-- Left Side: Custom Switch -->
                        <div
                           class="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline"
                           id="result_auto_cal_dev"
                        >
                           <div class="custom-switch-inner">
                              <p class="mb-0"> Auto Calculate Results </p>
                              <input type="checkbox" class="custom-control-input bg-success" id="result_auto_cal_chb" checked="" />
                              <label class="custom-control-label" for="result_auto_cal_chb" data-on-label="Yes" data-off-label="No"> </label>
                           </div>
                        </div>
                        <!-- Right Side: Buttons -->
                        <div>
                           <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                           <input class="btn btn-info" type="submit" id="approve_btn" value="Approve Result" />
                           <input class="btn btn-warning" type="submit" id="retest_btn" value="Need To Retest Result" />
                           <input class="btn btn-success" type="submit" id="submit_btn" value="Update Result" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, FILE_UPLOAD_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'result', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-test-results', ['active'])

      $(document).ready(function () {
         // Check userType and show buttons accordingly
         $('#approve_btn').hide()
         $('#retest_btn').hide()

         // ? get all patient names and ids into select patient dropdown
         get_patient_names_id()

         // ? comment delete function call
         setupDeleteCommentListener()

         // ? get all patient names and ids into select patient dropdown
         function get_patient_names_id() {
            $.ajax({
               url: `${API_URL}patient/patient-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#patient_id_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(patient => {
                        const option = new Option(patient.show_name, patient.pat_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#patient_id_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         $('#patient_id_cmb').on('change', function () {
            var patientID = $(this).val()

            // ? view all to be result update table data
            lab_test_transaction_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'N', 'R'])

            // ? view all to approve and to be retest result approve table data
            to_be_approve_lab_test_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'R'])

            // ? view all to complete lab test result test table data
            completed_lab_test_load_data_table(patientID, ['Y'], ['Y'])
         })

         // Click event for "To Be Update" link
         $('#pills-home-tab-fill').on('click', () => {
            var patientID = $('#patient_id_cmb').val()
            // ? view all to be result update table data
            lab_test_transaction_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'N', 'R'])
         })

         // Click event for "To Be Approved" link
         $('#pills-profile-tab-fill').on('click', () => {
            var patientID = $('#patient_id_cmb').val()
            // ? view all to approve and to be retest result approve table data
            to_be_approve_lab_test_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'R'])
         })

         // Click event for "Completed" link
         $('#pills-contact-tab-fill').on('click', () => {
            var patientID = $('#patient_id_cmb').val()
            // ? view all to complete lab test result test table data
            completed_lab_test_load_data_table(patientID, ['Y'], ['Y'])
         })

         // ? view all to be result update table data
         function lab_test_transaction_load_data_table(patientID, updateStatus, completeStatus) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}lab-test/view-all-lab-test-by-patient-id-and-status/` +
                     patientID +
                     `?resultUpdateStatus=` +
                     updateStatus +
                     `&completeStatus=` +
                     completeStatus,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                  },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        if (data === 'Y') {
                           return '<span class="badge badge-success">Completed</span>'
                        } else if (data === 'R') {
                           return '<span class="badge" style="background-color: darkorange; color: white;">Need To Retest</span>'
                        } else if (data === 'A') {
                           return '<span class="badge badge-primary">To Be Approved</span>'
                        } else {
                           return '<span class="badge badge-danger">Pending</span>'
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-warning btn-sm" id="edit_btn" data-id="' +
                           row.id +
                           '" data-cusid="' +
                           row.lab_tra_cus_id +
                           '" data-testid="' +
                           row.lab_tra_lab_test_id +
                           '"><i class="fa-sharp fa-solid fa-pen"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'lab_test_name',
                  },
                  {
                     data: 'lab_tra_date',
                  },
                  {
                     data: 'lab_tra_time',
                  },
                  {
                     data: 'pat_name',
                  },
                  {
                     data: 'brach_name',
                  },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#to_be_update_table').DataTable(dataTableOptions)
         }

         // ? data table upload report and receive test click event handler
         $('#to_be_update_table').on('click', '#edit_btn', function () {
            // Get the data-id attribute of the clicked button
            var reference_id = $(this).data('id')
            var user_id = window.globalData.id
            var patient_id = $(this).data('cusid')
            var test_id = $(this).data('testid')

            $('#ref_id_tbx').val(reference_id)
            $('#test_id_tbx').val(test_id)

            // Clear the content of the result_div
            $('#result_div').empty()

            // Create a new lab test result sheet
            $.ajax({
               url: `${API_URL}lab-test/create-new-empty-lab-test-result-sheet/${test_id}/${reference_id}/${patient_id}/${user_id}`,
               method: 'POST',
               dataType: 'json',
               success: function (data, textStatus, jqXHR) {
                  // Assuming the server returns a JSON object with a `statusCode` field
                  var statusCode = jqXHR.status

                  // Handle different status codes accordingly
                  if (statusCode === 200 || statusCode === 201) {
                     form_create(reference_id)
                  } else {
                     // Handle other status codes
                     console.error('Unexpected status code:', statusCode)
                  }
               },
               error: function (xhr, status, error) {
                  console.error('Error creating new lab test result sheet:', error)
               },
            })
         })

         function form_create(reference_id) {
            // On success, fetch lab test result sheet data based on reference ID
            $.ajax({
               url: `${API_URL}lab-test/view-all-lab-test-result-sheet-data-to-make-update-result-form-by-ref-id/${reference_id}`,
               method: 'GET',
               success: function (data) {
                  // Iterate over fetched data and create input labels and fields
                  data.forEach(function (item, index) {
                     // Create a div for form row
                     var formRow = $('<div class="form-row"></div>')

                     // Create label element with appropriate column classes
                     var label = $('<label class="col-sm-4 col-form-label font-weight-bold"></label>') // Adjust col-sm-4 based on your preference
                        .attr('for', 'input_' + index)
                        .text(item.wk_test_sub_result_name + ' (' + item.wk_test_units + ')')

                     // Create div for input with appropriate column classes
                     var inputWrapper = $('<div class="col-sm-8"></div>') // Adjust col-sm-8 based on your preference

                     // Create input element
                     var input = $('<input type="text" class="form-control form-control-sm"></input>')
                        .attr('id', '' + item.res_id)
                        .attr('name', '' + item.res_id)
                        .val(item.res_result_value)

                     // Append input to inputWrapper
                     inputWrapper.append(input)

                     // Append label and inputWrapper to form row
                     formRow.append(label)
                     formRow.append(inputWrapper)

                     // Append form row to result_div
                     $('#result_div').append(formRow.clone()) // Cloning the formRow to avoid moving it from its original location
                  })

                  $('#approve_btn').hide()
                  $('#retest_btn').hide()
                  $('#submit_btn').show()
                  $('#result_auto_cal_dev').show()

                  // To check the checkbox
                  $('#result_auto_cal_chb').prop('checked', true)

                  // Show the modal after updating result_div
                  const modalElement = document.getElementById('resultUpdateModal')
                  const modal = new bootstrap.Modal(modalElement)
                  modal.show()
               },
               error: function (xhr, status, error) {
                  console.error('Error fetching data:', error)
               },
            })

            //? load all lab test result related comments
            reload_comments(reference_id)
         }

         //? load all lab test result related comments
         function reload_comments(reference_id) {
            // ? load all comment by ref id
            $.ajax({
               url: `${API_URL}lab-test/view-all-lab-test-result-comments-by-ref-id/` + reference_id,
               method: 'GET',
               success: function (data) {
                  displayComments(data)
               },
               error: function (error) {
                  console.error('Error fetching comments:', error)
               },
            })
         }

         $('#submit_btn').on('click', () => {
            var formData = $('#result_update_form').serializeArray() // Serialize form data
            // Initialize an array to store the form data
            var formDataArray = []
            var user_id = window.globalData.id

            var lab_test_id = $('#test_id_tbx').val()

            var reference_id = $('#ref_id_tbx').val()

            // Check if the checkbox is checked
            var isChecked = $('#result_auto_cal_chb').prop('checked')

            var result_auto_cal_status = ''

            // Display the value in the console or an alert
            if (isChecked) {
               result_auto_cal_status = 'Y'
            } else {
               result_auto_cal_status = 'N'
            }

            // Iterate over form data and construct alert message
            formData.forEach(function (item) {
               var dataPair = {}
               dataPair[item.name] = item.value
               formDataArray.push(dataPair)
            })

            $('#submit_btn').prop('disabled', true).val('wait...')
            // ? Make the POST request using $.ajax()
            // Make the AJAX call
            $.ajax({
               url: `${API_URL}lab-test/lab-test-result-sheet-result-update/${user_id}/${reference_id}/${lab_test_id}/${result_auto_cal_status}/${encodeURIComponent(
                  JSON.stringify(formDataArray)
               )}`,
               type: 'PUT', // Assuming this is a POST request as it's updating data
               success: function (response) {
                  // Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'Lab Test Results Updated Successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  var patientID = $('#patient_id_cmb').val()
                  // ? view all to be result update table data
                  lab_test_transaction_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'N', 'R'])

                  // ? view all to approve and to be retest result approve table data
                  to_be_approve_lab_test_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'R'])

                  // ? view all to complete lab test result test table data
                  completed_lab_test_load_data_table(patientID, ['Y'], ['Y'])

                  $('[data-dismiss="modal"]').click()

                  $('#submit_btn').prop('disabled', false).val('Update Result')
               },
               error: function (xhr, status, error) {
                  // Handle error
                  console.error(xhr.responseText)
                  // ? Handle error
                  $('#submit_btn').prop('disabled', false).val('Update Result')
               },
            })
         })

         // ? view all to approve and to be retest result approve table data
         function to_be_approve_lab_test_load_data_table(patientID, updateStatus, completeStatus) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}lab-test/view-all-lab-test-by-patient-id-and-status/` +
                     patientID +
                     `?resultUpdateStatus=` +
                     updateStatus +
                     `&completeStatus=` +
                     completeStatus,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                  },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        if (data === 'Y') {
                           return '<span class="badge badge-success">Completed</span>'
                        } else if (data === 'R') {
                           return '<span class="badge" style="background-color: darkorange; color: white;">Need To Retest</span>'
                        } else if (data === 'A') {
                           return '<span class="badge badge-primary">To Be Approved</span>'
                        } else {
                           return '<span class="badge badge-danger">Pending</span>'
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-warning btn-sm" id="approve_tbl_btn" data-id="' +
                           row.id +
                           '" data-cusid="' +
                           row.lab_tra_cus_id +
                           '" data-testid="' +
                           row.lab_tra_lab_test_id +
                           '"><i class="fa-sharp fa-solid fa-pen"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'lab_test_name',
                  },
                  {
                     data: 'lab_tra_date',
                  },
                  {
                     data: 'lab_tra_time',
                  },
                  {
                     data: 'pat_name',
                  },
                  {
                     data: 'brach_name',
                  },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#to_be_approved_table').DataTable(dataTableOptions)
         }

         // ? test result approve and retest select model show button click
         $('#to_be_approved_table').on('click', '#approve_tbl_btn', function () {
            // Get the data-id attribute of the clicked button
            var reference_id = $(this).data('id')
            var user_id = window.globalData.id
            var patient_id = $(this).data('cusid')
            var test_id = $(this).data('testid')

            $('#ref_id_tbx').val(reference_id)
            $('#test_id_tbx').val(test_id)

            // Clear the content of the result_div
            $('#result_div').empty()

            const commentsContainer = $('#comments-container')
            commentsContainer.empty()

            // On success, fetch lab test result sheet data based on reference ID
            $.ajax({
               url: `${API_URL}lab-test/view-all-lab-test-result-sheet-data-to-make-update-result-form-by-ref-id/${reference_id}`,
               method: 'GET',
               success: function (data) {
                  // Iterate over fetched data and create input labels and fields
                  data.forEach(function (item, index) {
                     // Create a div for form row
                     var formRow = $('<div class="form-row"></div>')

                     // Create label element with appropriate column classes
                     var label = $('<label class="col-sm-4 col-form-label font-weight-bold"></label>') // Adjust col-sm-4 based on your preference
                        .attr('for', 'input_' + index)
                        .text(item.wk_test_sub_result_name + ' (' + item.wk_test_units + ')')

                     // Create div for input with appropriate column classes
                     var inputWrapper = $('<div class="col-sm-8"></div>') // Adjust col-sm-8 based on your preference

                     // Create input element
                     var input = $('<input type="text" class="form-control form-control-sm"></input>')
                        .attr('id', '' + item.res_id)
                        .attr('name', '' + item.res_id)
                        .val(item.res_result_value)

                     // Append input to inputWrapper
                     inputWrapper.append(input)

                     // Append label and inputWrapper to form row
                     formRow.append(label)
                     formRow.append(inputWrapper)

                     // Append form row to result_div
                     $('#result_div').append(formRow.clone()) // Cloning the formRow to avoid moving it from its original location
                  })

                  if (window.globalData.userType === 'UA' || window.globalData.userType === 'SA') {
                     $('#approve_btn').show()
                     $('#retest_btn').show()
                  }

                  $('#result_auto_cal_dev').show()

                  // To check the checkbox
                  $('#result_auto_cal_chb').prop('checked', true)

                  $('#submit_btn').show()

                  // Show the modal after updating result_div
                  const modalElement = document.getElementById('resultUpdateModal')
                  const modal = new bootstrap.Modal(modalElement)
                  modal.show()
               },
               error: function (xhr, status, error) {
                  console.error('Error fetching data:', error)
               },
            })

            //? load all lab test result related comments
            reload_comments(reference_id)
         })

         // ? view all to complete lab test result test table data
         function completed_lab_test_load_data_table(patientID, updateStatus, completeStatus) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}lab-test/view-all-lab-test-by-patient-id-and-status/` +
                     patientID +
                     `?resultUpdateStatus=` +
                     updateStatus +
                     `&completeStatus=` +
                     completeStatus,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                  },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        if (data === 'Y') {
                           return '<span class="badge badge-success">Completed</span>'
                        } else if (data === 'R') {
                           return '<span class="badge" style="background-color: darkorange; color: white;">Need To Retest</span>'
                        } else if (data === 'A') {
                           return '<span class="badge badge-primary">To Be Approved</span>'
                        } else {
                           return '<span class="badge badge-danger">Pending</span>'
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-warning btn-sm" id="view_tbl_btn" data-id="' +
                           row.id +
                           '" data-cusid="' +
                           row.lab_tra_cus_id +
                           '" data-testid="' +
                           row.lab_tra_lab_test_id +
                           '"><i class="fa-sharp fa-solid fa-pen"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'lab_test_name',
                  },
                  {
                     data: 'lab_tra_date',
                  },
                  {
                     data: 'lab_tra_time',
                  },
                  {
                     data: 'pat_name',
                  },
                  {
                     data: 'brach_name',
                  },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#completed_table').DataTable(dataTableOptions)
         }

         // ? test result approve and retest select model show button click
         $('#completed_table').on('click', '#view_tbl_btn', function () {
            // Get the data-id attribute of the clicked button
            var reference_id = $(this).data('id')
            var user_id = window.globalData.id
            var patient_id = $(this).data('cusid')
            var test_id = $(this).data('testid')

            $('#ref_id_tbx').val(reference_id)
            $('#test_id_tbx').val(test_id)

            // Clear the content of the result_div
            $('#result_div').empty()

            // On success, fetch lab test result sheet data based on reference ID
            $.ajax({
               url: `${API_URL}lab-test/view-all-lab-test-result-sheet-data-to-make-update-result-form-by-ref-id/${reference_id}`,
               method: 'GET',
               success: function (data) {
                  // Iterate over fetched data and create input labels and fields
                  data.forEach(function (item, index) {
                     // Create a div for form row
                     var formRow = $('<div class="form-row"></div>')

                     // Create label element with appropriate column classes
                     var label = $('<label class="col-sm-4 col-form-label font-weight-bold"></label>') // Adjust col-sm-4 based on your preference
                        .attr('for', 'input_' + index)
                        .text(item.wk_test_sub_result_name + ' (' + item.wk_test_units + ')')

                     // Create div for input with appropriate column classes
                     var inputWrapper = $('<div class="col-sm-8"></div>') // Adjust col-sm-8 based on your preference

                     // Create input element
                     var input = $('<input type="text" class="form-control form-control-sm" readonly></input>')
                        .attr('id', '' + item.res_id)
                        .attr('name', '' + item.res_id)
                        .val(item.res_result_value)

                     // Append input to inputWrapper
                     inputWrapper.append(input)

                     // Append label and inputWrapper to form row
                     formRow.append(label)
                     formRow.append(inputWrapper)

                     // Append form row to result_div
                     $('#result_div').append(formRow.clone()) // Cloning the formRow to avoid moving it from its original location
                  })

                  $('#approve_btn').hide()
                  $('#result_auto_cal_dev').hide()
                  $('#retest_btn').show()
                  $('#submit_btn').hide()

                  // Show the modal after updating result_div
                  const modalElement = document.getElementById('resultUpdateModal')
                  const modal = new bootstrap.Modal(modalElement)
                  modal.show()
               },
               error: function (xhr, status, error) {
                  console.error('Error fetching data:', error)
               },
            })

            //? load all lab test result related comments
            reload_comments(reference_id)
         })

         // Click event handler for the "Approve Result" button
         $('#approve_btn').on('click', () => {
            var user_id = window.globalData.id
            var ref_id = $('#ref_id_tbx').val()
            var status = 'Y'
            var patientID = $('#patient_id_cmb').val()

            $.ajax({
               url: `${API_URL}lab-test/lab-test-result-sheet-result-complete-status-update/` + user_id + '/' + ref_id + '/' + status,
               type: 'PUT',
               contentType: 'application/json',
               success: response => {
                  Swal.fire({
                     icon: 'success',
                     title: 'Medical test status updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? view all to approve and to be retest result approve table data
                  to_be_approve_lab_test_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'R'])

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {},
            })
         })

         // Click event handler for the "Need To Retest Result" button
         $('#retest_btn').on('click', () => {
            var user_id = window.globalData.id
            var ref_id = $('#ref_id_tbx').val()
            var status = 'R'
            var patientID = $('#patient_id_cmb').val()

            $.ajax({
               url: `${API_URL}lab-test/lab-test-result-sheet-result-complete-status-update/` + user_id + '/' + ref_id + '/' + status,
               type: 'PUT',
               contentType: 'application/json',
               success: response => {
                  Swal.fire({
                     icon: 'success',
                     title: 'Medical test status updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? view all to approve and to be retest result approve table data
                  to_be_approve_lab_test_load_data_table(patientID, ['A', 'N', 'R'], ['A', 'R'])

                  // ? view all to complete lab test result test table data
                  completed_lab_test_load_data_table(patientID, ['Y'], ['Y'])

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {},
            })
         })

         // Function to display comments with delete buttons
         function displayComments(comments) {
            const commentsContainer = $('#comments-container')
            commentsContainer.empty() // Clear any existing comments

            comments.forEach(comment => {
               const commentHtml = `
               <div class="comment d-flex justify-content-between align-items-center mb-1">
               <span class="comment-text">${comment.res_com_comment}</span>
               <input class="btn btn-sm btn-danger comment-del-btn" type="button" data-id="${comment.at_id}" value="Delete" />
               </div>`
               commentsContainer.append(commentHtml)
            })
         }

         // Attach click event listener to comments container
         function setupDeleteCommentListener() {
            const commentsContainer = $('#comments-container')
            commentsContainer.on('click', '.comment-del-btn', function () {
               const commentId = $(this).data('id')
               const ref_id = $('#ref_id_tbx').val()

               // Perform delete action or any other operation based on commentId
               $.ajax({
                  url: `${API_URL}lab-test/delete-lab-test-result-comment-by-comment-id/` + commentId,
                  type: 'DELETE',
                  contentType: 'application/json',
                  success: response => {
                     Swal.fire({
                        icon: 'success',
                        title: 'Comment Deleted successfully!',
                        text: 'Well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     }).then(() => {
                        // Load all lab test result related comments after the alert is closed
                        reload_comments(ref_id)
                     })
                  },
                  error: error => {
                     console.error('Error deleting comment:', error)
                     Swal.fire({
                        icon: 'error',
                        title: 'Failed to delete comment',
                        text: error.responseJSON?.message || 'An error occurred while deleting the comment.',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })
                  },
               })
            })
         }

         // ? re add new comment
         $('#comment_submit_btn').click(function () {
            // Get the HTML content of the text editor
            var editorContent = $('#editor').html()
            var ref_id = $('#ref_id_tbx').val()

            // Check if labTest is empty
            if (!ref_id) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Reference no is required!',
                  text: 'Please select a Reference No.',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else if (editorContent.trim() === '') {
               // Show SweetAlert with auto-close on 2 seconds
               Swal.fire({
                  title: 'Empty Content',
                  text: 'The text editor content is empty.',
                  icon: 'warning',
                  timer: 2000,
                  showConfirmButton: true,
                  allowOutsideClick: true,
               })
            } else {
               const testData = {
                  res_com_refference_id: ref_id,
                  res_com_comment: editorContent,
               }
               $('#comment_submit_btn').prop('disabled', true).val('wait...')
               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test/re-add-new-comment-lab-test-result-sheet`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(testData),
                  success: response => {
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'Comment added successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     //? load all lab test result related comments
                     reload_comments(ref_id)

                     // ? Enable the button
                     $('#comment_submit_btn').prop('disabled', false).val('Submit')
                     $('#editor').html('')
                  },
                  error: error => {
                     // ? Handle error
                     $('#comment_submit_btn').prop('disabled', false).val('Submit')
                  },
               })
            }
         })

         $('#comment_new_btn').click(function () {
            $('#editor').html('')
         })
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>

<style lang=""></style>
