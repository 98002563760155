export const API_URL =
   'https://health-care-lab-pannala.hashloopit.com:26418/api/v1/'

export const BILL_PRINT_URL =
   'https://health-care-lab-pannala.hashloopit.com/bill-print/cash-posbill.php?bill_no='

export const FILE_UPLOAD_URL =
   'https://health-care-lab-pannala.hashloopit.com/Report-Print/'

// export const PATIENT_REPORT_LINK =
//    'https://health-care-lab-pannala.hashloopit.com/bill-print/customer.php?no='

export const ENCRYPT_SCRT_KEY = 'HashloopITSCRT';

export const SMS_LINK = 'https://health-care-lab-pannala.hashloopit.com/front-end/#/patient-report-download/';


// export const API_URL = 'http://localhost:3003/api/v1/'

// export const BILL_PRINT_URL =
//    'http://localhost/Hashloop%20MLMSO%20Project/Bill-Print/cash-posbill.php?bill_no='

// export const FILE_UPLOAD_URL =
//    'http://localhost:80/Hashloop MLMSO Project/Report-Print/'

export const PATIENT_REPORT_LINK =
   'https://health-care-lab-pannala.hashloopit.com/bill-print/customer.php?no='


