<template lang="">
   <div class="wrapper">
      <!-- Sidebar  -->
      <LayoutManu></LayoutManu>

      <!-- Page Content  -->
      <div id="content-page" class="content-page">
         <!-- Page Content  -->
         <LayoutNavbar></LayoutNavbar>

         <div class="container-fluid">
            <div class="row">
               <!-- Left Column -->
               <div class="col-sm-12">
                  <div class="iq-card">
                     <div class="iq-card border border-primary">
                        <div class="iq-card-body">
                           <form>
                              <form>
                                 <div class="row">
                                    <div class="col-md-1">
                                       <label for="branch_cmb" class="">Branch</label>
                                    </div>
                                    <div class="col-md-4">
                                       <Select2 class="js-example-basic-single font-weight-bold" id="branch_cmb" name="branch_cmb"></Select2>
                                    </div>
                                    <div class="col-md-1">
                                       <label for="start_date_dtmp" class="">From Date</label>
                                    </div>
                                    <div class="col-md-2">
                                       <input
                                          type="date"
                                          class="form-control form-control-sm font-weight-bold"
                                          id="start_date_dtmp"
                                          :value="getDateWithOffset(-30)"
                                       />
                                    </div>
                                    <div class="col-md-1">
                                       <label for="end_date_dtmp" class="">To Date</label>
                                    </div>
                                    <div class="col-md-2">
                                       <input
                                          type="date"
                                          class="form-control form-control-sm font-weight-bold"
                                          id="end_date_dtmp"
                                          :value="getCurrentDate()"
                                       />
                                    </div>
                                    <div class="col-md-1">
                                       <input class="btn btn-danger btn-sm btn-block" type="button" id="refresh_btn" value="Refresh" />
                                    </div>
                                 </div>
                              </form>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="container-fluid">
            <div class="row">
               <div class="col-lg-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-sharp fa-solid fa-inbox-in text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Total Income</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="total_income_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-sharp fa-solid fa-inbox-out text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Total Expenses</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="total_expense_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                           <div class="iq-card-body iq-bg-grd-warning rounded">
                              <div class="d-flex align-items-center justify-content-between">
                                 <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                    ><i class="fa-solid fa-money-check-dollar text-dark"></i
                                 ></div>
                                 <div class="text-right">
                                    <h5 class="text-dark">Gross profit</h5>
                                    <div>
                                       <h2 class="text-dark mb-0"><span class="" id="gross_profit_lbl">0.00</span></h2></div
                                    >
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Income vs Expenses</h4>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <div><canvas id="income-vs-expenses-ratio-chart"></canvas></div>
                     </div>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Income - Cash vs Bank</h4>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <div><canvas id="income-cash-bank-ratio-chart"></canvas></div>
                     </div>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Expenses - Cash vs Bank</h4>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <div><canvas id="expenses-cash-bank-ratio-chart"></canvas></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-6">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Income Ratio</h4>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <div><canvas id="income-ratio-chart"></canvas></div>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h4 class="card-title">Expenses Ratio</h4>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <div><canvas id="expenses-ratio-chart"></canvas></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <LayoutFooterVue></LayoutFooterVue>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS
import Chart from 'chart.js/auto'

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'acc-dash', ['active', 'main-active'])
      this.updateElementClasses('sub', 'account-dashboard-monthly', ['active'])

      $(document).ready(function () {
         // ? clear values
         clearValues()

         // ? get all branch names
         get_branch_names_id()

         // ? clear values
         function clearValues() {
            $('#total_income_lbl').text('Rs. 0.00')
            $('#total_expense_lbl').text('Rs. 0.00')
            $('#gross_profit_lbl').text('Rs. 0.00')
         }

         function income_cash_bank_ratio_chart(chartElement, start_date, end_date, branch_id) {
            // Make the AJAX request
            $.ajax({
               url:
                  `${API_URL}account-dashboard/get-total-income-expenses-cash-bank-ratio-monthly-dashboard/` +
                  start_date +
                  '/' +
                  end_date +
                  '/' +
                  branch_id,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Check if the Chart already exists
                  const existingChart = Chart.getChart(chartElement)

                  // If it exists, destroy it
                  if (existingChart) {
                     existingChart.destroy()
                  }

                  // Create a new Chart object
                  const chart = new Chart(document.getElementById(chartElement), {
                     type: 'pie',
                     data: {
                        labels: [
                           'Cash Income - ' + formatNumberWithCommas(data.balance_cash_income),
                           'Bank Income - ' + formatNumberWithCommas(data.balance_bank_income),
                        ],
                        datasets: [
                           {
                              data: [data.balance_cash_income, data.balance_bank_income],
                              backgroundColor: ['#21618C', '#28B463'],
                           },
                        ],
                     },
                     options: {
                        maintainAspectRatio: false, // Set to false to allow custom size
                        responsive: true, // Set to true to make the chart responsive
                        width: 200, // Set the width of the chart
                        height: 200, // Set the height of the chart
                     },
                  })
               },
               error: function (error) {
                  // Handle errors here
                  console.error(error)
               },
            })
         }

         function expenses_cash_bank_ratio_chart(chartElement, start_date, end_date, branch_id) {
            // Make the AJAX request
            $.ajax({
               url:
                  `${API_URL}account-dashboard/get-total-income-expenses-cash-bank-ratio-monthly-dashboard/` +
                  start_date +
                  '/' +
                  end_date +
                  '/' +
                  branch_id,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Check if the Chart already exists
                  const existingChart = Chart.getChart(chartElement)

                  // If it exists, destroy it
                  if (existingChart) {
                     existingChart.destroy()
                  }

                  // Create a new Chart object
                  const chart = new Chart(document.getElementById(chartElement), {
                     type: 'pie',
                     data: {
                        labels: [
                           'Cash Expenses - ' + formatNumberWithCommas(data.balance_cash_expenses),
                           'Bank Expenses - ' + formatNumberWithCommas(data.balance_bank_expenses),
                        ],
                        datasets: [
                           {
                              data: [data.balance_cash_expenses, data.balance_bank_expenses],
                              backgroundColor: ['#5b305a', '#9a4c68'],
                           },
                        ],
                     },
                     options: {
                        maintainAspectRatio: false, // Set to false to allow custom size
                        responsive: true, // Set to true to make the chart responsive
                        width: 200, // Set the width of the chart
                        height: 200, // Set the height of the chart
                     },
                  })
               },
               error: function (error) {
                  // Handle errors here
                  console.error(error)
               },
            })
         }

         function income_expenses_ratio_chart(chartElement, start_date, end_date, branch_id) {
            // Make the AJAX request
            $.ajax({
               url:
                  `${API_URL}account-dashboard/get-total-income-expenses-cash-bank-ratio-monthly-dashboard/` +
                  start_date +
                  '/' +
                  end_date +
                  '/' +
                  branch_id,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Check if the Chart already exists
                  const existingChart = Chart.getChart(chartElement)

                  // If it exists, destroy it
                  if (existingChart) {
                     existingChart.destroy()
                  }

                  // Create a new Chart object
                  const chart = new Chart(document.getElementById(chartElement), {
                     type: 'pie',
                     data: {
                        labels: [
                           'Total Expenses - ' + formatNumberWithCommas(data.total_expense),
                           'Total Income - ' + formatNumberWithCommas(data.total_income),
                        ],
                        datasets: [
                           {
                              data: [data.total_expense, data.total_income],
                              backgroundColor: ['#f35c6e', '#6c5fa7'],
                           },
                        ],
                     },
                     options: {
                        maintainAspectRatio: false, // Set to false to allow custom size
                        responsive: true, // Set to true to make the chart responsive
                        width: 200, // Set the width of the chart
                        height: 200, // Set the height of the chart
                     },
                  })
               },
               error: function (error) {
                  // Handle errors here
                  console.error(error)
               },
            })
         }

         function income_by_account_ratio_chart(chartElement, start_date, end_date, branch_id) {
            // Make the AJAX request
            $.ajax({
               url: `${API_URL}account-dashboard/get-total-income-account-ratio-monthly-dashboard/` + start_date + '/' + end_date + '/' + branch_id,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Check if the Chart already exists
                  const existingChart = Chart.getChart(chartElement)

                  // If it exists, destroy it
                  if (existingChart) {
                     existingChart.destroy()
                  }
                  // Check if data is not empty
                  if (data.length > 0) {
                     // Extract labels and values from the data
                     const labels = data.map(item => `${item.account_name}: ${formatNumberWithCommas(item.total_income)}`)
                     const values = data.map(item => item.total_income)

                     // Create a new Chart object
                     const chart = new Chart(document.getElementById(chartElement), {
                        type: 'polarArea',
                        data: {
                           labels: labels,
                           datasets: [
                              {
                                 data: values,
                                 backgroundColor: getRandomColors(data.length),
                              },
                           ],
                        },
                        options: {
                           maintainAspectRatio: true, // Set to false to allow custom size
                           responsive: true, // Set to true to make the chart responsive
                           width: 400, // Set the width of the chart
                           height: 400, // Set the height of the chart
                        },
                     })
                  } else {
                     console.error('No data available for the pie chart.')
                  }
               },
               error: function (error) {
                  // Handle errors here
                  console.error(error)
               },
            })
         }

         function expenses_by_account_ratio_chart(chartElement, start_date, end_date, branch_id) {
            // Make the AJAX request
            $.ajax({
               url: `${API_URL}account-dashboard/get-total-expenses-account-ratio-monthly-dashboard/` + start_date + '/' + end_date + '/' + branch_id,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  // Check if the Chart already exists
                  const existingChart = Chart.getChart(chartElement)

                  // If it exists, destroy it
                  if (existingChart) {
                     existingChart.destroy()
                  }
                  // Check if data is not empty
                  if (data.length > 0) {
                     // Extract labels and values from the data
                     const labels = data.map(item => `${item.account_name}: ${formatNumberWithCommas(item.total_income)}`)
                     const values = data.map(item => item.total_income)

                     // Create a new Chart object
                     const chart = new Chart(document.getElementById(chartElement), {
                        type: 'polarArea',
                        data: {
                           labels: labels,
                           datasets: [
                              {
                                 data: values,
                                 backgroundColor: getRandomColors(data.length),
                              },
                           ],
                        },
                        options: {
                           maintainAspectRatio: true, // Set to false to allow custom size
                           responsive: true, // Set to true to make the chart responsive
                           width: 400, // Set the width of the chart
                           height: 400, // Set the height of the chart
                        },
                     })
                  } else {
                     console.error('No data available for the pie chart.')
                  }
               },
               error: function (error) {
                  // Handle errors here
                  console.error(error)
               },
            })
         }

         // ? get all branch names
         function get_branch_names_id() {
            $.ajax({
               url: `${API_URL}branch/branch-name/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#branch_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // Add the option for "All Branches"
                     const allBranchesOption = new Option('All Branches', 'B')
                     selectDropdown.append(allBranchesOption)

                     // ? Append new options
                     data.forEach(branch => {
                        const option = new Option(branch.brach_name, branch.brach_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()
                  }
               },
               error: function (error) {
                  console.error('Error fetching branch name:', error)
               },
            })
         }

         // ? brach select change event
         $('#branch_cmb').on('change', function () {
            // ? clear data
            clearValues()
         })

         // ? get total income expenses gross profit data
         function get_total_income_expenses_profit_data(start_date, end_date, branch_id) {
            $.ajax({
               url:
                  `${API_URL}` +
                  'account-dashboard/get-total-income-expenses-gross-profit-monthly-dashboard/' +
                  start_date +
                  '/' +
                  end_date +
                  '/' +
                  branch_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#total_income_lbl').text(formatNumberWithCommas(data['total_income']))
                  $('#total_expense_lbl').text(formatNumberWithCommas(data['total_expense']))
                  $('#gross_profit_lbl').text(formatNumberWithCommas(data['gross_profit']))
               },
            })
         }

         // ? refresh button click event
         $('#refresh_btn').on('click', () => {
            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#end_date_dtmp').val()
            // ? get total income expenses gross profit data
            get_total_income_expenses_profit_data(start_date, end_date, branch_id)

            // ? get cash bank income ratio to chart
            income_cash_bank_ratio_chart('income-cash-bank-ratio-chart', start_date, end_date, branch_id)

            // ? get cash bank expenses ratio to chart
            expenses_cash_bank_ratio_chart('expenses-cash-bank-ratio-chart', start_date, end_date, branch_id)

            // ? get income expenses ratio to chart
            income_expenses_ratio_chart('income-vs-expenses-ratio-chart', start_date, end_date, branch_id)

            // ? income by account ratio to chart
            income_by_account_ratio_chart('income-ratio-chart', start_date, end_date, branch_id)

            // ? income by account ratio to chart
            expenses_by_account_ratio_chart('expenses-ratio-chart', start_date, end_date, branch_id)
         })

         // ? auto load the data
         setInterval(function () {
            var branch_id = $('#branch_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#end_date_dtmp').val()
            // ? get total income expenses gross profit data
            get_total_income_expenses_profit_data(start_date, end_date, branch_id)

            // ? get cash bank income ratio to chart
            income_cash_bank_ratio_chart('income-cash-bank-ratio-chart', start_date, end_date, branch_id)

            // ? get cash bank expenses ratio to chart
            expenses_cash_bank_ratio_chart('expenses-cash-bank-ratio-chart', start_date, end_date, branch_id)

            // ? get income expenses ratio to chart
            income_expenses_ratio_chart('income-vs-expenses-ratio-chart', start_date, end_date, branch_id)

            // ? income by account ratio to chart
            income_by_account_ratio_chart('income-ratio-chart', start_date, end_date, branch_id)

            // ? income by account ratio to chart
            expenses_by_account_ratio_chart('expenses-ratio-chart', start_date, end_date, branch_id)
         }, 60000)

         // Function to generate random colors (optional)
         function getRandomColors(count) {
            const flatColors = [
               '#3498db',
               '#2ecc71',
               '#f1c40f',
               '#e67e22',
               '#e74c3c',
               '#9b59b6',
               '#1abc9c',
               '#95a5a6',
               '#34495e',
               '#27ae60',
               '#f39c12',
               '#d35400',
               '#c0392b',
               '#8e44ad',
               '#16a085',
               '#7f8c8d',
               '#2980b9',
               '#2c3e50',
               '#e74c3c',
               '#d35400',
            ]

            const colors = []

            for (let i = 0; i < count; i++) {
               colors.push(flatColors[i % flatColors.length])
            }

            return colors
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
