<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Hospital's Lab Test Transfer</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-4 mb-1">
                                 <label class="">Select Hospital <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="hospital_id_cmb" name="hospital_id_cmb"> </Select2>
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">Total Payable</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="total_to_be_paid_amount_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">All Test Count</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="row_count_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">Test Count (Now Selected)</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="selected_row_count_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label class="">To Be Paid (Now Selected)</label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="to_be_paid_amount_tbx"
                                    placeholder="0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="hospital_id_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-3 mb-1">
                                 <label class="">Cash Payment <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="cash_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-3 mb-1">
                                 <label class="">Bank Payment <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="bank_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-3 mb-1">
                                 <label class="">Cheque No </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="cheque_no_tbx" />
                              </div>
                              <div class="col-md-3 mb-1">
                                 <label class="">Cheque Post-date </label>
                                 <input
                                    type="date"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="cheque_post_date_pkr"
                                    :value="getCurrentDate()"
                                 />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-5">
                                 <label class="">Description <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="description_tbx" />
                              </div>
                              <div class="col-md-auto mt-4">
                                 <input class="btn btn-success btn-block" type="submit" id="submit_btn" value="Submit" />
                              </div>
                              <div class="col-md-auto mt-4">
                                 <input class="btn btn-success btn-block" type="submit" id="check_all_tra_btn" value="Select All" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12 col-lg-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                              <li class="nav-item">
                                 <a
                                    class="nav-link active"
                                    id="pills-home-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-home-fill"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                    >To Be Sent Lab Test</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="pills-profile-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-profile-fill"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                    >To Be Receive Lab Test</a
                                 >
                              </li>
                              <li class="nav-item">
                                 <a
                                    class="nav-link"
                                    id="pills-contact-tab-fill"
                                    data-toggle="pill"
                                    href="#pills-contact-fill"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected="false"
                                    >Lab Test Transfer History</a
                                 >
                              </li>
                           </ul>
                           <div class="tab-content" id="pills-tabContent-1">
                              <div class="tab-pane fade show active" id="pills-home-fill" role="tabpanel" aria-labelledby="pills-home-tab-fill">
                                 <div class="table-responsive">
                                    <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Select</th>
                                             <th>Reference ID</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Hospital Fee</th>
                                             <th>Patient Name</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Select</th>
                                             <th>Reference ID</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Hospital Fee</th>
                                             <th>Patient Name</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="pills-profile-fill" role="tabpanel" aria-labelledby="pills-profile-tab-fill">
                                 <div class="table-responsive">
                                    <table id="receive_lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Test</th>
                                             <th>Send Date</th>
                                             <th>Upload Report</th>
                                             <th>Patient Name</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Reference ID</th>
                                             <th>Test</th>
                                             <th>Send Date</th>
                                             <th>Upload Report</th>
                                             <th>Patient Name</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                              <div class="tab-pane fade" id="pills-contact-fill" role="tabpanel" aria-labelledby="pills-contact-tab-fill">
                                 <div class="table-responsive">
                                    <table id="history_lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                       <thead>
                                          <tr>
                                             <th>Ref. ID</th>
                                             <th>Print</th>
                                             <th>Patient Name</th>
                                             <th>Pay Status</th>
                                             <th>Pay Date</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Hospital Fee</th>
                                             <th>Send Status</th>
                                             <th>Send Date</th>
                                             <th>Receive Status</th>
                                             <th>Receive Date</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </thead>
                                       <tbody> </tbody>
                                       <tfoot>
                                          <tr>
                                             <th>Ref. ID</th>
                                             <th>Print</th>
                                             <th>Patient Name</th>
                                             <th>Pay Status</th>
                                             <th>Pay Date</th>
                                             <th>Test</th>
                                             <th>Price</th>
                                             <th>Hospital Fee</th>
                                             <th>Send Status</th>
                                             <th>Send Date</th>
                                             <th>Receive Status</th>
                                             <th>Receive Date</th>
                                             <th>Date</th>
                                             <th>Time</th>
                                             <th>Branch</th>
                                          </tr>
                                       </tfoot>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade bd-example-modal-lg" id="fileUploadModal" tabindex="-1" role="dialog" aria-hidden="true">
               <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="fileUploadModalLabel">File Upload</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div class="modal-body">
                        <form id="uploadForm" enctype="multipart/form-data">
                           <div class="form-row">
                              <div class="col">
                                 <label for="patient_name_tbx" class="font-weight-bold"> Patient Name <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm" id="patient_name_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col mt-1">
                                 <label for="patient_age_tbx" class="font-weight-bold"> Patient Age <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm" id="patient_age_tbx" readonly />
                              </div>
                              <div class="col mt-1">
                                 <label for="patient_gender_tbx" class="font-weight-bold"> Patient Gender <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm" id="patient_gender_tbx" readonly />
                              </div>
                              <div class="col mt-1">
                                 <label for="collected_date_tbx" class="font-weight-bold"> Collected Date <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm" id="collected_date_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col mt-1">
                                 <label for="test_name_tbx" class="font-weight-bold"> Test Name <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm" id="test_name_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col mt-1">
                                 <label for="patient_name_tbx" class="font-weight-bold">
                                    Select Report To Upload <span class="text-danger">*</span>
                                 </label>
                                 <input type="file" class="form-control-file" id="file" name="file" accept=".pdf" />
                                 <input type="hidden" class="form-control" id="folder_name_tbx" />
                                 <input type="hidden" class="form-control" id="file_name_tbx" />
                              </div>
                           </div>
                        </form>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                        <input class="btn btn-success" type="submit" id="upload_btn" value="Upload And Complete Test Transfer" />
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, FILE_UPLOAD_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'hospital', ['active', 'main-active'])
      this.updateElementClasses('sub', 'transfer-test-hospital', ['active'])

      $(document).ready(function () {
         let selectedIds = []

         // ? get all hospital names and ids into select hospital dropdown
         get_hospital_names_id()

         clearValues()

         // ? clear values in change select doctor
         function clearValues() {
            // ? Clear text inputs by ID
            $('#total_to_be_paid_amount_tbx').val(0.0)
            $('#row_count_tbx').val(0)
            $('#to_be_paid_amount_tbx').val(0.0)
            $('#selected_row_count_tbx').val(0)
            $('#to_be_paid_amount_tbx').val(0)
            $('#doctor_account_tbx').val('')
            $('#doctor_id_tbx').val('')

            // Set number inputs to 0 by ID
            $('#cash_tbx').val('0.00')
            $('#bank_tbx').val('0.00')

            $('#cheque_no_tbx').val('')
            $('#description_tbx').val('')
         }

         // ? get all hospital names and ids into select hospital dropdown
         function get_hospital_names_id() {
            $.ajax({
               url: `${API_URL}hospital/hospital-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#hospital_id_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(hospital => {
                        const option = new Option(hospital.hos_name, hospital.hos_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#hospital_id_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? hospital select box enter button click event selected change event
         $('#hospital_id_cmb').on('change', function () {
            clearValues()
            // ? Your code for Select doctor select2 change event
            var hospital_id = $(this).val()
            $('#hospital_id_tbx').val(hospital_id)

            // ? load data to table
            lab_test_transaction_load_data_table(hospital_id, ['N'], ['N'])
            receive_lab_test_transaction_load_data_table(hospital_id, ['Y'], ['N'])
            get_total_payable_fee_by_hospital_id(hospital_id)
            history_lab_test_transaction_load_data_table(hospital_id, ['N', 'Y', 'Z'], ['N', 'Y', 'Z'])

            // ? Focus and select the content of input
            setTimeout(function () {
               $('#cash_tbx').focus().select()
            }, 0)
         })

         // ? Handle checkbox selection
         $('#lab_test_data_table').on('click', '.select-checkbox', function () {
            const selectedId = $(this).data('id')

            const isSelected = $(this).prop('checked')

            if (isSelected) {
               // Add to selection
               selectedIds.push(selectedId)
            } else {
               // Remove from selection
               selectedIds = selectedIds.filter(id => id !== selectedId)
            }

            // Display selected data-ids in an alert
            if (selectedIds.length > 0) {
               $('#selected_row_count_tbx').val(selectedIds.length)
               get_total_payable_fee_by_selected_id(selectedIds.join(', '))
            } else {
               $('#to_be_paid_amount_tbx').val(0)

               Swal.fire({
                  icon: 'info',
                  title: 'Alert',
                  text: 'No Data Selected.',
                  timer: 2000, // Set the timer for 2 seconds
                  showConfirmButton: false,
                  timerProgressBar: true,
               })
            }

            // ? Focus and select the content of input
            setTimeout(function () {
               $('#cash_tbx').focus().select()
            }, 0)
         })

         $('#check_all_tra_btn').on('click', function () {
            // Check all checkboxes
            $('input[type="checkbox"]').prop('checked', true)

            // Update the selectedIds and selectedTraId arrays with all data-ids and lab_tra_ids
            selectedIds = []
            $('.select-checkbox').each(function () {
               const id = $(this).data('id') // assuming 'data-traid' attribute is present
               selectedIds.push(id)
            })

            // Display selected data-ids in an alert
            if (selectedIds.length > 0) {
               $('#selected_row_count_tbx').val(selectedIds.length)
               get_total_payable_fee_by_selected_id(selectedIds.join(', '))
            } else {
               Swal.fire({
                  icon: 'info',
                  title: 'Alert',
                  text: 'No Data Selected.',
                  timer: 2000, // Set the timer for 2 seconds
                  showConfirmButton: false,
                  timerProgressBar: true,
               })
            }
         })

         // ? get all payable hospital fee total by hospital id array
         function get_total_payable_fee_by_hospital_id(hospital_id) {
            $.ajax({
               url: `${API_URL}lab-test-transaction/sum-of-payable-hospital-fee/` + hospital_id,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#total_to_be_paid_amount_tbx').val(data['total_payable_fee'])
               },
               error: function (error) {
                  console.error('Error fetching hospital names:', error)
               },
            })
         }

         // ? get all payable hospital fee total by selected ids array
         function get_total_payable_fee_by_selected_id(selected_ids) {
            $.ajax({
               url: `${API_URL}lab-test-transaction/sum-of-payable-hospital-fee-selected-ids?ids=` + selected_ids,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#to_be_paid_amount_tbx').val(data['total_payable_fee'])
               },
               error: function (error) {
                  console.error('Error fetching hospital names:', error)
               },
            })
         }

         // ? view lab test transaction to table model view
         function lab_test_transaction_load_data_table(hospitalID, send_type, receive_type) {
            // Update the selectedIds array with all data-ids
            selectedIds = []

            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}lab-test-transaction/hospital-fee-transfer-test-transaction-view-data-table/` +
                     hospitalID +
                     `?send_status=` +
                     send_type +
                     `&receive_status=` +
                     receive_type,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                     render: function (data, type, row) {
                        return (
                           '<input type="checkbox" class="select-checkbox" id="select_tra_chb" data-id="' +
                           data +
                           '" data-traid="' +
                           row.lab_tra_id +
                           '">'
                        )
                     },
                  },
                  {
                     data: 'id',
                  },
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_hospital_fee',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'pat_name',
                  },
                  { data: 'lab_tra_date' },
                  { data: 'lab_tra_time' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [[10000000], ['Show all']],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal

                  $('#row_count_tbx').val(totalRecords)
               }
            }

            // ? Initialize DataTable
            $('#lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? view lab test transaction to table model view
         function receive_lab_test_transaction_load_data_table(hospitalID, send_type, receive_type) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}lab-test-transaction/hospital-fee-transfer-test-transaction-view-data-table/` +
                     hospitalID +
                     `?send_status=` +
                     send_type +
                     `&receive_status=` +
                     receive_type,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                  },
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_lab_test_send_time',
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                           row.id +
                           '" data-cusid="' +
                           row.lab_tra_cus_id +
                           '"><i class="fa-solid fa-folder-arrow-up"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'pat_name',
                  },
                  { data: 'lab_tra_date' },
                  { data: 'lab_tra_time' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#receive_lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? re print after click print button
         $('#history_lab_test_data_table').on('click', '#view_btn', function () {
            var row_id = $(this).data('id')
            var url = FILE_UPLOAD_URL + 'view-pdf.php?file_name=' + row_id + '.pdf'
            window.open(url, '_blank')
         })

         // ? view lab test transaction to table model view
         function history_lab_test_transaction_load_data_table(hospitalID, send_type, receive_type) {
            // Update the selectedIds array with all data-ids
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}lab-test-transaction/hospital-fee-transfer-test-transaction-view-data-table/` +
                     hospitalID +
                     `?send_status=` +
                     send_type +
                     `&receive_status=` +
                     receive_type,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                  },
                  {
                     data: 'lab_tra_lab_test_receive',
                     render: function (data, type, row) {
                        if (data === 'N') {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-id="' +
                              row.lab_tra_cus_id +
                              '/' +
                              row.id +
                              '" disabled><i class="fa-sharp fa-solid fa-eye-slash"></i></button>'
                           )
                        } else if (data === 'Y') {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-id="' +
                              row.lab_tra_cus_id +
                              '/' +
                              row.id +
                              '" ><i class="fa-solid fa-eye"></i></button>'
                           )
                        }
                     },
                  },
                  {
                     data: 'pat_name',
                  },
                  {
                     data: 'lab_tra_hospital_fee_update',
                     render: function (data, type, row) {
                        if (data === 'P') {
                           return '<span class="badge badge-danger">Not Paid Yet</span>'
                        } else if (data === 'Y') {
                           return '<span class="badge badge-success">Paid</span>'
                        } else if (data === 'Z') {
                           return '<span class="badge badge-warning">No Payment To Be Made</span>'
                        }
                     },
                  },
                  { data: 'lab_tra_hospital_fee_pay_date' },
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_hospital_fee',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_lab_test_send',
                     render: function (data, type, row) {
                        if (data === 'N') {
                           return '<span class="badge badge-danger">Not Send Yet</span>'
                        } else if (data === 'Y') {
                           return '<span class="badge badge-success">Sent</span>'
                        } else {
                           return '<span class="badge badge-warning">Nothing to Send</span>'
                        }
                     },
                  },
                  { data: 'lab_tra_lab_test_send_time' },
                  {
                     data: 'lab_tra_lab_test_receive',
                     render: function (data, type, row) {
                        if (data === 'N') {
                           return '<span class="badge badge-danger">Not Receive Yet</span>'
                        } else if (data === 'Y') {
                           return '<span class="badge badge-success">Received</span>'
                        } else {
                           return '<span class="badge badge-warning">Nothing to Receive</span>'
                        }
                     },
                  },
                  { data: 'lab_tra_lab_test_receive_time' },
                  { data: 'lab_tra_date' },
                  { data: 'lab_tra_time' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [
                  [5, 10, 25, 50, 10000000],
                  ['5 rows', '10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#history_lab_test_data_table').DataTable(dataTableOptions)
         }

         // ? Trigger action on "Enter" key press in cash_tbx
         $('#cash_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#bank_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in bank_tbx
         $('#bank_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#cheque_no_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_no_tbx
         $('#cheque_no_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#cheque_post_date_pkr').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_post_date_pkr
         $('#cheque_post_date_pkr').on('keyup', function (event) {
            if (event.which === 13) {
               $('#description_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_post_date_pkr
         $('#description_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               document.getElementById('submit_btn').click()
            }
         })

         // ? data table upload report and receive test click event handler
         $('#receive_lab_test_data_table').on('click', '#edit_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var reference_id = $(this).data('id')
            var patient_id = $(this).data('cusid')

            // Create folder name based on patient_id and reference_id
            var folderName = patient_id

            var formData = new FormData()
            formData.append('fun_folder_name', folderName)

            $.ajax({
               // Change this to 'GET' or 'POST' based on your server's requirements
               url: FILE_UPLOAD_URL + 'create-folder.php',
               type: 'POST',
               data: formData,
               contentType: false,
               processData: false,
               success: function (response) {
                  console.log(response)
                  // Handle the success response here
               },
               error: function (xhr, status, error) {
                  console.error(xhr.responseText)
                  // Handle the error response here
               },
            })

            $.ajax({
               url: `${API_URL}` + 'lab-test-transaction/lab-test-transaction-details-by-id/' + reference_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#folder_name_tbx').val(patient_id)
                  $('#file_name_tbx').val(reference_id)
                  $('#patient_name_tbx').val(data['pat_name'])
                  $('#patient_age_tbx').val(data['pat_age'])
                  if (data['pat_gender'] == 'M') {
                     $('#patient_gender_tbx').val('Male')
                  } else {
                     $('#patient_gender_tbx').val('Female')
                  }
                  $('#collected_date_tbx').val(data['lab_tra_collect_datetime'])
                  $('#test_name_tbx').val(data['lab_test_name'])
               },
            })

            const modalElement = document.getElementById('fileUploadModal')
            const modal = new bootstrap.Modal(modalElement)
            modal.show()
         })

         // ? submit button click event
         $('#submit_btn').on('click', () => {
            var cashValue = parseFloat($('#cash_tbx').val()) || 0
            var bankValue = parseFloat($('#bank_tbx').val()) || 0
            var to_be_paid_amount = parseFloat($('#to_be_paid_amount_tbx').val()) || 0
            var cheque_no = $('#cheque_no_tbx').val()
            var cheque_post_date = $('#cheque_post_date_pkr').val()
            var description = $('#description_tbx').val()

            var hospital_id = $('#hospital_id_tbx').val()
            var tra_count = $('#row_count_tbx').val()

            // Calculate total and assign it to the readonly input
            var totalPayment = cashValue + bankValue

            var requestData = {
               ids: selectedIds.join(', '),
               gl_tra_branch: window.globalData.branchId,
               amount: totalPayment,
               cash_bal: cashValue,
               bank_bal: bankValue,
               post_date: cheque_post_date,
               cheque_no: cheque_no,
               description: description,
               user_id: window.globalData.id,
            }

            if (!hospital_id) {
               // Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select a hospital!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (tra_count == 0) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'No lab test for transfer or paid!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (selectedIds.length === 0) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select a transaction for transfer or payment!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (totalPayment === 0) {
               // Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Both cash and bank payments cannot be zero!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (totalPayment !== to_be_paid_amount) {
               // Display SweetAlert for entering a description
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Total payment should be equal to payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (!description) {
               // Display SweetAlert for entering a description
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please enter a description!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else {
               if (bankValue > 0) {
                  if (cheque_no === '') {
                     Swal.fire({
                        icon: 'warning',
                        title: 'Oops!',
                        text: 'Cheque No can not be null!',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                     })
                  } else if (!cheque_post_date) {
                     Swal.fire({
                        icon: 'warning',
                        title: 'Oops!',
                        text: 'Cheque post-date can not be null!',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                     })
                  } else {
                     $('#submit_btn').prop('disabled', true).val('wait...')

                     // ? Make the API call using jQuery AJAX
                     $.ajax({
                        type: 'POST',
                        url: `${API_URL}account-transaction/hospital-fee-expense-transaction-insert/`, // Replace with your actual API endpoint
                        contentType: 'application/json',
                        data: JSON.stringify(requestData),
                        success: function (response) {
                           $('#submit_btn').prop('disabled', false).val('Submit')
                           // Display SweetAlert for success
                           Swal.fire({
                              icon: 'success',
                              title: 'Success',
                              text: 'Hospital fee pay transaction successfully added.',
                              timer: 2000,
                              showConfirmButton: false,
                              timerProgressBar: true,
                           })

                           // ? load data to table
                           clearValues()

                           // ? load data to table
                           lab_test_transaction_load_data_table($('#hospital_id_tbx').val(), ['N'], ['N'])
                           get_total_payable_fee_by_hospital_id($('#hospital_id_tbx').val())
                           history_lab_test_transaction_load_data_table($('#hospital_id_tbx').val(), ['N', 'Y', 'Z'], ['N', 'Y', 'Z'])

                           // You can perform additional actions after a successful API call if needed
                        },
                        error: function (error) {
                           $('#submit_btn').prop('disabled', false).val('Submit')
                           // Display SweetAlert for error
                           Swal.fire({
                              icon: 'error',
                              title: 'Error',
                              text: error.responseText,
                           })
                        },
                     })
                  }
               } else {
                  $('#submit_btn').prop('disabled', true).val('wait...')

                  // ? Make the API call using jQuery AJAX
                  // ? Make the API call using jQuery AJAX
                  $.ajax({
                     type: 'POST',
                     url: `${API_URL}account-transaction/hospital-fee-expense-transaction-insert/`, // Replace with your actual API endpoint
                     contentType: 'application/json',
                     data: JSON.stringify(requestData),
                     success: function (response) {
                        $('#submit_btn').prop('disabled', false).val('Submit')
                        // Display SweetAlert for success
                        Swal.fire({
                           icon: 'success',
                           title: 'Success',
                           text: 'Hospital fee pay transaction successfully added.',
                           timer: 2000,
                           showConfirmButton: false,
                           timerProgressBar: true,
                        })

                        // ? load data to table
                        clearValues()

                        // ? load data to table
                        lab_test_transaction_load_data_table(hospital_id, ['N'], ['N'])
                        get_total_payable_fee_by_hospital_id(hospital_id)
                        history_lab_test_transaction_load_data_table(hospital_id, ['N', 'Y', 'Z'], ['N', 'Y', 'Z'])

                        // You can perform additional actions after a successful API call if needed
                     },
                     error: function (error) {
                        $('#submit_btn').prop('disabled', false).val('Submit')
                        // Display SweetAlert for error
                        Swal.fire({
                           icon: 'error',
                           title: 'Error',
                           text: error.responseText,
                        })
                     },
                  })
               }
            }
         })

         $('#upload_btn').on('click', function () {
            var file_name = $('#file_name_tbx').val()
            var folder_name = $('#folder_name_tbx').val()
            var fileInput = $('#file')[0]

            // Check if a file is selected
            if (fileInput.files.length === 0) {
               Swal.fire({
                  icon: 'error',
                  title: 'No File Selected',
                  text: 'Please choose a PDF file to upload.',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
               return
            }

            var formData = new FormData($('#uploadForm')[0])
            formData.append('file_name', file_name)
            formData.append('folder_name', folder_name)

            // Display a progress bar
            var progressBar = Swal.fire({
               title: 'Uploading...',
               html: 'Please wait...',
               allowOutsideClick: false,
            })

            $.ajax({
               url: FILE_UPLOAD_URL + 'upload.php',
               type: 'POST',
               data: formData,
               contentType: false,
               processData: false,
               success: function (response) {
                  $.ajax({
                     url: `${API_URL}lab-test-transaction/update-test-transfer-received-status/` + file_name,
                     type: 'PUT',
                     success: function (response) {
                        // Close the progress bar
                        progressBar.close()

                        // Display success message
                        Swal.fire({
                           icon: 'success',
                           title: 'Completed',
                           text: 'Report uploaded successfully, and transfer received updated.',
                           timer: 2000,
                           showConfirmButton: false,
                        })

                        // Clear the file input
                        $('#file').val('')

                        // ? load data to table
                        lab_test_transaction_load_data_table($('#hospital_id_tbx').val(), ['N'], ['N'])
                        get_total_payable_fee_by_hospital_id($('#hospital_id_tbx').val())
                        history_lab_test_transaction_load_data_table($('#hospital_id_tbx').val(), ['N', 'Y', 'Z'], ['N', 'Y', 'Z'])
                     },
                     error: function (error) {
                        console.error('API call failed:', error.responseText)
                     },
                  })
               },
               error: function () {
                  // Close the progress bar
                  progressBar.close()

                  // Display error message
                  Swal.fire({
                     icon: 'error',
                     title: 'Error',
                     text: 'There was an error uploading the file.',
                     timer: 2000,
                     showConfirmButton: false,
                  })
               },
            })
         })
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>

<style lang=""></style>
