import LabTestManagement from '../pages/labTests/LabTestManagement.vue'
import LabTestAllView from '../pages/labTests/ViewAllLabTest.vue'
import LabTestPriceChange from '../pages/labTests/LabTestPriceChange.vue'
import LabTestWorkSheetCreate from '../pages/labTests/LabTestWorkSheetCreate.vue'

const LabTestRoutes = [
   // ? Lab Test Management Page view rout
   {
      path: '/lab-management',
      name: '/lab-management',
      component: LabTestManagement,
      meta: { requiresAuth: true },
   },

   // ? All Lab test price change Page view rout
   {
      path: '/lab-tests-price-change',
      name: '/lab-tests-price-change',
      component: LabTestPriceChange,
      meta: { requiresAuth: true },
   },

   // ?create lab test worksheet Page view rout
   {
      path: '/lab-tests-worksheet-create',
      name: '/lab-tests-worksheet-create',
      component: LabTestWorkSheetCreate,
      meta: { requiresAuth: true },
   },

   // ? All Lab test view Page view rout
   {
      path: '/view-all-lab-tests',
      name: '/view-all-lab-tests',
      component: LabTestAllView,
      meta: { requiresAuth: true },
   },
]

export default LabTestRoutes
