import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../config/store'
import Login from '../pages/LoginPage.vue'
import HomePage from '../pages/HomePage.vue'
import DoctorRoutes from './doctor-routes'
import HospitalRoutes from './hospital-routes'
import LabTestRoutes from './lab-test-routes'
import PatientRoutes from './patient-routes'
import UserRoutes from './user-routes'
import BranchRoutes from './branch-routes'
import DayOpenPage from '../pages/day-open/DayOpenPage.vue'
import LabTestTransactionRoutes from './lab-test-transaction-routes'
import PaymentsRoutes from './payment-routes'
import AccountRoutes from './account-routes'
import DashboardRoutes from './dashboard-routes'
import TestResultRoutes from './lab-test-results-routes'
import SmsRoutes from './sms-routes'
import CustomerReportDownloadRoutes from './customer-report-download-routes'
import CorrectionRoutes from './correction-routes'

const routes = [
   // ? login root page show
   { path: '/', name: '', component: Login },

   // ? login root page show
   { path: '/login', name: 'login', component: Login },

   // ? day open root page show
   {
      path: '/day-open',
      name: 'day-open',
      component: DayOpenPage,
      meta: { requiresAuth: true },
   },

   // ? home root page show
   {
      path: '/home',
      name: 'home',
      component: HomePage,
      meta: { requiresAuth: true },
   },

   // ? Doctors management routers package
   ...DoctorRoutes,

   // ? Hospital management routers package
   ...HospitalRoutes,

   // ? Lab Test management routers package
   ...LabTestRoutes,

   // ? Patient Management routers package
   ...PatientRoutes,

   // ? User Management routers package
   ...UserRoutes,

   // ? Branch Management routers package
   ...BranchRoutes,

   // ? lab test transaction routers package
   ...LabTestTransactionRoutes,

   // ? payment view router package
   ...PaymentsRoutes,

   // ? account router package
   ...AccountRoutes,

   // ? dashboard router package
   ...DashboardRoutes,

   // ? lab test results router package
   ...TestResultRoutes,

   // ? Sms send results router package
   ...SmsRoutes,

   // ? download lab test result for customer router package
   ...CustomerReportDownloadRoutes,

   // ? correction entry router package
   ...CorrectionRoutes

]

const router = createRouter({
   history: createWebHashHistory(),
   routes,
})

router.beforeEach((to, from, next) => {
   if (to.meta.requiresAuth && !store.state.isAuthenticated) {
      // Redirect to login if not authenticated
      next('/login')
   } else {
      next()
   }
})

export default router
