<template lang="">
   <div class="vh-100 d-flex justify-content-center align-items-center">
      <div class="container-fluid">
         <div class="row justify-content-center">
            <div class="col-sm-6">
               <div class="iq-card bg-light border border-primary">
                  <div class="iq-card-body">
                     <div class="text-center mb-4">
                        <img src="Assets/images/logo.png" alt="Logo" style="max-width: 100px" />
                        <h1>Hashloop Medical Lab Management System</h1>
                        <h3>LAB +</h3>
                        <h3>Day Open</h3>
                     </div>

                     <!-- Branch Selection -->
                     <div class="mb-3">
                        <label for="branch_cmb" class="form-label"><b>Select Your Branch</b></label>
                        <select class="form-select" id="branch_cmb"> </select>
                      </div>

                     <!-- User Name -->
                     <div class="mb-3">
                        <label for="userName" class="form-label"><b>User</b></label>
                        <input type="text" class="form-control" id="userName" :value="userName + ' - ' + userID" readonly />
                     </div>

                     <!-- Current Date & Time -->
                     <div class="mb-3">
                        <label for="currentTime" class="form-label"><b>Current Date & Time</b></label>
                        <input type="text" class="form-control" id="currentTime" :value="getCurrentDate()" readonly />
                     </div>

                     <!-- Last Working Date -->
                     <div class="mb-3">
                        <label for="lastWorkingDate" class="form-label"><b>Last Working Date</b></label>
                        <input type="text" class="form-control" id="lastWorkingDate" value="" readonly />
                     </div>

                     <div class="row mt-4">
                        <div class="col-6">
                           <button class="btn btn-primary btn-block" id="get_start_btn"><b>Get Start</b></button>
                        </div>
                        <div class="col-6">
                           <button class="btn btn-danger btn-block" @click="logout"><b>Log Out</b></button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapState } from 'vuex'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

export default {
   mixins: [dateTimeMixin],

   mounted() {
      const vm = this

      // ? Load data table when the page loads
      this.$nextTick(() => {
         // ? get all branch names and ids into select branch dropdown
         get_branch_names_id()
      })

      // ? get all branch names and ids into select branch dropdown
      function get_branch_names_id() {
         $.ajax({
            url: `${API_URL}branch/branch-name/`, // corrected the URL concatenation
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               const selectElement = $('#branch_cmb') // Get the select element by its ID

               // Clear any existing options
               selectElement.empty()

               // Append each branch as an option to the select element
               data.forEach(branch => {
                  const option = $('<option></option>')
                     .attr('value', branch.brach_id) // Set the value attribute of the option
                     .text(branch.brach_name) // Set the text content of the option

                  selectElement.append(option)
               })
            },
            error: function (error) {
               console.error('Error fetching branch names:', error)
            },
         })
      }

      // ? get day open data
      function get_day_open_data(branch_id) {
         $.ajax({
            url: `${API_URL}` + 'day-open-end/last-date/' + branch_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#lastWorkingDate').val(data['sys_date'])

               const branch_id = $('#branch_cmb').val()
               get_day_open_data(branch_id)
            },
         })
      }

      // ? branch select change event
      $('#branch_cmb').change(function () {
         const branch_id = $('#branch_cmb').val()
         get_day_open_data(branch_id)
      })

      // ? Trigger on click event
      $('#branch_cmb').click(function () {
         const branch_id = $(this).val()
         get_day_open_data(branch_id)
      })

      $('#get_start_btn').on('click', () => {
         const branch_id = $('#branch_cmb').val()

         if (!branch_id) {
            // If branch_id is null or empty, show a SweetAlert
            Swal.fire({
               icon: 'warning',
               title: 'Branch Not Selected',
               text: 'Please select a branch from the dropdown.',
               confirmButtonText: 'OK',
            })
            return // Exit the function early if branch_id is null or empty
         } else {
            get_day_open_data(branch_id)

            const openDate = this.getCurrentDate() // Example date
            const branchID = branch_id // Example branch ID
            const currentDateTime = this.getCurrentDateTime() // Current date-time in ISO format

            $.ajax({
               url: `${API_URL}` + 'day-open-end/day-open/', // Replace with the actual endpoint URL
               type: 'POST',
               contentType: 'application/json',
               data: JSON.stringify({
                  openDate: openDate,
                  branchID: branchID,
                  currentDateTime: currentDateTime,
               }),
               success: function (response) {
                  const newBranchId = branch_id // Replace with your new value or fetch it from the response
                  vm.$store.commit('setBranchId', newBranchId)

                  // Navigate to the route and then reload it
                     vm.$router.replace('/home').then(() => {
                        window.location.reload()
                     })
               },
               error: function (error) {
                  // Display a SweetAlert2 error alert
                  Swal.fire({
                     icon: 'error',
                     title: 'Oops...',
                     text: error.responseJSON.message,
                  })
               },
            })
         }
      })
   },
   computed: {
      ...mapState({
         isAuthenticated: 'isAuthenticated',
         user: 'user',
      }),
      // Separate computed properties for individual state values
      userName() {
         return this.user.username
      },
      gender() {
         return this.user.gender
      },
      userID() {
         return this.user.id
      },
      branchID() {
         return this.user.branchId
      },
   },

   methods: {
      logout() {
         // Perform logout logic
         this.$store.dispatch('logout')
         this.$router.push('/login')
      },
   },
}
</script>
<style lang=""></style>
