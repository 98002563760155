import AccountDashboardMonthly from '../pages/account-dashboard/accountDashboardMonthly.vue'
import AccountReportMonthly from '../pages/account-dashboard/accountReportMonthly.vue'
import AccountReportDaily from '../pages/account-dashboard/accountReportDaily.vue'

import AllLabTestReportDashboard from '../pages//lab-test-dashboard/AllLabTestReportDashboard.vue'

const DashboardRoutes = [
   // ? monthly account dashboard page view rout
   {
      path: '/account-dashboard-monthly',
      name: '/account-dashboard-monthly',
      component: AccountDashboardMonthly,
      meta: { requiresAuth: true },
   },

   // ? monthly account report page view rout
   {
      path: '/account-report-monthly',
      name: '/account-report-monthly',
      component: AccountReportMonthly,
      meta: { requiresAuth: true },
   },

   // ? daily account report page view rout
   {
      path: '/account-report-daily',
      name: '/account-report-daily',
      component: AccountReportDaily,
      meta: { requiresAuth: true },
   },

   // ? all lab test report dashboard page view rout
   {
      path: '/all-lab-test-report-dashboard',
      name: '/all-lab-test-report-dashboard',
      component: AllLabTestReportDashboard,
      meta: { requiresAuth: true },
   },
]

export default DashboardRoutes
