import UpdateTestResults from '../pages/lab-test-result/UpdateLabTestResults.vue'

const TestResultRoutes = [
    // ? hospital management page show rout
    {
        path: '/update-test-results',
        name: '/update-test-results',
        component: UpdateTestResults,
        meta: { requiresAuth: true },
    },

]

export default TestResultRoutes