import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Create the Vuex store
const store = createStore({
   state: {
      isAuthenticated: false,
      user: {
         id: '',
         username: '',
         gender: '',
         branchId: '',
         userType: '',
      },
   },
   mutations: {
      setAuthenticated(state, value) {
         state.isAuthenticated = value
      },
      setUser(state, userData) {
         state.user = userData
      },
      setBranchId(state, newBranchId) {
         state.user.branchId = newBranchId
      },
   },
   actions: {
      login({ commit }) {
         // Simulating a successful login for demonstration
         const userData = {
            id: '',
            username: '',
            gender: '',
            branchId: '',
            userType: '',
         }

         commit('setAuthenticated', true)
         commit('setUser', userData)
      },
      logout({ commit }) {
         commit('setAuthenticated', false)
         commit('setUser', {
            id: '',
            username: '',
            gender: '',
            branchId: '',
            userType: '',
         })
      },
   },
   plugins: [createPersistedState()],
   modules: {},
})

// Assign values to window.globalData after Vuex store creation
const { user } = store.state
window.globalData = {
   id: user.id,
   username: user.username,
   gender: user.gender,
   branchId: user.branchId,
   userType: user.userType,
}

// Export the store
export default store
