import CustomerReportView from '../pages/customer-report-view/customer-report-download'

const CustomerReportDownloadRoutes = [
    {
        path: '/patient-report-download/:id', // Define ':id' as a route parameter
        name: 'patient-report-download',
        component: CustomerReportView,
        props: true // This allows route params to be passed as props to the component
    }
]

export default CustomerReportDownloadRoutes