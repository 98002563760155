<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Lab Test Price Change</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-2 mb-1">
                                 <label class="font-weight-bold">Select Hospital</label>
                              </div>
                              <div class="col-md-10 mb-1">
                                 <Select2 class="js-example-basic-single font-weight-bold" id="hospital_cmb" name="hospital_cmb"></Select2>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <div class="table-responsive mt-3 mb-2">
                              <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Test</th>
                                       <th>Price</th>
                                       <th>New Price</th>
                                       <th>Update</th>
                                       <th>Lab</th>
                                       <th>Hospital</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Test</th>
                                       <th>Price</th>
                                       <th>New Price</th>
                                       <th>Update</th>
                                       <th>Lab</th>
                                       <th>Hospital</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'test', ['active', 'main-active'])
      this.updateElementClasses('sub', 'change-price-test', ['active'])

      $(document).ready(function () {
         // ? load hospital names
         getHospitalNamesAndID()

         // ? Add a change event listener
         $('#hospital_cmb').on('change', function () {
            // Get the selected value
            var hospital_id = $(this).val()

            // ? view table data
            load_data_table(hospital_id)
         })

         // ? data table update button click event handler
         $('#lab_test_data_table').on('click', '#edit_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var lab_testId = $(this).data('id')
            var price = $('#' + lab_testId + '_tbx').val()

            if (parseFloat(price) <= 0) {
               // Display SweetAlert with auto-close for price <= 0
               Swal.fire({
                  icon: 'warning',
                  title: 'Invalid Price',
                  text: 'Price must be greater than 0',
                  timer: 3000, // Auto close after 3 seconds
                  showConfirmButton: false,
               })
            } else {
               const testData = {
                  lab_test_price: price,
               }

               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test/lab-test-price-update/` + lab_testId,
                  type: 'PUT',
                  contentType: 'application/json',
                  data: JSON.stringify(testData),
                  success: response => {
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'Lab test price updated successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     // Get the selected value
                     var hospital_id = $('#hospital_cmb').val()

                     // ? view table data
                     load_data_table(hospital_id)
                  },
                  error: error => {
                     // ? Handle error
                     $('#lab_test_update_btn').prop('disabled', false).val('Update')
                  },
               })
            }
         })

         // ? load hospital names
         function getHospitalNamesAndID() {
            $.ajax({
               url: `${API_URL}hospital/hospital-names/`, // Update with the actual path
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#hospital_cmb')

                     // Clear existing options
                     selectDropdown.empty()

                     // Append new options
                     data.forEach(hospital => {
                        const option = new Option(hospital.hos_name, hospital.hos_id)
                        selectDropdown.append(option)
                     })

                     // Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#hospital_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching hospital names and IDs:', error)
               },
            })
         }

         function load_data_table(hospital_id) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test/view-all-lab-test-by-hospital-id/` + hospital_id,
                  type: 'GET',
               },
               columns: [
                  { data: 'lab_id' },
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_test_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_id',
                     render: function (data, type, row) {
                        if (type === 'display' || type === 'filter') {
                           return (
                              '<input type="number" id="' +
                              row.lab_id +
                              '_tbx" value="0" class="form-control form-control-sm text-right" style="width: 130px;">'
                           )
                        }
                        return data
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                           row.lab_id +
                           '" ><i class="fa-solid fa-square-pen"></i></button>'
                        )
                     },
                  },
                  {
                     data: 'lab_test_type',
                     render: function (data, type, row) {
                        if (data === 'O') {
                           return '<span class="badge badge-warning">Other Lab</span>'
                        } else if (data === 'A') {
                           return '<span class="badge badge-primary">Own Lab</span>'
                        } else {
                           // Handle other cases if needed
                           return data
                        }
                     },
                  },
                  { data: 'hos_name' },
               ],
               lengthMenu: [
                  [25, 50, 10000000],
                  ['25 rows', '50 rows', 'Show all'],
               ],
               columnDefs: [
                  { width: '7%', targets: 0 },
                  { width: '35%', targets: 1 },
                  { width: '9%', targets: 2 },
                  { width: '6%', targets: 3 },
                  { width: '9%', targets: 4 },
               ],
            }

            // ? Initialize DataTable
            $('#lab_test_data_table').DataTable(dataTableOptions)
         }
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })

         // Function to call the getHospitalNamesAndID API
      },
   },
}
</script>
<style lang=""></style>
