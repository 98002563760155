<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="row">
                        <div class="col-md-6 col-lg-4">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-sharp fa-solid fa-inbox-in text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Balance</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="balance_lbl">0.00</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-sharp fa-solid fa-inbox-out text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Charge Per SMS</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="charge_lbl">0.00</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                           <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                              <div class="iq-card-body iq-bg-grd-warning rounded">
                                 <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon iq-bg-round-warning"
                                       ><i class="fa-solid fa-money-check-dollar text-dark"></i
                                    ></div>
                                    <div class="text-right">
                                       <h5 class="text-dark">Available SMS</h5>
                                       <div>
                                          <h2 class="text-dark mb-0"><span class="" id="avb_sms_lbl">0</span></h2></div
                                       >
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Patients</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="table-responsive mt-3 mb-2">
                              <table id="patient_data_table" class="table table-hover table-bordered display">
                                 <thead>
                                    <tr>
                                       <th>Contact</th>
                                       <th>SMS</th>
                                       <th>Date</th>
                                       <th>Balance</th>
                                       <th>Type</th>
                                       <th>Description</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>Contact</th>
                                       <th>SMS</th>
                                       <th>Date</th>
                                       <th>Balance</th>
                                       <th>Type</th>
                                       <th>Description</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5'

export default {
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'sms', ['active', 'main-active'])
      this.updateElementClasses('sub', 'sms-balance-history', ['active'])

      $(document).ready(function () {
         // ? get balance and available sms count data
         get_balance_sms_charge()

         // ? load sms transaction data to table
         load_data_table()

         // ? get balance and available sms count data
         function get_balance_sms_charge() {
            $.ajax({
               url: `${API_URL}sms-api/get-sms-account-balance-and-charge`,
               type: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (Array.isArray(data) && data.length > 0) {
                     const firstItem = data[0] // Access the balance property of the first item
                     $('#balance_lbl').text('Rs. ' + firstItem.balance)
                     $('#charge_lbl').text('Rs. ' + firstItem.sms_chargers)
                     $('#avb_sms_lbl').text(firstItem.avb_sms)
                  } else {
                     console.error('No data found or data is not in the expected format')
                  }
               },
               error: function (xhr, status, error) {
                  console.error('An error occurred:', error)
               },
            })
         }

         // ? load sms transaction data to table
         function load_data_table() {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}sms-api/view-all-sms-transaction-data-table`,
                  type: 'GET',
               },
               columns: [
                  { data: 'tr_contact' },
                  { data: 'tr_sms_sms' },
                  { data: 'tr_sms_date' },
                  { data: 'sms_charge' },
                  {
                     data: 'tr_sms_type',
                     render: function (data, type, row) {
                        if (data === 'S') {
                           return '<span class="badge badge-success">Send</span>'
                        } else {
                           return '<span class="badge badge-danger">Reload</span>'
                        }
                     },
                  },
                  { data: 'tr_sms_description' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
               order: [[2, 'desc']],
            }

            // ? Initialize DataTable
            $('#patient_data_table').DataTable(dataTableOptions)
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>

<style></style>
