import BranchManagement from '../pages/branch/BranchManagement.vue'

const BranchRoutes = [
   // ? Branch management page view rout
   {
      path: '/branch-management',
      name: '/branch-management',
      component: BranchManagement,
      meta: { requiresAuth: true },
   },
]

export default BranchRoutes
