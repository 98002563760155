<template lang="">
   <div id="content-page" style="padding: 80px 0 0">
      <div class="top-navbar fixed-top header-top-sticky" style="background-color: #099dad; height: 70px">
         <div class="iq-navbar-custom">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
               <button type="button" id="new_btn" class="btn btn-secondary btn-sm mb-3 ml-2 mr-1 mt-3">New - F2</button>
               <button type="button" id="lab_test_add_btn" class="btn btn-sm mb-3 mr-1 mt-3" style="background-color: #5cdb5c">Save - F4</button>
               <button type="button" id="pay_bill_btn" class="btn btn-warning btn-sm mb-3 mr-1 mt-3">Pay Bill - F12</button>
               <button type="button" id="clear_all_btn" class="btn btn-light btn-sm mb-3 mr-1 mt-3">Clear All - F8</button>
               <button type="button" id="refresh_btn" class="btn btn-danger btn-sm mb-3 mr-1 mt-3">Refresh - F9</button>
               <button
                  type="button"
                  id="add_patient_model_btn"
                  class="btn btn-success btn-sm mb-3 mr-1 mt-3"
                  data-toggle="modal"
                  data-target=".bd-example-modal-lg"
                  >Add New Patient - F10</button
               >

               <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav ml-auto navbar-list">
                     <li class="nav-item iq-full-screen mr-2">
                        <div id="btnFullscreen">
                           <i class="ri-fullscreen-line text-light"></i>
                        </div>
                     </li>
                  </ul>
               </div> -->

               <div class="form-group mr-2">
                  <input type="text" class="form-control form-control-sm mt-3" id="transaction_id_tbx" style="width: 200px" readonly />
               </div>

               <div class="form-group">
                  <select class="form-control form-control-sm mt-3" id="not_update_transaction_cmb" style="width: 200px"> </select>
               </div>
               <ul class="navbar-list ml-auto d-flex align-items-center">
                  <li>
                     <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                        <!-- Conditionally render the user image based on gender -->
                        <img v-if="gender === 'M'" src="Assets/images/user/12.jpg" class="rounded-circle mr-3" width="100" height="100" alt="user" />
                        <!-- class="img-fluid rounded mr-3" -->
                        <img v-else-if="gender === 'F'" src="Assets/images/user/1.jpg" class="rounded-circle mr-3" alt="user" />
                        <div class="caption">
                           <h6 class="mb-0 font-size-12 line-height text-light">{{ userName }}</h6>
                        </div>
                     </a>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="iq-card border border-primary">
                  <div class="iq-card-body">
                     <form>
                        <form>
                           <div class="form-row">
                              <div class="col-md-4 mb-1">
                                 <label class="">Select Patient <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="patient_id_cmb" name="patient_id_cmb"> </Select2>
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_gender_tbx" class="">Patient Gender </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_gender_tbx" readonly />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="patient_account_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_contact_tbx" class="">Patient Contact</label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_contact_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Patient Age </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_age_tbx" readonly />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="patient_age_tbx" class="">Patient Email </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="patient_email_tbx" readonly />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-4 mb-1">
                                 <label class="">Lab Test Name <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="lab_test_id_cmb" name="lab_test_id_cmb"> </Select2>
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="test_price_tbx" class="">Price </label>
                                 <input
                                    type="text"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="test_price_tbx"
                                    value="Rs. 0.00"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-1 mb-1">
                                 <label for="dis_rate_tbx" class="">Dis. Rate <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.01"
                                    value="0"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="dis_rate_tbx"
                                    required
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="after_dis_price_tbx" class="">Price After Discount </label>
                                 <input
                                    type="text"
                                    value="Rs. 0.00"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="after_dis_price_tbx"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-2 mb-1">
                                 <label for="sample_collect_time_dtmp" class="">Colle. Date <span class="text-danger">*</span></label>
                                 <input
                                    type="date"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="sample_collect_date_dtmp"
                                    :value="getCurrentDate()"
                                 />
                              </div>
                              <div class="col-md-1 mb-1">
                                 <label for="sample_collect_time_dtmp" class="">Colle. Time <span class="text-danger">*</span></label>
                                 <input
                                    type="time"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="sample_collect_time_dtmp"
                                    :value="getCurrentTime()"
                                 />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-4 mb-1">
                                 <label class="">Reference <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="doctor_id_cmb" name="doctor_id_cmb"> </Select2>
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="doctor_account_tbx" readonly />
                                 <input
                                    type="hidden"
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    value="0"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="test_price_r_tbx"
                                    readonly
                                 />
                                 <input
                                    type="hidden"
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    value="0"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="test_actual_price_tbx"
                                    readonly
                                 />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="test_type_tbx" readonly />
                                 <input type="hidden" class="form-control form-control-sm font-weight-bold" id="hospital_id_tbx" readonly />
                                 <input
                                    type="hidden"
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    value="0"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="after_dis_price_r_tbx"
                                    readonly
                                 />
                                 <input
                                    type="hidden"
                                    min="0"
                                    max="100000000"
                                    step="0.01"
                                    value="0"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="doctor_rate_tbx"
                                    readonly
                                 />
                              </div>
                              <div class="col-md-8 mb-1">
                                 <div class="border border-primary">
                                    <div class="container mt-2">
                                       <div class="row">
                                          <!-- First Row -->
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-success">Total Amount</label>
                                          </div>
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-success">Total Discount</label>
                                          </div>
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-success">Payable Amount</label>
                                          </div>
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-success">Total Tests</label>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-danger" id="total_amount_lbl">Rs. 0.00</label>
                                          </div>
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-danger" id="total_discount_lbl">Rs. 0.00</label>
                                          </div>
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-danger" id="total_payable_lbl">Rs. 0.00</label>
                                          </div>
                                          <div class="col-md-3 text-center">
                                             <label class="font-weight-bold text-danger" id="row_count_lbl">0</label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="iq-card border border-primary">
                  <div class="iq-card-body">
                     <div class="table-responsive">
                        <table id="transaction_data_table" class="table table-hover table-bordered display nowrap">
                           <thead>
                              <tr>
                                 <th>Test Name</th>
                                 <th>Price</th>
                                 <th>Dis. Rt</th>
                                 <th>Dis</th>
                                 <th>Amount</th>
                                 <th>Doctor</th>
                                 <th>Hospital</th>
                                 <th>Action</th>
                              </tr>
                           </thead>
                           <tbody> </tbody>
                           <tfoot>
                              <tr>
                                 <th>Test Name</th>
                                 <th>Price</th>
                                 <th>Dis. Rt</th>
                                 <th>Dis</th>
                                 <th>Amount</th>
                                 <th>Doctor</th>
                                 <th>Hospital</th>
                                 <th>Action</th>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade bd-example-modal-lg" id="dt" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title font-weight-bold">Add New Patient</h4>
                  <button type="button" id="dc" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <form class="needs-validation" id="add_patient_data_form" onsubmit="return false" novalidate>
                     <div class="form-row">
                        <div class="col-md-12 mb-3">
                           <label for="name_tbx" class="font-weight-bold"> Name <span class="text-danger">*</span> </label>
                           <input type="text" class="form-control form-control-sm" placeholder="Name" id="name_tbx" required />
                        </div>
                        <div class="col-md-12 mb-3">
                           <label for="location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                           <input type="text" class="form-control form-control-sm" placeholder="Location" id="location_tbx" required />
                        </div>
                     </div>
                     <div class="form-row">
                        <div class="col-md-6 mb-3">
                           <label for="contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                           <input type="text" class="form-control form-control-sm" placeholder="Contact" id="contact_tbx" required />
                        </div>
                        <div class="col-md-6 mb-3">
                           <label for="email_tbx" class="font-weight-bold"> Email </label>
                           <input type="email" class="form-control form-control-sm" placeholder="Email" id="email_tbx" />
                        </div>
                     </div>
                     <div class="form-row">
                        <div class="col-md-6 mb-3">
                           <label for="birthday_tbx" class="font-weight-bold"> Birthday <span class="text-danger">*</span> </label>
                           <input
                              type="date"
                              class="form-control form-control-sm"
                              placeholder="Contact"
                              id="birthday_tbx"
                              :value="getCurrentDate()"
                              required
                           />
                        </div>
                        <div class="col-md-6 mb-3">
                           <label for="gender_cmb" class="font-weight-bold">Gender <span class="text-danger">*</span></label>
                           <select class="form-control form-control-sm" id="gender_cmb" required>
                              <option value="M">Male</option>
                              <option value="F">Female</option>
                           </select>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" id="dcd" data-dismiss="modal"> Close </button>
                        <input class="btn btn-sm btn-primary" type="submit" id="patient_add_btn" value="Submit" />
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade bd-example-modal-lg" id="payment_select_model" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title font-weight-bold text-center">Select Payment Type</h4>
                  <button type="button" id="dc" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <button type="button" id="select_advance_btn" class="btn btn-secondary btn-block focus-visible">Advance Payment</button>
                  <button type="button" id="select_full_pay_btn" class="btn btn-success btn-block focus-visible">Full Payment</button>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade bd-example-modal-lg" id="advance_payment_model" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title font-weight-bold">Advance Payment</h4>
                  <button type="button" id="dc" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="col-lg-12">
                     <div class="row">
                        <div class="col-lg-12 mb-3">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <h5 class="card-title text-center" id="pa_customer_lbl">Customer</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <!-- Total Amount Card -->
                        <div class="col-lg-4 mb-2">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <p class="card-title text-center">Total Amount</p>
                                 <h5 class="card-text text-center" id="pa_total_amount_lbl">Rs. 0.00</h5>
                              </div>
                           </div>
                        </div>
                        <!-- Total Discount Card -->
                        <div class="col-lg-4 mb-2">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <p class="card-title text-center">Total Discount</p>
                                 <h5 class="card-text text-center" id="pa_total_discount_lbl">Rs. 0.00</h5>
                              </div>
                           </div>
                        </div>
                        <!-- Total Payable Card -->
                        <div class="col-lg-4 mb-2">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <p class="card-title text-center">Total Payable</p>
                                 <h5 class="card-text text-center" id="pa_payable_lbl">Rs. 0.00</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <hr />
                     <!-- Payment Input Fields -->
                     <!-- Payment Input Fields -->
                     <div class="row">
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Discount Rate</span>
                              </div>
                              <input type="number" min="0" max="100" class="form-control text-right" id="pa_discount_rate_tbx" placeholder="0.00" />
                           </div>
                        </div>
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Fixed Discount</span>
                              </div>
                              <input type="number" class="form-control text-right" id="pa_discount_tbx" placeholder="0.00" />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Bank </span>
                              </div>
                              <input type="number" class="form-control text-right" id="pa_bank_tbx" placeholder="0.00" />
                           </div>
                        </div>
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Cash</span>
                              </div>
                              <input type="number" class="form-control text-right" id="pa_cash_tbx" placeholder="0.00" />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-12">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Balance</span>
                              </div>
                              <input type="number" class="form-control text-right" id="pa_balance_tbx" placeholder="0.00" readonly />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="pa_printOption" id="pa_do_not_print_rbtn" value="D" />
                     <label class="form-check-label" for="pa_do_not_print_rbtn"> Don't Print </label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="pa_printOption" id="pa_print_rbtn" value="P" />
                     <label class="form-check-label" for="pa_print_rbtn"> To Print </label>
                  </div>
                  <input class="btn btn-primary" type="submit" id="pay_advance_btn" value="Submit" />
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade bd-example-modal-lg" id="full_payment_model" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title font-weight-bold">Full Payment</h4>
                  <button type="button" id="dc" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="col-lg-12">
                     <div class="row">
                        <div class="col-lg-12 mb-3">
                           <div class="card bg-warning">
                              <h5 class="card-body">
                                 <p class="card-title text-center" id="pf_customer_lbl">Customer</p>
                              </h5>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <!-- Total Amount Card -->
                        <div class="col-lg-4 mb-2">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <p class="card-title text-center">Total Amount</p>
                                 <h5 class="card-text text-center" id="pf_total_amount_lbl">Rs. 0.00</h5>
                              </div>
                           </div>
                        </div>
                        <!-- Total Discount Card -->
                        <div class="col-lg-4 mb-2">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <p class="card-title text-center">Total Discount</p>
                                 <h5 class="card-text text-center" id="pf_total_discount_lbl">Rs. 0.00</h5>
                              </div>
                           </div>
                        </div>
                        <!-- Total Payable Card -->
                        <div class="col-lg-4 mb-2">
                           <div class="card bg-warning">
                              <div class="card-body">
                                 <p class="card-title text-center">Total Payable</p>
                                 <h5 class="card-text text-center" id="pf_payable_lbl">Rs. 0.00</h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <hr />
                     <!-- Payment Input Fields -->
                     <div class="row">
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Discount Rate</span>
                              </div>
                              <input type="number" min="0" max="100" class="form-control text-right" id="pf_discount_rate_tbx" placeholder="0.00" />
                           </div>
                        </div>
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Fixed Discount</span>
                              </div>
                              <input type="number" class="form-control text-right" id="pf_discount_tbx" placeholder="0.00" />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Bank </span>
                              </div>
                              <input type="number" class="form-control text-right" id="pf_bank_tbx" placeholder="0.00" />
                           </div>
                        </div>
                        <div class="col-lg-6">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Cash</span>
                              </div>
                              <input type="number" class="form-control text-right" id="pf_cash_tbx" placeholder="0.00" />
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-12">
                           <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                 <span class="input-group-text">Balance</span>
                              </div>
                              <input type="number" class="form-control text-right" id="pf_balance_tbx" placeholder="0.00" readonly />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="pf_printOption" id="pf_do_not_print_rbtn" value="D" />
                     <label class="form-check-label" for="pf_do_not_print_rbtn"> Don't Print </label>
                  </div>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="pf_printOption" id="pf_print_rbtn" value="P" />
                     <label class="form-check-label" for="pf_print_rbtn"> To Print </label>
                  </div>
                  <input class="btn btn-primary" type="submit" id="pay_full_btn" value="Submit" />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import { mapState } from 'vuex'
import Select2 from 'vue3-select2-component'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, BILL_PRINT_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   name: 'LayoutNavbar',
   components: { Select2 },
   emits: ['select2'],

   computed: {
      ...mapState({
         isAuthenticated: 'isAuthenticated',
         user: 'user',
      }),
      // Separate computed properties for individual state values
      userName() {
         return this.user.username
      },
      gender() {
         return this.user.gender
      },
      userID() {
         return this.user.id
      },
      branchID() {
         return this.user.branchId
      },
   },
   mounted() {
      $(document).ready(function () {
         // ? variable assign
         var ttl_amount = 0
         var ttl_lab_discount = 0
         var ttl_bill_discount = 0
         var ttl_payable = 0
         var cus_id = ''
         var cas_acc = ''
         var tr_id = ''
         var doct_id = ''
         var collect_dattime = ''

         // ? Load data table when the page loads
         // this.$nextTick(() => {
         // ? get all lab test names and ids into select lab_test dropdown
         get_lab_test_names_id()

         // ? get all patient names and ids into select patient dropdown
         get_patient_names_id()

         // ? get all doctor names and ids into select doctor dropdown
         get_doctor_names_id()

         // ? get all not updated transactions dropdown
         get_all_not_updated_transactions()
         //  })

         clear_all_data()

         // ? cal payment return balance
         function cal_payment_return_balance(total, cash, bank, dis_rate, fix_dis) {
            total = total || 0
            cash = cash || 0
            bank = bank || 0
            dis_rate = dis_rate || 0
            fix_dis = fix_dis || 0

            var total_bill_discount = parseFloat(((ttl_amount - ttl_lab_discount) * dis_rate) / 100) + parseFloat(fix_dis)
            var total_discount = parseFloat(total_bill_discount) + parseFloat(ttl_lab_discount)
            var total_payable = parseFloat(ttl_amount - total_bill_discount - ttl_lab_discount)

            // ? public variable equal to that function variables
            ttl_payable = total_payable
            ttl_bill_discount = total_bill_discount
            var return_balance = parseFloat(total_payable - cash - bank)

            $('#pa_payable_lbl').text(formatNumberWithCommas(ttl_payable))
            $('#pa_total_discount_lbl').text(formatNumberWithCommas(total_discount))
            $('#pf_payable_lbl').text(formatNumberWithCommas(ttl_payable))
            $('#pf_total_discount_lbl').text(formatNumberWithCommas(total_discount))

            return parseFloat(return_balance.toFixed(2))
         }

         // ? clear text after save transaction
         function clear_after_save() {
            $('#lab_test_id_cmb').val(null).trigger('change.select2')
            $('#dis_rate_tbx').val('0.00')
            $('#doctor_id_cmb').val(null).trigger('change.select2')
            $('#doctor_account_tbx').val('')
            $('#test_price_r_tbx').val('0')
            $('#test_actual_price_tbx').val('0')
            $('#hospital_id_tbx').val('')
            $('#after_dis_price_r_tbx').val('')
            $('#doctor_rate_tbx').val('')

            $('#test_price_tbx').val('Rs. 0.00')
            $('#after_dis_price_tbx').val('Rs. 0.00')
         }

         // ? clear all data
         function clear_all_data() {
            $('#transaction_id_tbx').val('')
            $('#not_update_transaction_cmb').text('')

            $('#patient_id_cmb').val(null).trigger('change.select2')
            $('#patient_account_tbx').val('')
            $('#patient_contact_tbx').val('')
            $('#patient_age_tbx').val('')
            $('#patient_email_tbx').val('')
            $('#patient_gender_tbx').val('')

            $('#lab_test_id_cmb').val(null).trigger('change.select2')
            $('#dis_rate_tbx').val('0.00')
            $('#doctor_id_cmb').val(null).trigger('change.select2')
            $('#doctor_account_tbx').val('')
            $('#test_price_r_tbx').val('0')
            $('#test_actual_price_tbx').val('0')
            $('#hospital_id_tbx').val('')
            $('#after_dis_price_r_tbx').val('')
            $('#doctor_rate_tbx').val('')

            $('#test_price_tbx').val('Rs. 0.00')
            $('#after_dis_price_tbx').val('Rs. 0.00')

            $('#total_amount_lbl').text('Rs. 0.00')
            $('#total_discount_lbl').text('Rs. 0.00')
            $('#total_payable_lbl').text('Rs. 0.00')

            $('#pf_discount_tbx').val('0')
            $('#pf_discount_rate_tbx').val('0')
            $('#pf_bank_tbx').val('0')
            $('#pf_cash_tbx').val('0')
            $('#pf_balance_tbx').val('0')

            $('#pa_discount_tbx').val('0')
            $('#pa_discount_rate_tbx').val('0')
            $('#pa_bank_tbx').val('0')
            $('#pa_cash_tbx').val('0')
            $('#pa_balance_tbx').val('0')

            $('input[name=pf_printOption][value=P]').attr('checked', true)
            $('input[name=pa_printOption][value=P]').attr('checked', true)

            setTimeout(function () {
               $('#patient_id_cmb').select2('open')
            }, 100)
         }

         // ? Click event for the "New" button
         $('#new_btn').click(function () {
            clear_all_data()

            load_data_table('1')
            // ? get all not updated transactions dropdown
            get_all_not_updated_transactions()
         })

         // ? Click event for the "Clear All" button
         $('#clear_all_btn').click(function () {
            clear_after_save()
         })

         // Click event for the "Refresh" button
         $('#refresh_btn').click(function () {
            // ? get all lab test names and ids into select lab_test dropdown
            get_lab_test_names_id()

            // ? get all patient names and ids into select patient dropdown
            get_patient_names_id()

            // ? get all doctor names and ids into select doctor dropdown
            get_doctor_names_id()

            // ? get all not updated transactions dropdown
            get_all_not_updated_transactions()
         })

         // ? cal after discount price
         function price_cal_and_discount(discountRate, price) {
            // Check if parsed values are valid numbers
            if (discountRate === 0) {
               var formattedPrice = price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
               $('#after_dis_price_tbx').val('Rs. ' + formattedPrice)
               $('#after_dis_price_r_tbx').val(price)
            } else if (discountRate > 100) {
               Swal.fire({
                  title: 'The discount rate can not be greater than 100%.',
                  icon: 'warning',
                  timer: 3000, // Close after 2 seconds
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
               $('#dis_rate_tbx').val(0)
            } else {
               var after_dis_price = price - (price * discountRate) / 100
               $('#after_dis_price_r_tbx').val(after_dis_price.toFixed(2))

               var formattedPrice = after_dis_price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
               $('#after_dis_price_tbx').val('Rs. ' + formattedPrice)
            }
         }

         // ? get all patient names and ids into select patient dropdown
         function get_patient_names_id() {
            $.ajax({
               url: `${API_URL}patient/patient-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#patient_id_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(patient => {
                        const option = new Option(patient.show_name, patient.pat_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#patient_id_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? get all lab test names and ids into select lab_test dropdown
         function get_lab_test_names_id() {
            $.ajax({
               url: `${API_URL}lab-test/lab-test-names/`, // corrected the URL concatenation
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `lab_id` and `show_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#lab_test_id_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(lab_test => {
                        const option = new Option(lab_test.lab_test_name, lab_test.lab_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#lab_test_id_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching lab test names:', error)
               },
            })
         }

         // ? get all doctor names and ids into select doctor dropdown
         function get_doctor_names_id() {
            $.ajax({
               url: `${API_URL}doctor/doctor-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#doctor_id_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(doctor => {
                        const option = new Option(doctor.show_name, doctor.doct_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#doctor_id_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching doctor names:', error)
               },
            })
         }

         // ? get pl account by tracker id
         function get_pl_account_data_pl_acc_id(tracker_id) {
            $.ajax({
               url: `${API_URL}pl-account/tracker/` + tracker_id,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data['pl_acc_type'] == 'CPL') {
                     $('#patient_account_tbx').val(data['pl_acc_no'])
                  } else {
                     $('#doctor_account_tbx').val(data['pl_acc_no'])
                  }
               },
               error: function (error) {
                  console.error('Error fetching doctor names:', error)
               },
            })
         }

         // ? get lab test data to edit and update
         function get_lab_test_details(lab_test_id) {
            $.ajax({
               url: API_URL + 'lab-test/' + lab_test_id,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  var lab_test_price = parseFloat(data.lab_test_price)
                     .toFixed(2)
                     .replace(/\d(?=(\d{3})+\.)/g, '$&,')

                  // Update the DOM elements with the fetched data
                  $('#test_price_tbx').val('Rs. ' + lab_test_price)
                  $('#test_price_r_tbx').val(data.lab_test_price)
                  $('#after_dis_price_tbx').val('Rs. ' + lab_test_price)
                  $('#after_dis_price_r_tbx').val(data.lab_test_price)
                  $('#test_type_tbx').val(data.lab_test_type)
                  $('#test_actual_price_tbx').val(data.lab_test_profit)
                  $('#hospital_id_tbx').val(data.lab_test_hospital_id)
               },
               error: function (error) {
                  console.error('Error fetching lab test details:', error)
                  // Optionally, you can display an error message or handle the error accordingly
               },
            })
         }

         // ? get doctor data to edit and update
         function get_doctor_details(doct_id) {
            $.ajax({
               url: `${API_URL}` + 'doctor/' + doct_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  var test_type = $('#test_type_tbx').val()
                  if (test_type == 'O') {
                     $('#doctor_rate_tbx').val(data['doct_other_commission_rate'])
                  } else {
                     $('#doctor_rate_tbx').val(data['doct_commission_rate'])
                  }
               },
            })
         }

         // ? get patient details after select patient
         function get_patient_details(patient_id) {
            $.ajax({
               url: `${API_URL}` + 'patient/' + patient_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data['pat_gender'] == 'M') {
                     $('#patient_gender_tbx').val('Male')
                  } else {
                     $('#patient_gender_tbx').val('Female')
                  }

                  $('#patient_contact_tbx').val(data['pat_contact'])
                  $('#patient_age_tbx').val(data['pat_age'])
                  $('#patient_email_tbx').val(data['pat_email'])

                  $('#pa_customer_lbl').text(data['pat_name'])
                  $('#pf_customer_lbl').text(data['pat_name'])
               },
            })
         }

         // ? get all not updated transactions dropdown
         function get_all_not_updated_transactions() {
            $.ajax({
               url: `${API_URL}lab-test-transaction/not-update-transactions/`, // corrected the URL concatenation
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  const selectElement = $('#not_update_transaction_cmb')

                  // Clear any existing options
                  selectElement.empty()

                  // Check if data is empty
                  if (typeof data === 'undefined') {
                     return
                  } else if (data.message) {
                     const option = $('<option></option>').text('No data found').attr('disabled', true).attr('selected', true) // Optionally, make this option selected
                     selectElement.append(option)
                     return // Exit the function
                  } else {
                     // Append each transaction as an option to the select element
                     data.forEach(transaction => {
                        const option = $('<option></option>')
                           .attr('value', transaction.lab_tra_id_tmp) // Set the value attribute of the option
                           .attr('data-id', transaction.lab_tra_cus_id_tmp)
                           .text(transaction.not_update_tra) // Set the text content of the option

                        selectElement.append(option)
                        $('#not_update_transaction_cmb').val(null)
                     })
                  }
               },
               error: function (error) {
                  console.error('Error fetching lab test names:', error)
               },
            })
         }

         // ? get selected bill total values
         function get_transaction_total_values(tra_id) {
            $.ajax({
               url: `${API_URL}` + 'lab-test-transaction/total-values/' + tra_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#total_amount_lbl').text(formatNumberWithCommas(data['total_price']))
                  $('#total_discount_lbl').text(formatNumberWithCommas(data['total_discount']))
                  $('#total_payable_lbl').text(formatNumberWithCommas(data['total_amount']))

                  $('#pf_total_amount_lbl').text(formatNumberWithCommas(data['total_price']))
                  $('#pf_total_discount_lbl').text(formatNumberWithCommas(data['total_discount']))
                  $('#pf_payable_lbl').text(formatNumberWithCommas(data['total_amount']))

                  $('#pa_total_amount_lbl').text(formatNumberWithCommas(data['total_price']))
                  $('#pa_total_discount_lbl').text(formatNumberWithCommas(data['total_discount']))
                  $('#pa_payable_lbl').text(formatNumberWithCommas(data['total_amount']))

                  ttl_amount = data['total_price']
                  ttl_lab_discount = data['total_discount']
                  ttl_payable = data['total_amount']

                  doct_id = data['doctor_id']
                  collect_dattime = data['collected_time']
               },
            })
         }

         // ? selected row delete function
         $('#transaction_data_table').on('click', '#delete_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var row_id = $(this).data('id')

            Swal.fire({
               title: 'Are you sure?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, delete it!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
            }).then(result => {
               if (result.isConfirmed) {
                  $.ajax({
                     url: `${API_URL}` + 'lab-test-transaction/delete-transactions/' + row_id,
                     type: 'DELETE',
                     dataType: 'json',
                     success: function (response) {
                        Swal.fire({
                           title: 'Deleted!',
                           text: 'Your data has been successfully removed.',
                           icon: 'success',
                           timer: 2000, // Close after 2 seconds
                           timerProgressBar: true,
                           onBeforeOpen: () => {
                              Swal.showLoading() // Show a progress bar while waiting
                           },
                        })
                        load_data_table($('#transaction_id_tbx').val())
                        // ? get selected bill total values
                        get_transaction_total_values($('#transaction_id_tbx').val())
                     },

                     error: function (error) {
                        Swal.fire('Error!', 'There was an error deleting the data.', 'error')
                     },
                  })
               } else if (result.dismiss === Swal.DismissReason.cancel) {
                  Swal.fire('Cancelled', 'Your action has been cancelled :)', 'error')
               }
            })
         })

         // ? patient select box enter button click event selected change event
         $('#patient_id_cmb').on('change', function () {
            // ? Your code for Select Patient select2 change event
            var patient_id = $(this).val()

            // ? get patient details after select patient
            get_patient_details(patient_id)

            // ? get tracker pl account
            get_pl_account_data_pl_acc_id(patient_id)

            $('#lab_test_id_cmb').select2('open')
         })

         // ? Attach a change event listener to the select element with the ID 'not_update_transaction_cmb'
         $('#not_update_transaction_cmb').on('change', function () {
            var selectedValue = $(this).val()
            var selectedDataId = $(this).find('option:selected').data('id')

            $('#patient_id_cmb').val(selectedDataId).trigger('change')
            $('#transaction_id_tbx').val(selectedValue)

            // ? get selected bill total values
            get_transaction_total_values(selectedValue)

            load_data_table(selectedValue)
         })

         // ? Lab Test select box enter button click event selected change event
         $('#lab_test_id_cmb').on('change', function () {
            const patient_Id = $('#patient_id_cmb').val()

            if (!patient_Id) {
               Swal.fire({
                  title: 'Please select a patient first!',
                  icon: 'warning',
                  timer: 3000, // Close after 2 seconds
                  timerProgressBar: true,
                  showConfirmButton: false,
               })

               $('#lab_test_id_cmb').val('')
            } else {
               // ? Your code for Lab Test Name select2 change event
               var lab_test_id = $(this).val()
               // ? get lab test data to edit and update

               get_lab_test_details(lab_test_id)

               const date = dateTimeMixin.methods.getCurrentDate.call(this)
               const time = dateTimeMixin.methods.getCurrentTime.call(this)
               $('#sample_collect_date_dtmp').val(date)
               $('#sample_collect_time_dtmp').val(time)

               $('#doctor_id_cmb').val('')

               setTimeout(function () {
                  $('#dis_rate_tbx').focus().select()
               }, 100)
            }
         })

         // ? Reference select box enter button click event  selected change event
         $('#doctor_id_cmb').on('change', function () {
            const lab_test_Id = $('#lab_test_id_cmb').val()

            if (!lab_test_Id) {
               Swal.fire({
                  title: 'Please select a lab test first!',
                  icon: 'warning',
                  timer: 3000, // Close after 2 seconds
                  timerProgressBar: true,
                  showConfirmButton: false,
               })

               $('#doctor_id_cmb').val('')
            } else {
               // ?  Your code for Reference select2 change event
               var doctor_id = $(this).val()

               // ? get tracker pl account
               get_pl_account_data_pl_acc_id(doctor_id)

               // ? get doctor data to edit and update
               get_doctor_details(doctor_id)
               setTimeout(function () {
                  document.getElementById('lab_test_add_btn').click()
               }, 1000)
            }
         })

         // Click event for add patient model show button
         $('#add_patient_model_btn').click(function () {
            setTimeout(function () {
               $('#name_tbx').focus().select()
            }, 200)
         })

         // ? Attach click event to the add patient button using jQuery
         $('#patient_add_btn').on('click', () => {
            const form = document.getElementById('add_patient_data_form')
            const isValid = form.checkValidity()

            if (isValid) {
               $('#patient_add_btn').prop('disabled', true).val('wait...')

               // ? Get values from the input fields
               const name = $('#name_tbx').val()
               const location = $('#location_tbx').val()
               const contact = $('#contact_tbx').val()
               const email = $('#email_tbx').val()
               const birthday = $('#birthday_tbx').val()
               const gender = $('#gender_cmb').val()

               // ? Create patient data object
               const patientData = {
                  pat_name: name,
                  pat_city: location,
                  pat_contact: contact,
                  pat_email: email,
                  pat_birthday: birthday,
                  pat_gender: gender,
                  pat_branch: window.globalData.branchId,
               }

               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}patient/`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(patientData),
                  success: response => {
                     // ? Reset the form
                     $('#add_patient_data_form')[0].reset()

                     // ? pl account balance update function call
                     pl_account_balance_update()

                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'New patient added successfully!',
                        text: 'well done..',
                        timer: 2000,
                        showConfirmButton: true,
                        allowOutsideClick: true,
                     })

                     // ? get all patient names and ids into select patient dropdown
                     get_patient_names_id()

                     // ? Enable the button
                     $('#patient_add_btn').prop('disabled', false).val('Submit')

                     $('[data-dismiss="modal"]').click()
                  },
                  error: error => {
                     // ? Handle error
                     $('#patient_add_btn').prop('disabled', false).val('Submit')
                  },
               })
            }
         })

         // ? discount rate text change event
         $('#dis_rate_tbx').on('keyup', function () {
            var price = $('#test_price_r_tbx').val()
            var disrate = $('#dis_rate_tbx').val()

            price_cal_and_discount(disrate, price)
         })

         // ? discount rate textbox enter button click event
         $('#dis_rate_tbx').on('keydown', function (event) {
            // ? Check if the pressed key is Enter (keyCode 13)
            if (event.keyCode === 13) {
               // ? For example, focus on Reference select2
               $('#sample_collect_date_dtmp').focus().select()
            }
         })

         // ? discount rate textbox enter button click event
         $('#sample_collect_date_dtmp').on('keydown', function (event) {
            // ? Check if the pressed key is Enter (keyCode 13)
            if (event.keyCode === 13) {
               // ? For example, focus on Reference select2
               $('#sample_collect_time_dtmp').focus().select()
            }
         })

         // ? discount rate textbox enter button click event
         $('#sample_collect_time_dtmp').on('keydown', function (event) {
            // ? Check if the pressed key is Enter (keyCode 13)
            if (event.keyCode === 13) {
               // ? For example, focus on Reference select2
               $('#doctor_id_cmb').select2('open')
            }
         })

         // Handle the lab test add button  click event here
         $('#lab_test_add_btn').click(function () {
            const transaction_id = $('#transaction_id_tbx').val()
            const patient_id = $('#patient_id_cmb').val()
            const patient_account = $('#patient_account_tbx').val()

            const lab_test_id = $('#lab_test_id_cmb').val()
            const dis_rate = $('#dis_rate_tbx').val()
            const sample_collect_date = $('#sample_collect_date_dtmp').val()
            const sample_collect_time = $('#sample_collect_time_dtmp').val()
            const sample_collect_date_time = sample_collect_date + ' ' + sample_collect_time
            const doctor_id = $('#doctor_id_cmb').val()
            const doctor_account = $('#doctor_account_tbx').val()
            const test_price = $('#test_price_r_tbx').val()
            const test_actual_price = $('#test_actual_price_tbx').val()
            const hospital_id = $('#hospital_id_tbx').val()
            const after_dis_price = $('#after_dis_price_r_tbx').val()
            const doctor_rate = $('#doctor_rate_tbx').val()

            if (!patient_account) {
               Swal.fire({
                  title: 'Error!',
                  text: 'Patient account cannot be empty.',
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (!lab_test_id) {
               Swal.fire({
                  title: 'Error!',
                  text: 'Lab test ID cannot be empty.',
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (!doctor_id) {
               Swal.fire({
                  title: 'Error!',
                  text: 'Doctor ID cannot be empty.',
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (!sample_collect_date) {
               Swal.fire({
                  title: 'Error!',
                  text: 'Sample collection date cannot be empty.',
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (!sample_collect_time) {
               Swal.fire({
                  title: 'Error!',
                  text: 'Sample collection time cannot be empty.',
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else {
               $('#lab_test_add_btn').prop('disabled', true).val('wait...')

               // ? Create an object with the extracted values
               const testTraData = {
                  lab_tra_id_tmp: transaction_id,
                  lab_tra_collect_datetime_tmp: sample_collect_date_time,
                  lab_tra_date_tmp: dateTimeMixin.methods.getCurrentDate.call(this),
                  lab_tra_time_tmp: dateTimeMixin.methods.getCurrentTimeS.call(this),
                  lab_tra_cus_id_tmp: patient_id,
                  lab_tra_cus_acc_tmp: patient_account,
                  lab_tra_doct_id_tmp: doctor_id,
                  lab_tra_doct_acc_tmp: doctor_account,
                  lab_tra_doct_rate_tmp: doctor_rate,
                  lab_tra_lab_test_id_tmp: lab_test_id,
                  lab_tra_price_tmp: test_price,
                  lab_tra_actual_price_tmp: test_actual_price,
                  lab_tra_discount_rate_tmp: dis_rate,
                  lab_tra_total_discount_tmp: test_price - after_dis_price,
                  lab_tra_total_amount_tmp: test_price,
                  lab_tra_hospital_id_tmp: hospital_id,
                  lab_tra_lab_test_send_time_tmp: '0000-00-00 00:00:00',
                  lab_tra_lab_test_receive_time_tmp: '0000-00-00 00:00:00',
                  lab_tra_branch_id_tmp: window.globalData.branchId,
                  lab_tra_user_id_tmp: window.globalData.id,
               }

               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}lab-test-transaction/`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(testTraData),
                  success: response => {
                     if (!transaction_id) {
                        $('#transaction_id_tbx').val(response.tra_id)
                     }
                     // ? Reset the form

                     // ? Show success message
                     // Swal.fire({
                     //    icon: 'success',
                     //    title: 'Added Successfully!',
                     //    text: 'well done..',
                     //    timer: 1000,
                     //    showConfirmButton: true,
                     //    allowOutsideClick: true,
                     // })

                     // ? Enable the button
                     $('#lab_test_add_btn').prop('disabled', false).val('Save')

                     // ? get selected bill total values
                     get_transaction_total_values($('#transaction_id_tbx').val())

                     clear_after_save()

                     // ? Reload the data table
                     load_data_table($('#transaction_id_tbx').val())

                     // ? get all not updated transactions dropdown
                     get_all_not_updated_transactions()

                     setTimeout(function () {
                        $('#lab_test_id_cmb').select2('open')
                     }, 100)
                  },
                  error: error => {
                     // ? Handle error
                     $('#lab_test_add_btn').prop('disabled', false).val('Save - F4')
                  },
               })
            }
         })

         $('#pay_bill_btn').click(function () {
            const transaction_id = $('#transaction_id_tbx').val()

            if (!transaction_id) {
               Swal.fire({
                  title: 'Error!',
                  text: 'Please Select A Transaction ID.',
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else {
               const modalElement = document.getElementById('payment_select_model')
               const modal = new bootstrap.Modal(modalElement)
               // Show the modal
               modal.show()
               setTimeout(function () {
                  $('#select_advance_btn').focus()

                  cus_id = $('#patient_id_cmb').val()
                  cas_acc = $('#patient_account_tbx').val()
                  tr_id = transaction_id
               }, 200)
            }
         })

         // Click event for Advance Payment button
         $('#select_advance_btn').click(function () {
            $('[data-dismiss="modal"]').click()

            const modalElement = document.getElementById('advance_payment_model')
            const modal = new bootstrap.Modal(modalElement)
            // Show the modal
            modal.show()

            setTimeout(function () {
               $('#pa_cash_tbx').focus().select()
            }, 200)
         })

         // Click event for Full Payment button
         $('#select_full_pay_btn').click(function () {
            $('[data-dismiss="modal"]').click()

            const modalElement = document.getElementById('full_payment_model')
            const modal = new bootstrap.Modal(modalElement)
            // Show the modal
            modal.show()

            setTimeout(function () {
               $('#pf_cash_tbx').focus().select()
            }, 200)
         })

         $('#pf_cash_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pf_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pf_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pf_balance_tbx').val(returnbl)
         })

         // For bank input
         $('#pf_bank_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pf_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pf_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pf_balance_tbx').val(returnbl)
         })

         $('#pf_discount_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pf_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pf_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pf_balance_tbx').val(returnbl)
         })

         // For bank input
         $('#pf_discount_rate_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pf_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pf_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pf_balance_tbx').val(returnbl)
         })

         $('#pa_cash_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pa_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pa_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pa_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pa_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pa_balance_tbx').val(returnbl)
         })

         // For bank input
         $('#pa_bank_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pa_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pa_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pa_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pa_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pa_balance_tbx').val(returnbl)
         })

         // For bank input
         $('#pa_discount_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pa_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pa_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pa_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pa_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pa_balance_tbx').val(returnbl)
         })

         // For bank input
         $('#pa_discount_rate_tbx').on('input', function () {
            var bankAmount = parseFloat($('#pa_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pa_cash_tbx').val(), 2) || 0
            var dis_rate = parseFloat($('#pa_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pa_discount_tbx').val(), 2) || 0
            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)

            $('#pa_balance_tbx').val(returnbl)
         })

         $('#pa_cash_tbx').keypress(function (event) {
            // Check if the key pressed is Enter (key code 13)
            if (event.which == 13) {
               document.getElementById('pay_advance_btn').click()
            }
         })

         $('#pa_bank_tbx').keypress(function (event) {
            // Check if the key pressed is Enter (key code 13)
            if (event.which == 13) {
               $('#pa_cash_tbx').focus().select() // Call your submit function or any other action you want
            }
         })

         $('#pf_cash_tbx').keypress(function (event) {
            // Check if the key pressed is Enter (key code 13)
            if (event.which == 13) {
               document.getElementById('pay_full_btn').click()
            }
         })

         $('#pf_bank_tbx').keypress(function (event) {
            // Check if the key pressed is Enter (key code 13)
            if (event.which == 13) {
               $('#pf_cash_tbx').focus().select() // Call your submit function or any other action you want
            }
         })

         // Click event for pay Advance Payment button
         $('#pay_full_btn').click(function () {
            var cus_age = $('#patient_age_tbx').val()
            var cus_gender = $('#patient_gender_tbx').val()

            var bankAmount = parseFloat($('#pf_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pf_cash_tbx').val(), 2) || 0
            var totalPayment = parseFloat(bankAmount + cashAmount)

            var actual_total_payment = 0
            var actual_cash_payment = 0

            var dis_rate = parseFloat($('#pf_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pf_discount_tbx').val(), 2) || 0

            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)
            $('#pf_balance_tbx').val(returnbl)
            var ReturnBalance = parseFloat($('#pf_balance_tbx').val(), 2) || 0

            if (ReturnBalance < 0) {
               actual_total_payment = ttl_payable
               actual_cash_payment = parseFloat(cashAmount + ReturnBalance).toFixed(2)
            } else {
               actual_total_payment = totalPayment
               actual_cash_payment = cashAmount
            }

            var total_billing_sum_of_discount = parseFloat(ttl_lab_discount) + parseFloat(ttl_bill_discount)

            // console.log(bankAmount, cashAmount, totalPayment, ttl_payable)
            if (bankAmount > ttl_payable) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  text: 'Bank payment can not exceed the payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic for payment or other actions.
                  },
               })
            } else if (totalPayment === 0) {
               // Assuming you want to handle the case where both amounts are zero.
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Both bank and cash payments cannot be zero!',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic or actions related to this case.
                  },
               })
            } else if (totalPayment < ttl_payable) {
               // Assuming you want to handle the case where both amounts are zero.
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Payment can not be less than the payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic or actions related to this case.
                  },
               })
            } else if (dis_rate > 100) {
               // Assuming you want to handle the case where both amounts are zero.
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Discount rate can not be grater than 100% !',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic or actions related to this case.
                  },
               })
            } else if (dis_fixed > ttl_amount) {
               // Assuming you want to handle the case where both amounts are zero.
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Discount can not be Grater than the payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic or actions related to this case.
                  },
               })
            } else {
               $('#pay_full_btn').prop('disabled', true).val('wait...')

               const dataSet = {
                  gl_tra_branch: window.globalData.branchId,
                  pl_tra_ref_id: cus_id,
                  pl_tra_ref_acc: cas_acc,
                  pl_tra_lab_test_discount: ttl_lab_discount,
                  pl_tra_bill_discount: ttl_bill_discount,
                  pl_tra_ttl_discount: total_billing_sum_of_discount,
                  pl_tra_ttl_amount: ttl_amount,
                  pl_tra_payable_amount: ttl_payable,
                  pl_tra_cash_bal: cashAmount,
                  pl_tra_bank_bal: bankAmount,
                  pl_tra_balance: ReturnBalance,
                  pl_tra_user: window.globalData.id,
                  pl_tra_total_payment: actual_total_payment,
                  actual_cash_bal: actual_cash_payment,
                  pl_tra_lab_test_tra: tr_id,
                  pltb_collected_time: collect_dattime,
                  pltb_age: cus_age,
                  pltb_gender: cus_gender,
                  pltb_doctorid: doct_id,
               }

               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}account-transaction/customer-bill-payment-advance/`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(dataSet),
                  success: response => {
                     $('#pay_full_btn').prop('disabled', false).val('Submit')
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Payment successful!',
                        timer: 2000,
                        timerProgressBar: true,
                     })

                     // ?  copy lab test data from temp table to permanent table
                     pl_lab_transaction_copy_from_temp_sp_call()

                     // ?  call doctor and hospital fee and ref data update sp
                     doctor_fee_Hospital_fee_ref_account_data_update_sp_call()

                     pl_account_balance_update()

                     setTimeout(function () {
                        // Check the second radio button with name "printOption"
                        if ($('#pf_print_rbtn').prop('checked')) {
                           var url = `${BILL_PRINT_URL}` + tr_id
                           window.open(url, '_blank')
                        }

                        $('[data-dismiss="modal"]').click()

                        // ? clear all data
                        clear_all_data()

                        // ? get all not updated transactions dropdown
                        get_all_not_updated_transactions()

                        load_data_table('1')
                     }, 1000)
                  },
                  error: error => {
                     // ? Handle error
                     $('#pay_full_btn').prop('disabled', false).val('Submit')
                  },
               })
            }

            //console.log(cal_payment_return_balance(ttl_payable, cashAmount, bankAmount))
         })

         // Click event for pay Full Payment button
         $('#pay_advance_btn').click(function () {
            var cus_age = $('#patient_age_tbx').val()
            var cus_gender = $('#patient_gender_tbx').val()

            var bankAmount = parseFloat($('#pa_bank_tbx').val(), 2) || 0
            var cashAmount = parseFloat($('#pa_cash_tbx').val(), 2) || 0
            var totalPayment = parseFloat(bankAmount + cashAmount)

            var actual_total_payment = 0
            var actual_cash_payment = 0

            var dis_rate = parseFloat($('#pa_discount_rate_tbx').val(), 2) || 0
            var dis_fixed = parseFloat($('#pa_discount_tbx').val(), 2) || 0

            var returnbl = cal_payment_return_balance(ttl_payable, cashAmount, bankAmount, dis_rate, dis_fixed)
            $('#pa_balance_tbx').val(returnbl)
            var ReturnBalance = parseFloat($('#pa_balance_tbx').val(), 2) || 0

            if (ReturnBalance < 0) {
               actual_total_payment = ttl_payable
               actual_cash_payment = parseFloat(cashAmount + ReturnBalance).toFixed(2)
            } else {
               actual_total_payment = totalPayment
               actual_cash_payment = cashAmount
            }

            var total_billing_sum_of_discount = parseFloat(ttl_lab_discount) + parseFloat(ttl_bill_discount)

            if (bankAmount > ttl_payable) {
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Bank payment cannot be greater than payable balance!',
                  timer: 2000, // Close after 2 seconds
                  timerProgressBar: true,
                  onClose: () => {
                     // You can add any logic here that you want to execute when the alert is closed
                  },
               })
               return // Exit the function early
            } else if (dis_rate > 100) {
               // Assuming you want to handle the case where both amounts are zero.
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Discount rate can not be grater than 100% !',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic or actions related to this case.
                  },
               })
            } else if (dis_fixed > ttl_amount) {
               // Assuming you want to handle the case where both amounts are zero.
               Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Discount can not be Grater than the payable balance!',
                  timer: 2000,
                  timerProgressBar: true,
                  onClose: () => {
                     // Handle any logic or actions related to this case.
                  },
               })
            } else {
               $('#pay_advance_btn').prop('disabled', true).val('wait...')

               const dataSet = {
                  gl_tra_branch: window.globalData.branchId,
                  pl_tra_ref_id: cus_id,
                  pl_tra_ref_acc: cas_acc,
                  pl_tra_lab_test_discount: ttl_lab_discount,
                  pl_tra_bill_discount: ttl_bill_discount,
                  pl_tra_ttl_discount: total_billing_sum_of_discount,
                  pl_tra_ttl_amount: ttl_amount,
                  pl_tra_payable_amount: ttl_payable,
                  pl_tra_cash_bal: cashAmount,
                  pl_tra_bank_bal: bankAmount,
                  pl_tra_balance: ReturnBalance,
                  pl_tra_user: window.globalData.id,
                  pl_tra_total_payment: actual_total_payment,
                  actual_cash_bal: actual_cash_payment,
                  pl_tra_lab_test_tra: tr_id,
                  pltb_collected_time: collect_dattime,
                  pltb_age: cus_age,
                  pltb_gender: cus_gender,
                  pltb_doctorid: doct_id,
               }

               // ? Make the POST request using $.ajax()
               $.ajax({
                  url: `${API_URL}account-transaction/customer-bill-payment-advance/`,
                  type: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify(dataSet),
                  success: response => {
                     $('#pay_advance_btn').prop('disabled', false).val('Submit')
                     // ? Show success message
                     Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Payment successful!',
                        timer: 2000,
                        timerProgressBar: true,
                     })
                     setTimeout(function () {
                        // ?  copy lab test data from temp table to permanent table
                        pl_lab_transaction_copy_from_temp_sp_call()

                        // ?  call doctor and hospital fee and ref data update sp
                        doctor_fee_Hospital_fee_ref_account_data_update_sp_call()

                        pl_account_balance_update()

                        // Check the second radio button with name "printOption"
                        if ($('#pa_print_rbtn').prop('checked')) {
                           var url = `${BILL_PRINT_URL}` + tr_id
                           window.open(url, '_blank')
                        }

                        $('[data-dismiss="modal"]').click()

                        // ? clear all data
                        clear_all_data()

                        // ? get all not updated transactions dropdown
                        get_all_not_updated_transactions()
                        load_data_table('1')
                     }, 1000)
                  },
                  error: error => {
                     // ? Handle error
                     $('#pay_advance_btn').prop('disabled', false).val('Submit')
                  },
               })
            }
         })

         function load_data_table(traID) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}lab-test-transaction/view-data-table/` + traID,
                  type: 'GET',
               },
               columns: [
                  { data: 'lab_test_name' },
                  {
                     data: 'lab_tra_price_tmp',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_discount_rate_tmp',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${
                                parseFloat(data).toLocaleString(undefined, {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                }) + '%'
                             }</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_total_discount_tmp',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'amount',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'doct_name' },
                  { data: 'hos_name' },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-danger btn-sm" id="delete_btn" data-id="' +
                           row.id_tmp +
                           '" ><i class="fa-solid fa-trash"></i></button>'
                        )
                     },
                  },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal

                  $('#row_count_lbl').text(totalRecords)
               }
            }

            // ? Initialize DataTable
            $('#transaction_data_table').DataTable(dataTableOptions)
         }
      })

      function pl_account_balance_update() {
         $.ajax({
            url: `${API_URL}account-transaction/call-pl-account-balance-update-store-procedure`,
            type: 'GET',
            success: function (response) {
               //console.log('Stored procedure executed successfully', response)
            },
            error: function (xhr, status, error) {
               // console.error('Error executing stored procedure', error)
            },
         })
      }

      // ?  copy lab test data from temp table to permanent table
      function pl_lab_transaction_copy_from_temp_sp_call() {
         $.ajax({
            url: `${API_URL}lab-test-transaction/call-lab-test-transaction-copy-and-delete-temp-table-sp`,
            type: 'GET',
            success: function (response) {
               //console.log('Stored procedure executed successfully', response)
            },
            error: function (xhr, status, error) {
               //console.error('Error executing stored procedure', error)
               //alert('Error executing stored procedure')
            },
         })
      }

      // ?  call doctor and hospital fee and ref data update sp
      function doctor_fee_Hospital_fee_ref_account_data_update_sp_call() {
         $.ajax({
            url: `${API_URL}lab-test-transaction/call-doctor-fee-hospital-fee-and-reference-data-sp`,
            type: 'GET',
            success: function (response) {
               //console.log('Stored procedure executed successfully', response)
            },
            error: function (xhr, status, error) {
               //console.error('Error executing stored procedure', error)
               //alert('Error executing stored procedure')
            },
         })
      }

      // ? key press events
      $(document).keydown(function (event) {
         switch (event.which) {
            case 113: // F2
               document.getElementById('new_btn').click()
               break
            case 115: // F4
               document.getElementById('lab_test_add_btn').click()
               break
            case 123: // F12
               document.getElementById('pay_bill_btn').click()
               break
            case 119: // F8
               document.getElementById('clear_all_btn').click()
               break
            case 120: // F9
               document.getElementById('refresh_btn').click()
               break
            case 121: // F10
               document.getElementById('add_patient_model_btn').click()
               break
         }
      })
   },
   methods: {
      logout() {
         // Perform logout logic
         this.$store.dispatch('logout')
         this.$router.push('/login')
      },
   },
}
</script>
<style lang=""></style>
