<template>
   <div>
      <div class="d-flex justify-content-center align-items-center" style="background: #000829">
         <img src="Assets/images/customer-report-page-logo.png" alt="Customer Report Page Logo" height="75" />
      </div>
      <div>
         <input type="hidden" name="bill_no_tbx" id="bill_no_tbx" :value="$route.params.id" />
         <!-- Other content related to the customer report -->
      </div>
      <div class="container mt-4">
         <div id="mainCardView" class="row">
            <!-- Main Card View will be populated dynamically -->
         </div>
         <div id="listCardView" class="row mt-4">
            <!-- List Card View will be populated dynamically -->
         </div>
         <input type="hidden" id="cus_id" />
      </div>
      <footer class="footer bg-dark text-light py-4">
         <div class="container text-center">
            <div class="row">
               <div class="col-md-6">
                  <h5>Contact Details</h5>
                  <p>Email: healthcarelab.info@gmail.com</p>
                  <p>Phone: 037-2246363</p>
                  <p>Address: No. 133, Negombo Rd, Pannala</p>
               </div>
               <div class="col-md-6">
                  <h5>Having Any Issues?</h5>
                  <p>If you encounter any problems, please feel free to contact us.</p>
                  <p><a href="mailto:healthcarelab.info@gmail.com">Email Support</a></p>
                  <p><a href="tel:+94372246363">Call Support</a></p>
               </div>
            </div>
            <div class="row mt-4">
               <div class="col">
                  <p>From Sample to Report: Excellence, Integrity, and Care in Every Step.</p>
               </div>
            </div>
         </div>
      </footer>
   </div>
</template>

<script>
import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, ENCRYPT_SCRT_KEY, BILL_PRINT_URL, FILE_UPLOAD_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS
import CryptoJS from 'crypto-js'

export default {
   mounted() {
      $(document).ready(function () {
         var balance = 0
         var id = $('#bill_no_tbx').val()

         function formatCurrency(amount) {
            // Check if amount is a valid number
            if (isNaN(amount)) {
               return '0.00' // Return default value or handle error
            }

            // Convert to string and split into parts for formatting
            const parts = parseFloat(amount).toFixed(2).toString().split('.')

            // Format integer part with commas
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

            // Join parts back together with decimal point
            return parts.join('.')
         }

         function decryptValue(id) {
            // Replace URL-safe characters back to their original form
            id = id.replace(/_/g, '/').replace(/-/g, '+')

            // Decrypt using CryptoJS and your secret key
            var decrypted = CryptoJS.AES.decrypt(id, `${ENCRYPT_SCRT_KEY}`).toString(CryptoJS.enc.Utf8)

            return decrypted
         }

         // Get the lab transaction ID from the URL parameters
         if (decryptValue(id)) {
            $.ajax({
               url: `${API_URL}patient/patient-report-download-view-table/${decryptValue(id)}`,
               type: 'GET',
               success: function (response) {
                  const data = response.data

                  // Render main card view
                  $('#mainCardView').empty() // Clear existing content
                  data.forEach(patient => {
                     // assign to available balance in patient account - due payments
                     balance = patient.pl_acc_avb_balance

                     $('#cus_id').val(patient.lab_tra_cus_id)
                     const mainCardHtml = `<div class="card text-center border border-warning border-4" style="background-color:#300062; border-radius: 20px;">
              <div class="card-body">
                <h5 class="card-title mb-1 text-light">${patient.pat_name}</h5>
                <p class="card-subtitle mb-1 text-light">${patient.pat_gender === 'M' ? 'Male' : 'Female'}</p>
                <p class="card-subtitle mb-1 text-light">${patient.pat_age}</p>
                <p class="card-text mb-1 text-light">Due Balance: Rs. ${formatCurrency(patient.pl_acc_avb_balance)}</p>
                <p class="card-text text-light">${patient.lab_tra_date}</p>
              </div>
            </div>`

                     $('#mainCardView').append(mainCardHtml)
                  })

                  // Render list card view
                  $('#listCardView').empty() // Clear existing content
                  data.forEach(patient => {
                     patient.tests.forEach(test => {
                        const listCardHtml = `<div class="col-md-4 mb-4">
                 <div class="card text-center border border-4" style="background-color:#FDD674; border-color:#300062; border-radius: 20px;">
                   <div class="card-body">
                     <h5 class="card-title">${test.lab_test_print_name}</h5>
                     ${
                        test.lab_tra_complete_status === 'N'
                           ? '<span class="badge bg-primary">Pending</span>'
                           : test.lab_tra_complete_status === 'R'
                           ? '<span class="badge bg-secondary">Pending Retest</span>'
                           : test.lab_tra_complete_status === 'C'
                           ? '<span class="badge bg-danger">Canceled</span>'
                           : test.lab_tra_complete_status === 'A'
                           ? '<span class="badge bg-success">Pending Approval</span>'
                           : test.lab_tra_complete_status === 'Y'
                           ? test.lab_test_type === 'O'
                              ? `<button class="btn btn-success rounded-pill btn-block" id="other_download_btn" data-id="${test.id}" data-patient="${patient.pat_name}" data-test="${test.lab_test_print_name}" type="submit">Other Download</button>`
                              : `<button class="btn btn-success rounded-pill btn-block" id="report_download_btn" data-id="${test.id}" data-patient="${patient.pat_name}" data-test="${test.lab_test_print_name}" type="submit">Download</button>`
                           : ''
                     }
                   </div>
                 </div>
               </div>`

                        $('#listCardView').append(listCardHtml)
                     })
                  })
               },
               error: function (xhr, status, error) {
                  console.error('Error fetching data:', error)
                  // Handle error display as needed
               },
            })
         } else {
            $('#mainCardView').html('<p>No lab transaction ID provided</p>') // Show message if no lab transaction ID
         }

         // Add event listener to the listCardView element
         $('#listCardView').on('click', '#report_download_btn', function (event) {
            // Prevent the default action of the event
            event.preventDefault()

            // Check if the balance is greater than 0
            if (balance > 0) {
               // If the balance is greater than 0, show a warning message
               Swal.fire({
                  title: 'Do you have due payment?',
                  text: 'Please complete the payment to download the report.',
                  icon: 'warning',
                  showCancelButton: true,
                  cancelButtonText: 'Close',
                  timer: 5000,
                  timerProgressBar: true,
               })
            } else {
               // If the balance is 0, get the data from the button
               const testId = $(this).data('id')
               const patientName = $(this).data('patient')
               const testName = $(this).data('test')
               const reportName = `${patientName} - ${testName}`

               // Disable the button and change the text to 'Generating...'
               $(this)
                  .prop('disabled', true)
                  .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Generating...')

               // Make an AJAX request to generate the PDF
               $.ajax({
                  url: `${API_URL}pdf/create-pdf-report`,
                  method: 'GET',
                  data: {
                     url: `https://health-care-lab-pannala.hashloopit.com/Report-Print/lab-report-with-letter-head.php?ref_id=${testId}`,
                     filename: `REPORT ${reportName}`,
                  },
                  xhrFields: {
                     responseType: 'blob', // Handle the response as a Blob
                  },
                  success: function (data) {
                     // Enable the button and change the text back to 'Download Report'
                     $('#report_download_btn').prop('disabled', false).html('Download Report')
                     // Create a Blob object from the data
                     const blob = new Blob([data], { type: 'application/pdf' })
                     // Create a link element
                     const link = document.createElement('a')
                     // Set the href attribute of the link to the Blob object
                     link.href = window.URL.createObjectURL(blob)
                     // Set the download attribute of the link to the report name
                     link.download = `REPORT ${reportName}.pdf`
                     // Append the link to the body of the document
                     document.body.appendChild(link)
                     // Click the link to download the file
                     link.click()
                     // Remove the link from the body of the document
                     document.body.removeChild(link)
                  },
                  error: function (xhr, status, error) {
                     // Log the error to the console
                     console.error('Error generating PDF:', error)
                     // Enable the button and change the text back to 'Download Report'
                     $('#report_download_btn').prop('disabled', false).html('Download Report')
                  },
               })
            }
         })

         $('#listCardView').on('click', '#other_download_btn', function (event) {
            event.preventDefault()

            // Get the test ID from data-id attribute
            const testId = $(this).data('id')
            const cus_id = $('#cus_id').val()

            var url = FILE_UPLOAD_URL + 'view-pdf.php?file_name=' + cus_id + '/' + testId + '.pdf'
            window.open(url, '_blank')
         })
      })
   },
}
</script>

<style></style>
