import GLAccountManagement from '../pages/account/GLAccountManagement.vue'
import IncomeTransaction from '../pages/account/IncomeTransaction.vue'
import ExpensesTransaction from '../pages/account/ExpensesTransaction.vue'

const AccountRoutes = [
   // ? gl account management page show rout
   {
      path: '/gl-account-management',
      name: '/gl-account-management',
      component: GLAccountManagement,
      meta: { requiresAuth: true },
   },

   // ? other expenses page show rout
   {
      path: '/other-expenses',
      name: '/other-expenses',
      component: ExpensesTransaction,
      meta: { requiresAuth: true },
   },

   // ? other income page show rout
   {
      path: '/other-income',
      name: '/other-income',
      component: IncomeTransaction,
      meta: { requiresAuth: true },
   },
]

export default AccountRoutes
