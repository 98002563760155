<template lang="">
   <div class="wrapper">
      <!-- Sidebar  -->
      <LayoutManu></LayoutManu>

      <!-- Page Content  -->
      <div id="content-page" class="content-page">
         <!-- Page Content  -->
         <LayoutNavbar></LayoutNavbar>

         <div class="container-fluid">
            <div class="row">
               <!-- Left Column -->
               <div class="col-sm-12">
                  <div class="iq-card">
                     <div class="iq-card border border-primary">
                        <div class="iq-card-body">
                           <form>
                              <form>
                                 <div class="row">
                                    <div class="col-md-1 mb-1">
                                       <label for="branch_cmb" class="">Branch</label>
                                    </div>
                                    <div class="col-md-5 mb-1">
                                       <Select2 class="js-example-basic-single font-weight-bold" id="branch_cmb" name="branch_cmb"></Select2>
                                    </div>

                                    <div class="col-md-1 mb-1">
                                       <label for="lab_test_cmb" class="">Lab Test</label>
                                    </div>
                                    <div class="col-md-5 mb-1">
                                       <Select2 class="js-example-basic-single font-weight-bold" id="lab_test_cmb" name="hospital_cmb"></Select2>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-1 mb-1">
                                       <label for="hospital_cmb" class="">Hospital</label>
                                    </div>
                                    <div class="col-md-5 mb-1">
                                       <Select2 class="js-example-basic-single font-weight-bold" id="hospital_cmb" name="hospital_cmb"></Select2>
                                    </div>
                                    <div class="col-md-1 mb-1">
                                       <label for="patient_cmb" class="">Patient</label>
                                    </div>
                                    <div class="col-md-5 mb-1">
                                       <Select2 class="js-example-basic-single font-weight-bold" id="patient_cmb" name="patient_cmb"></Select2>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-md-1 mb-1">
                                       <label for="start_date_dtmp" class="">From Date</label>
                                    </div>
                                    <div class="col-md-4 mb-1">
                                       <input
                                          type="date"
                                          class="form-control form-control-sm font-weight-bold"
                                          id="start_date_dtmp"
                                          :value="getDateWithOffset(-30)"
                                       />
                                    </div>
                                    <div class="col-md-1 mb-1">
                                       <label for="end_date_dtmp" class="">To Date</label>
                                    </div>
                                    <div class="col-md-4 mb-1">
                                       <input
                                          type="date"
                                          class="form-control form-control-sm font-weight-bold"
                                          id="end_date_dtmp"
                                          :value="getCurrentDate()"
                                       />
                                    </div>
                                    <div class="col-md-1 mb-1">
                                       <input class="btn btn-danger btn-sm btn-block" type="button" id="refresh_btn" value="Refresh" />
                                    </div>
                                 </div>
                              </form>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 col-lg-12">
                  <div class="iq-card">
                     <div class="iq-card-body">
                        <div class="table-responsive">
                           <table id="lab_all_transaction_table" class="table table-hover table-bordered display nowrap">
                              <thead>
                                 <tr>
                                    <th>Ref. ID</th>
                                    <th>Print</th>
                                    <th>Re-Upload</th>
                                    <th>Patient Name</th>
                                    <th>Test</th>
                                    <th>Price</th>
                                    <th>Hospital Fee</th>
                                    <th>Status</th>
                                    <th>Send Status</th>
                                    <th>Send Date</th>
                                    <th>Receive Status</th>
                                    <th>Receive Date</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Branch</th>
                                 </tr>
                              </thead>
                              <tbody> </tbody>
                              <tfoot>
                                 <tr>
                                    <th>Ref. ID</th>
                                    <th>Print</th>
                                    <th>Re-Upload</th>
                                    <th>Patient Name</th>
                                    <th>Test</th>
                                    <th>Price</th>
                                    <th>Hospital Fee</th>
                                    <th>Status</th>
                                    <th>Send Status</th>
                                    <th>Send Date</th>
                                    <th>Receive Status</th>
                                    <th>Receive Date</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Branch</th>
                                 </tr>
                              </tfoot>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal fade bd-example-modal-lg" id="fileUploadModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="fileUploadModalLabel">File Upload</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div class="modal-body">
                     <form id="uploadForm" enctype="multipart/form-data">
                        <div class="form-row">
                           <div class="col">
                              <label for="patient_name_tbx" class="font-weight-bold"> Patient Name <span class="text-danger">*</span> </label>
                              <input type="text" class="form-control form-control-sm" id="patient_name_tbx" readonly />
                           </div>
                        </div>
                        <div class="form-row">
                           <div class="col mt-1">
                              <label for="patient_age_tbx" class="font-weight-bold"> Patient Age <span class="text-danger">*</span> </label>
                              <input type="text" class="form-control form-control-sm" id="patient_age_tbx" readonly />
                           </div>
                           <div class="col mt-1">
                              <label for="patient_gender_tbx" class="font-weight-bold"> Patient Gender <span class="text-danger">*</span> </label>
                              <input type="text" class="form-control form-control-sm" id="patient_gender_tbx" readonly />
                           </div>
                           <div class="col mt-1">
                              <label for="collected_date_tbx" class="font-weight-bold"> Collected Date <span class="text-danger">*</span> </label>
                              <input type="text" class="form-control form-control-sm" id="collected_date_tbx" readonly />
                           </div>
                        </div>
                        <div class="form-row">
                           <div class="col mt-1">
                              <label for="test_name_tbx" class="font-weight-bold"> Test Name <span class="text-danger">*</span> </label>
                              <input type="text" class="form-control form-control-sm" id="test_name_tbx" readonly />
                           </div>
                        </div>
                        <div class="form-row">
                           <div class="col mt-1">
                              <label for="patient_name_tbx" class="font-weight-bold">
                                 Select Report To Upload <span class="text-danger">*</span>
                              </label>
                              <input type="file" class="form-control-file" id="file" name="file" accept=".pdf" />
                              <input type="hidden" class="form-control" id="folder_name_tbx" />
                              <input type="hidden" class="form-control" id="file_name_tbx" />
                           </div>
                        </div>
                     </form>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                     <input class="btn btn-success" type="submit" id="upload_btn" value="Upload And Complete Test Transfer" />
                  </div>
               </div>
            </div>
         </div>
         <LayoutFooterVue></LayoutFooterVue>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL, FILE_UPLOAD_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS
import Chart from 'chart.js/auto'

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'test-dash', ['active', 'main-active'])
      this.updateElementClasses('sub', 'test-dashboard-monthly', ['active'])

      $(document).ready(function () {
         // ? get all branch names
         get_branch_names_id()

         // ? get all lab test names and ids into select lab_test dropdown
         get_lab_test_names_id()

         // ? get all patient names and ids into select patient dropdown
         get_patient_names_id()

         // ? get all hospital names and ids into select hospital dropdown
         get_hospital_names_id()

         // ? refresh button click event
         $('#refresh_btn').on('click', () => {
            var branch_id = $('#branch_cmb').val()
            var lab_test_id = $('#lab_test_cmb').val()
            var hospital_id = $('#hospital_cmb').val()
            var patient_id = $('#patient_cmb').val()
            var start_date = $('#start_date_dtmp').val()
            var end_date = $('#end_date_dtmp').val()

            history_lab_test_transaction_load_data_table(branch_id, lab_test_id, hospital_id, patient_id, start_date, end_date)
         })

         // ? get all branch names
         function get_branch_names_id() {
            $.ajax({
               url: `${API_URL}branch/branch-name/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#branch_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // Add the option for "All Branches"
                     const allBranchesOption = new Option('All Branches', 'B')
                     selectDropdown.append(allBranchesOption)

                     // ? Append new options
                     data.forEach(branch => {
                        const option = new Option(branch.brach_name, branch.brach_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     selectDropdown.val(selectDropdown.find('option:eq(0)').val()).trigger('change')
                  }
               },
               error: function (error) {
                  console.error('Error fetching branch name:', error)
               },
            })
         }

         // ? get all lab test names and ids into select lab_test dropdown
         function get_lab_test_names_id() {
            $.ajax({
               url: `${API_URL}lab-test/lab-test-names/`, // corrected the URL concatenation
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `lab_id` and `lab_test_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#lab_test_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // Add the option for "All Lab Tests"
                     const allLabTestsOption = new Option('All Lab Tests', '-')
                     selectDropdown.append(allLabTestsOption)

                     // ? Append new options
                     data.forEach(lab_test => {
                        const option = new Option(lab_test.lab_test_name, lab_test.lab_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#lab_test_cmb').val(selectDropdown.find('option:eq(0)').val()).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching lab test names:', error)
               },
            })
         }

         // ? get all patient names and ids into select patient dropdown
         function get_patient_names_id() {
            $.ajax({
               url: `${API_URL}patient/patient-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `pat_id` and `show_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#patient_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // Add the option for "All Patients"
                     const allPatientsOption = new Option('All Patients', 'P')
                     selectDropdown.append(allPatientsOption)

                     // ? Append new options
                     data.forEach(patient => {
                        const option = new Option(patient.show_name, patient.pat_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#patient_cmb').val(selectDropdown.find('option:eq(0)').val()).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching patient names:', error)
               },
            })
         }

         // ? get all hospital names and ids into select hospital dropdown
         function get_hospital_names_id() {
            $.ajax({
               url: `${API_URL}hospital/hospital-names/`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  // ? Assuming the response is an array of objects with `hos_id` and `hos_name` properties
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#hospital_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // Add the option for "All Hospitals"
                     const allHospitalsOption = new Option('All Hospitals', 'H')
                     selectDropdown.append(allHospitalsOption)

                     // ? Append new options
                     data.forEach(hospital => {
                        const option = new Option(hospital.hos_name, hospital.hos_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#hospital_cmb').val(selectDropdown.find('option:eq(0)').val()).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching hospital names:', error)
               },
            })
         }

         // ? view lab test transaction to table model view
         function history_lab_test_transaction_load_data_table(branchId, labTestId, hospitalID, patientId, startDate, endDate) {
            // Update the selectedIds array with all data-ids
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url:
                     `${API_URL}account-dashboard/view-all-transaction-by-type-monthly-dashboard/` +
                     branchId +
                     `/` +
                     labTestId +
                     `/` +
                     hospitalID +
                     `/` +
                     patientId +
                     `/` +
                     startDate +
                     `/` +
                     endDate,
                  type: 'GET',
                  error: function (xhr, errorType, exception) {
                     console.log('Ajax error while fetching data:', exception)
                  },
               },
               columns: [
                  {
                     data: 'id',
                  },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        const firstCharacter = row.lab_tra_lab_test_id.charAt(0)

                        if (data === 'Y') {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-typ="' +
                              firstCharacter +
                              '" data-id="' +
                              row.id +
                              '" data-cus="' +
                              row.lab_tra_cus_id +
                              '"><i class="fa-solid fa-eye"></i></button>'
                           )
                        } else {
                           return (
                              '<button class="btn btn-warning btn-sm" id="view_btn" data-typ="' +
                              firstCharacter +
                              '" data-id="' +
                              row.id +
                              '"  disabled><i class="fa-sharp fa-solid fa-eye-slash"></i></button>'
                           )
                        }
                     },
                  },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        const firstCharacter = row.lab_tra_lab_test_id.charAt(0)

                        // ? You can customize the button or link here
                        if (data === 'Y' && firstCharacter === 'O') {
                           return (
                              '<button class="btn btn-success btn-sm" id="upload_btn" data-id="' +
                              row.id +
                              '" data-cusid="' +
                              row.lab_tra_cus_id +
                              '" ><i class="fa-solid fa-folder-arrow-up"></i></button>'
                           )
                        } else {
                           return (
                              '<button class="btn btn-success btn-sm" id="upload_btn" data-id="' +
                              row.id +
                              '" data-cusid="' +
                              row.lab_tra_cus_id +
                              '" disabled><i class="fa-solid fa-folder-arrow-up"></i></button>'
                           )
                        }
                     },
                  },
                  {
                     data: 'pat_name',
                  },
                  {
                     data: 'lab_test_name',
                  },
                  {
                     data: 'lab_tra_price',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_hospital_fee',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'lab_tra_complete_status',
                     render: function (data, type, row) {
                        if (data === 'Y') {
                           return '<span class="badge badge-success">Completed</span>'
                        } else if (data === 'R') {
                           return '<span class="badge" style="background-color: darkorange; color: white;">Need To Retest</span>'
                        } else if (data === 'A') {
                           return '<span class="badge badge-primary">To Be Approved</span>'
                        } else if (data === 'C') {
                           return '<span class="badge badge-danger">Canceled</span>'
                        } else {
                           return '<span class="badge badge-danger">Pending</span>'
                        }
                     },
                  },
                  {
                     data: 'lab_tra_lab_test_send',
                     render: function (data, type, row) {
                        if (data === 'N') {
                           return '<span class="badge badge-danger">Not Send Yet</span>'
                        } else if (data === 'Y') {
                           return '<span class="badge badge-success">Sent</span>'
                        } else {
                           return '<span class="badge badge-warning">Nothing to Send</span>'
                        }
                     },
                  },
                  { data: 'lab_tra_lab_test_send_time' },
                  {
                     data: 'lab_tra_lab_test_receive',
                     render: function (data, type, row) {
                        if (data === 'N') {
                           return '<span class="badge badge-danger">Not Receive Yet</span>'
                        } else if (data === 'Y') {
                           return '<span class="badge badge-success">Received</span>'
                        } else {
                           return '<span class="badge badge-warning">Nothing to Receive</span>'
                        }
                     },
                  },
                  { data: 'lab_tra_lab_test_receive_time' },
                  { data: 'lab_tra_date' },
                  { data: 'lab_tra_time' },
                  { data: 'brach_name' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
               order: [[0, 'desc']],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#lab_all_transaction_table').DataTable(dataTableOptions)
         }

         // ? data table upload report and receive test click event handler
         $('#lab_all_transaction_table').on('click', '#upload_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var reference_id = $(this).data('id')
            var patient_id = $(this).data('cusid')

            // Create folder name based on patient_id and reference_id
            var folderName = patient_id

            var formData = new FormData()
            formData.append('fun_folder_name', folderName)

            $.ajax({
               // Change this to 'GET' or 'POST' based on your server's requirements
               url: FILE_UPLOAD_URL + 'create-folder.php',
               type: 'POST',
               data: formData,
               contentType: false,
               processData: false,
               success: function (response) {
                  console.log(response)
                  // Handle the success response here
               },
               error: function (xhr, status, error) {
                  console.error(xhr.responseText)
                  // Handle the error response here
               },
            })

            $.ajax({
               url: `${API_URL}` + 'lab-test-transaction/lab-test-transaction-details-by-id/' + reference_id, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#folder_name_tbx').val(patient_id)
                  $('#file_name_tbx').val(reference_id)
                  $('#patient_name_tbx').val(data['pat_name'])
                  $('#patient_age_tbx').val(data['pat_age'])
                  if (data['pat_gender'] == 'M') {
                     $('#patient_gender_tbx').val('Male')
                  } else {
                     $('#patient_gender_tbx').val('Female')
                  }
                  $('#collected_date_tbx').val(data['lab_tra_collect_datetime'])
                  $('#test_name_tbx').val(data['lab_test_name'])
               },
            })

            const modalElement = document.getElementById('fileUploadModal')
            const modal = new bootstrap.Modal(modalElement)
            modal.show()
         })

         // ? re print after click print button
         $('#lab_all_transaction_table').on('click', '#view_btn', function () {
            var row_id = $(this).data('id')
            var row_type = $(this).data('typ')
            var cus_id = $(this).data('cus')

            if (row_type == 'O') {
               var url = FILE_UPLOAD_URL + 'view-pdf.php?file_name=' + cus_id + '/' + row_id + '.pdf'
               window.open(url, '_blank')
            } else {
               var url = FILE_UPLOAD_URL + 'lab-report-without-letter-head.php?ref_id=' + row_id
               window.open(url, '_blank')
            }
         })

         $('#upload_btn').on('click', function () {
            var file_name = $('#file_name_tbx').val()
            var folder_name = $('#folder_name_tbx').val()
            var fileInput = $('#file')[0]

            // Check if a file is selected
            if (fileInput.files.length === 0) {
               Swal.fire({
                  icon: 'error',
                  title: 'No File Selected',
                  text: 'Please choose a PDF file to upload.',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
               return
            }

            var formData = new FormData($('#uploadForm')[0])
            formData.append('file_name', file_name)
            formData.append('folder_name', folder_name)

            // Display a progress bar
            var progressBar = Swal.fire({
               title: 'Uploading...',
               html: 'Please wait...',
               allowOutsideClick: false,
            })

            $.ajax({
               url: FILE_UPLOAD_URL + 'upload.php',
               type: 'POST',
               data: formData,
               contentType: false,
               processData: false,
               success: function (response) {
                  // Display success message
                  Swal.fire({
                     icon: 'success',
                     title: 'Completed',
                     text: 'Report uploaded successfully, and transfer received updated.',
                     timer: 2000,
                     showConfirmButton: false,
                  })

                  // Clear the file input
                  $('#file').val('')
               },
               error: function () {
                  // Close the progress bar
                  progressBar.close()

                  // Display error message
                  Swal.fire({
                     icon: 'error',
                     title: 'Error',
                     text: 'There was an error uploading the file.',
                     timer: 2000,
                     showConfirmButton: false,
                  })
               },
            })
         })

         // ? brach select change event
         $('#branch_cmb').on('change', function () {})
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
