<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage GL Account</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-6 mb-1">
                                 <label class="">Select Account Type <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="acc_type_cmb" name="acc_type_cmb"></Select2>
                              </div>
                              <div class="col-md-6 mb-1">
                                 <label class="">Select Sub Account Type <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="sub_acc_type_cmb" name="sub_acc_type_cmb"></Select2>
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-6 mb-1">
                                 <label class="">Account Name <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="acc_name_tbx" />
                              </div>
                              <div class="col-md-3 mt-4">
                                 <input class="btn btn-success" type="submit" id="submit_btn" value="Submit" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table id="gl_acc_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Account Name</th>
                                       <th>Type</th>
                                       <th>Sub Type</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Account Name</th>
                                       <th>Type</th>
                                       <th>Sub Type</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'account', ['active', 'main-active'])
      this.updateElementClasses('sub', 'create-gl-acc', ['active'])

      $(document).ready(function () {
         // ? Function to get GL account main types and populate the dropdown
         getGLAccountMainTypes()

         // ? Function to load GL accounts data into DataTable
         getAllGLAccountsToTableView_load_data_table()

         // ? Function to get GL account main types and populate the dropdown
         function getGLAccountMainTypes() {
            $.ajax({
               url: `${API_URL}account/gl-account-main-types`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#acc_type_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(type => {
                        const option = new Option(type.gltp_mintyp_eng, type.gltp_mtyp_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#acc_type_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching GL account types:', error)
               },
            })
         }

         // ? get gl account sub types by main type selected
         $('#acc_type_cmb').on('change', function () {
            // ? Function to get GL account types by main type ID and populate the dropdown
            var main_type_id = $(this).val()

            $.ajax({
               url: `${API_URL}account/gl-account-sub-types/` + main_type_id,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#sub_acc_type_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(type => {
                        const option = new Option(type.gltp_typ_eng, type.gltp_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#sub_acc_type_cmb').val(null).trigger('change.select2')

                     setTimeout(function () {
                        $('#sub_acc_type_cmb').select2('open')
                     }, 100)
                  }
               },
               error: function (error) {
                  console.error('Error fetching GL account subtypes:', error)
               },
            })
         })

         // ? gl account sub type select change event
         $('#sub_acc_type_cmb').on('change', function () {
            setTimeout(function () {
               $('#acc_name_tbx').focus().select()
            }, 0)
         })

         // ? Function to load GL accounts data into DataTable
         function getAllGLAccountsToTableView_load_data_table() {
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}account/gl-account-table-view`,
                  type: 'GET',
               },
               columns: [{ data: 'glacc_id' }, { data: 'glacc_name_eng' }, { data: 'gltp_mintyp_eng' }, { data: 'gltp_subtyp_eng' }],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#gl_acc_table').DataTable(dataTableOptions)
         }

         // ? create gl account submit button click event
         $('#submit_btn').on('click', () => {
            var main_type = $('#acc_type_cmb').val()
            var sub_type = $('#sub_acc_type_cmb').val()
            var account_name = $('#acc_name_tbx').val()

            if (!main_type) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select an account type!',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (!sub_type) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select an account sub type!',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (!account_name) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please enter the account name!',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else {
               $('#submit_btn').prop('disabled', true).val('wait...')
               const requestData = {
                  gl_typid: sub_type,
                  glacc_name_eng: account_name,
               }

               // ? Make the API call using jQuery AJAX
               $.ajax({
                  type: 'POST',
                  url: `${API_URL}account/create-gl-account`,
                  contentType: 'application/json',
                  data: JSON.stringify(requestData),
                  success: function (response) {
                     // ? Display SweetAlert for success
                     $('#submit_btn').prop('disabled', false).val('Submit')

                     Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'GL Account created successfully',
                        timer: 2000,
                        showConfirmButton: false,
                        timerProgressBar: true,
                     })

                     // ? Function to load GL accounts data into DataTable
                     getAllGLAccountsToTableView_load_data_table()
                  },
                  error: function (error) {
                     // ? Display SweetAlert for error
                     Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.responseText,
                     })

                     $('#submit_btn').prop('disabled', false).val('Submit')
                  },
               })
            }
         })

         // Trigger action on "Enter" key press in acc_name_tbx
         $('#acc_name_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               document.getElementById('submit_btn').click()
            }
         })
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>
<style lang=""></style>
