import DoctorManagement from '../pages/doctors/DoctorManagement.vue'
import ViewAllDoctors from '../pages/doctors/ViewAllDoctors.vue'
import DoctorPayments from '../pages/doctors/DoctorPayments.vue'

const DoctorRoutes = [
   // ? Doctor Management root page show
   {
      path: '/doctor-management',
      name: 'doctor-management',
      component: DoctorManagement,
      meta: { requiresAuth: true },
   },

   // ? All Doctors payments View root page show
   {
      path: '/doctor-payments',
      name: 'doctor-payments',
      component: DoctorPayments,
      meta: { requiresAuth: true },
   },

   // ? All Doctors View root page show
   {
      path: '/view-all-doctors',
      name: 'view-all-doctors',
      component: ViewAllDoctors,
      meta: { requiresAuth: true },
   },
]

export default DoctorRoutes
