<template lang="">
   <div class="iq-sidebar">
      <div class="iq-sidebar-logo d-flex justify-content-between">
         <router-link to="/home">
            <img src="Assets/images/logo.png" class="img-fluid" alt="" />
            <span><img src="Assets/images/logo-text.png" class="img-fluid" alt="" /></span>
         </router-link>
         <div class="iq-menu-bt-sidebar">
            <div class="iq-menu-bt align-self-center">
               <div class="wrapper-menu">
                  <div class="main-circle">
                     <i class="ri-more-fill"></i>
                  </div>
                  <div class="hover-circle">
                     <i class="ri-more-2-fill"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div id="sidebar-scrollbar">
         <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="iq-menu">
               <li class="iq-menu-title">
                  <i class="ri-subtract-line"></i>
                  <span>Dashboard</span>
               </li>
               <li id="sub" data-id="home" class="">
                  <router-link class="iq-waves-effect" to="/home">
                     <i class="fa-sharp fa-solid fa-file-chart-pie"></i>
                     <span>Home</span>
                  </router-link>
               </li>
               <li id="main" data-id="acc-dash" class="">
                  <a href="#acc-dash-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-sharp fa-solid fa-file-chart-column"></i>
                     <span>Account Dashboard</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="acc-dash-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="account-dashboard-monthly" class="">
                        <router-link class="iq-waves-effect" to="/account-dashboard-monthly">
                           <i class="fa-solid fa-file-invoice-dollar"></i> Account Dashboard - Monthly
                        </router-link>
                     </li>
                  </ul>
                  <ul id="acc-dash-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="account-report-monthly" class="">
                        <router-link class="iq-waves-effect" to="/account-report-monthly">
                           <i class="fa-solid fa-file-invoice-dollar"></i> Account Report - Monthly
                        </router-link>
                     </li>
                  </ul>
                  <ul id="acc-dash-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="account-report-daily" class="">
                        <router-link class="iq-waves-effect" to="/account-report-daily">
                           <i class="fa-solid fa-file-invoice-dollar"></i> Account Report - Daily
                        </router-link>
                     </li>
                  </ul>
               </li>
               <li id="main" data-id="test-dash" class="">
                  <a href="#test-dash-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-flask-gear"></i>
                     <span>Lab Test Dashboard</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="test-dash-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="test-dashboard-monthly" class="">
                        <router-link class="iq-waves-effect" to="/all-lab-test-report-dashboard">
                           <i class="fa-solid fa-flask-gear"></i> All Lab Test Reports
                        </router-link>
                     </li>
                  </ul>
               </li>
               <li class="iq-menu-title">
                  <i class="ri-subtract-line"></i>
                  <span>Main</span>
               </li>
               <li id="main" data-id="doctor" class="">
                  <a href="#doctor-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-user-doctor"></i>
                     <span>Doctors</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="doctor-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-doctor" class="">
                        <router-link class="iq-waves-effect" to="/doctor-management">
                           <i class="fa-solid fa-user-doctor"></i> Manage Doctor
                        </router-link>
                     </li>
                     <li id="sub" data-id="doctor-pay" class="">
                        <router-link class="iq-waves-effect" to="/doctor-payments">
                           <i class="fa-solid fa-hospital-user"></i>Doctor Payments
                        </router-link>
                     </li>
                     <!-- <li id="sub" data-id="all-doctor" class="">
                        <router-link class="iq-waves-effect" to="/view-all-doctors">
                           <i class="fa-solid fa-hospital-user"></i>All Doctors
                        </router-link>
                     </li> -->
                  </ul>
               </li>
               <li id="main" data-id="hospital" class="">
                  <a href="#hospital-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-sharp fa-solid fa-house-medical"></i>
                     <span>Hospitals</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="hospital-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-hospital" class="">
                        <router-link class="iq-waves-effect" to="/hospital-management">
                           <i class="fa-sharp fa-solid fa-house-medical"></i> Add Hospital
                        </router-link>
                     </li>
                     <li id="sub" data-id="transfer-test-hospital" class="">
                        <router-link class="iq-waves-effect" to="/hospital-lab-test-transfer">
                           <i class="fa-solid fa-vial-circle-check"></i> Lab Test Transfer
                        </router-link>
                     </li>
                     <!-- <li id="sub" data-id="all-hospital" class="">
                        <router-link class="iq-waves-effect" to="/view-all-hospitals"> <i class="fa-solid fa-hospital"></i>All Hospital </router-link>
                     </li> -->
                  </ul>
               </li>
               <li id="main" data-id="test" class="">
                  <a href="#test-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-flask-vial"></i>
                     <span>Lab Tests</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="test-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-test" class="">
                        <router-link class="iq-waves-effect" to="/lab-management"> <i class="fa-solid fa-flask-vial"></i> Add Lab Test </router-link>
                     </li>
                     <li id="sub" data-id="change-price-test" class="">
                        <router-link class="iq-waves-effect" to="/lab-tests-price-change">
                           <i class="fa-solid fa-money-check-dollar-pen"></i>Lab Test Price Change
                        </router-link>
                     </li>
                     <li id="sub" data-id="create-worksheet-test" class="">
                        <router-link class="iq-waves-effect" to="/lab-tests-worksheet-create">
                           <i class="fa-solid fa-file-spreadsheet"></i> Create Lab Test Worksheet
                        </router-link>
                     </li>
                     <!-- <li id="sub" data-id="all-test" class="">
                        <router-link class="iq-waves-effect" to="/view-all-lab-tests">
                           <i class="fa-solid fa-hospital"></i>All Lab Tests
                        </router-link>
                     </li> -->
                  </ul>
               </li>
               <li id="main" data-id="patient" class="">
                  <a href="#patient-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-hospital-user"></i>
                     <span>Patient</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="patient-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-patient" class="">
                        <router-link class="iq-waves-effect" to="/patient-management">
                           <i class="fa-solid fa-hospital-user"></i> Add Patient
                        </router-link>
                     </li>
                     <!-- <li id="sub" data-id="all-patient" class="">
                        <router-link class="iq-waves-effect" to="/view-all-patient"> <i class="fa-solid fa-hospital"></i>All Patient </router-link>
                     </li> -->
                  </ul>
               </li>
               <li id="main" data-id="payment" class="">
                  <a href="#payment-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-file-invoice-dollar"></i>
                     <span>Payments</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="payment-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="bill-reprint" class="">
                        <router-link class="iq-waves-effect" to="/reprint"> <i class="fa-solid fa-print"></i> Bill Reprint </router-link>
                     </li>
                     <li id="sub" data-id="patient-due-payments" class="">
                        <router-link class="iq-waves-effect" to="/patient-due-payments">
                           <i class="fa-sharp fa-solid fa-money-check-dollar-pen"></i> Patient Due Payments
                        </router-link>
                     </li>

                     <!-- <li id="sub" data-id="all-patient" class="">
                        <router-link class="iq-waves-effect" to="/view-all-patient"> <i class="fa-solid fa-hospital"></i>All Patient </router-link>
                     </li> -->
                  </ul>
               </li>
               <li id="main" data-id="account" class="">
                  <a href="#account-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-money-check-dollar-pen"></i>
                     <span>Accounts</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="account-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="create-gl-acc" class="">
                        <router-link class="iq-waves-effect" to="/gl-account-management">
                           <i class="fa-brands fa-bandcamp"></i> Create GL Account
                        </router-link>
                     </li>
                     <li id="sub" data-id="other-income" class="">
                        <router-link class="iq-waves-effect" to="/other-income">
                           <i class="fa-solid fa-money-bill-transfer"></i> Other Incomes
                        </router-link>
                     </li>
                     <li id="sub" data-id="other-expenses" class="">
                        <router-link class="iq-waves-effect" to="/other-expenses">
                           <i class="fa-solid fa-money-bill-transfer"></i> Other Expenses
                        </router-link>
                     </li>
                  </ul>
               </li>
               <li id="main" data-id="correction" class="">
                  <a href="#correction-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-square-xmark"></i>
                     <span>Correction</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="correction-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-test-correction" class="">
                        <router-link class="iq-waves-effect" to="/add-correction">
                           <i class="fa-solid fa-hexagon-xmark"></i>Test Transaction Correction
                        </router-link>
                     </li>
                  </ul>
               </li>
               <li id="main" data-id="result" class="">
                  <a href="#result-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-flask-round-potion"></i>
                     <span>Test Results</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="result-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-test-results" class="">
                        <router-link class="iq-waves-effect" to="/update-test-results">
                           <i class="fa-regular fa-calendar-lines-pen"></i> Update Test Results
                        </router-link>
                     </li>
                  </ul>
               </li>
               <li id="main" data-id="sms" class="">
                  <a href="#sms-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-message-sms"></i>
                     <span>SMS</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="sms-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="send-customer-sms" class="">
                        <router-link class="iq-waves-effect" to="/send-patient-sms">
                           <i class="fa-solid fa-message-smile"></i> Send Patient Logins
                        </router-link>
                     </li>
                     <li id="sub" data-id="send-test-results-sms" class="">
                        <router-link class="iq-waves-effect" to="/send-patient-lab-test-results-sms">
                           <i class="fa-solid fa-message-pen"></i> Send Test Results
                        </router-link>
                     </li>
                     <li id="sub" data-id="sms-balance-history" class="">
                        <router-link class="iq-waves-effect" to="/sms-balance-history">
                           <i class="fa-solid fa-message-dollar"></i> SMS Balance & History
                        </router-link>
                     </li>
                  </ul>
               </li>
               <li class="iq-menu-title">
                  <i class="ri-subtract-line"></i>
                  <span>Transaction</span>
               </li>
               <li class="">
                  <router-link class="iq-waves-effect" to="/lab-test-transaction" target="_blank">
                     <i class="fa-solid fa-flask-gear"></i> Lab Test Transactions
                  </router-link>
               </li>
               <li class="iq-menu-title">
                  <i class="ri-subtract-line"></i>
                  <span>Employee</span>
               </li>
               <li id="main" data-id="user" class="">
                  <a href="#user-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-user"></i>
                     <span>user</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="user-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-user" class="">
                        <router-link class="iq-waves-effect" to="/user-management"> <i class="fa-solid fa-user"></i> Add User </router-link>
                     </li>
                  </ul>
               </li>
               <li id="main" data-id="branch" class="">
                  <a href="#branch-info" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="true">
                     <i class="fa-solid fa-farm"></i>
                     <span>Branch</span>
                     <i class="fa-sharp fa-solid fa-circle-chevron-down iq-arrow-right"></i>
                  </a>
                  <ul id="branch-info" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                     <li id="sub" data-id="add-branch" class="">
                        <router-link class="iq-waves-effect" to="/branch-management"> <i class="fa-solid fa-farm"></i> Add branch </router-link>
                     </li>
                  </ul>
               </li>
            </ul>
         </nav>
         <div class="p-3"></div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'LayoutMenu',
   mounted() {
      //Get all elements with id="sub" and add click event listener
      const subElements = document.querySelectorAll('#sub')
      subElements.forEach(subElement => {
         subElement.addEventListener('click', () => {
            // Reload the page when a sub element is clicked
            window.location.reload()
         })
      })
   },
}
</script>

<style lang=""></style>
