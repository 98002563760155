<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Patients Login Details - SMS</h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table id="patient_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Name</th>
                                       <th>Status</th>
                                       <th>Send SMS</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Birthday</th>
                                       <th>Age</th>
                                       <th>Gender</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Name</th>
                                       <th>Status</th>
                                       <th>Send SMS</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Birthday</th>
                                       <th>Age</th>
                                       <th>Gender</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5'

export default {
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'sms', ['active', 'main-active'])
      this.updateElementClasses('sub', 'send-customer-sms', ['active'])

      $(document).ready(function () {
         // ? view data table
         load_data_table()

         // ? view data table
         function load_data_table() {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}patient/`,
                  type: 'GET',
               },
               columns: [
                  { data: 'pat_id' },
                  { data: 'pat_name' },
                  {
                     data: 'pat_pass_send_status',
                     render: function (data, type, row) {
                        if (data === 'Y') {
                           return '<span class="badge badge-success">Sent</span>'
                        } else if (data === 'N') {
                           return '<span class="badge badge-danger">Not Yet</span>'
                        } else {
                           // Handle other cases if needed
                           return data
                        }
                     },
                  },
                  {
                     render: function (data, type, row) {
                        // ? You can customize the button or link here
                        return (
                           '<button class="btn btn-warning btn-sm" id="sent_btn" data-id="' +
                           row.pat_id +
                           '" ><i class="fa-solid fa-paper-plane-top"></i></button>'
                        )
                     },
                  },
                  { data: 'pat_city' },
                  { data: 'pat_contact' },
                  { data: 'pat_birthday' },
                  {
                     data: 'pat_age',
                  },
                  {
                     data: 'pat_gender',
                     render: function (data, type, row) {
                        if (data === 'F') {
                           return '<i class="fa-sharp fa-solid fa-person-dress text-success mr-2"></i><span class="badge badge-success">Female</span>'
                        } else if (data === 'M') {
                           return '<i class="fa-sharp fa-solid fa-person text-danger mr-2"></i><span class="badge badge-danger">Male</span>'
                        } else {
                           // Handle other cases if needed
                           return data
                        }
                     },
                  },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // ? Initialize DataTable
            $('#patient_data_table').DataTable(dataTableOptions)
         }

         // sms send button click event
         // ? data table update button click event handler
         $('#patient_data_table').on('click', '#sent_btn', function () {
            // ? Get the data-id attribute of the clicked button
            var patientId = $(this).data('id')
            var pat_name = ''
            var pat_password = ''
            var contact = ''
            var message = ''

            $.ajax({
               url: `${API_URL}` + 'patient/' + patientId, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  pat_name = data['pat_name']
                  pat_password = data['pat_password']
                  contact = data['pat_contact']
                  message =
                     'Dear ' +
                     pat_name +
                     ',\n\n' +
                     'Your account has been created.\n\n' +
                     'User ID: ' +
                     patientId +
                     '\n' +
                     'Password: ' +
                     pat_password +
                     '\n\n' +
                     'Please change your password after the first login.\nFor assistance, contact us at 037-2246363.\n\n' +
                     'Thank you!'

                  var data_pack = {
                     contact_no: contact,
                     sms_text: message,
                  }

                  $.ajax({
                     url: `${API_URL}` + 'sms-api/send-sms',
                     type: 'POST',
                     contentType: 'application/json',
                     data: JSON.stringify(data_pack),
                     success: function (response) {
                        var data_pack2 = {
                           trSmsType: 'S',
                           trSmsDebit: response.trSmsDebit,
                           trSmsCredit: '0',
                           trSmsDescription: 'Patient Login Credentials Send',
                           trSmsSms: message,
                           trContact: contact,
                        }

                        $.ajax({
                           url: `${API_URL}` + 'sms-api/sms-balance-transaction-data-insert/',
                           type: 'POST',
                           contentType: 'application/json',
                           data: JSON.stringify(data_pack2),
                           success: function (response) {
                              $.ajax({
                                 url: `${API_URL}` + 'sms-api/sms-balance-update-sp-call/',
                                 type: 'POST',
                                 contentType: 'application/json',
                                 success: function (response) {
                                    // ? Show success message
                                    $.ajax({
                                       url: `${API_URL}` + 'patient/logins-sms-send-status-update/' + patientId,
                                       type: 'PUT',
                                       contentType: 'application/json',
                                       success: function (response) {
                                          load_data_table()

                                          // ? Show success message
                                          Swal.fire({
                                             icon: 'success',
                                             title: 'SMS sent successfully!',
                                             text: 'well done..',
                                             timer: 2000,
                                             showConfirmButton: true,
                                             allowOutsideClick: true,
                                          })
                                       },
                                       error: function (xhr, status, error) {
                                          Swal.fire({
                                             title: 'Failed to send SMS',
                                             text: xhr.responseText,
                                             icon: 'error',
                                             timer: 2000,
                                             showConfirmButton: true,
                                             allowOutsideClick: true,
                                          })
                                       },
                                    })
                                 },
                                 error: function (xhr, status, error) {
                                    Swal.fire({
                                       title: 'Failed to send SMS',
                                       text: xhr.responseText,
                                       icon: 'error',
                                       timer: 2000,
                                       showConfirmButton: true,
                                       allowOutsideClick: true,
                                    })
                                 },
                              })
                           },
                           error: function (xhr, status, error) {
                              Swal.fire({
                                 title: 'Failed to send SMS',
                                 text: xhr.responseText,
                                 icon: 'error',
                                 timer: 2000,
                                 showConfirmButton: true,
                                 allowOutsideClick: true,
                              })
                           },
                        })
                     },
                     error: function (xhr, status, error) {
                        Swal.fire({
                           title: 'Failed to send SMS',
                           text: xhr.responseText,
                           icon: 'error',
                           timer: 2000,
                           showConfirmButton: true,
                           allowOutsideClick: true,
                        })
                     },
                  })
               },
            })
         })
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>

<style></style>
