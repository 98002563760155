<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <!-- Left Column -->
                  <div class="col-md-6">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Other Expenses</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="form-row">
                              <div class="col-md-12 mb-1">
                                 <label class="">Select Account <span class="text-danger">*</span> </label>
                                 <Select2 class="js-example-basic-single font-weight-bold" id="acc_cmb" name="acc_cmb"></Select2>
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-6 mb-1">
                                 <label class="">Cash Payment <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="cash_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                              <div class="col-md-6 mb-1">
                                 <label class="">Bank Payment <span class="text-danger">*</span> </label>
                                 <input
                                    type="number"
                                    class="form-control form-control-sm text-right font-weight-bold"
                                    id="bank_tbx"
                                    placeholder="0.00"
                                 />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-6 mb-1">
                                 <label class="">Cheque No </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="cheque_no_tbx" />
                              </div>
                              <div class="col-md-6 mb-1">
                                 <label class="">Cheque Post-date </label>
                                 <input
                                    type="date"
                                    class="form-control form-control-sm font-weight-bold"
                                    id="cheque_post_date_pkr"
                                    :value="getCurrentDate()"
                                 />
                              </div>
                           </div>
                           <div class="form-row">
                              <div class="col-md-10 mb-1">
                                 <label class="">Description <span class="text-danger">*</span> </label>
                                 <input type="text" class="form-control form-control-sm font-weight-bold" id="description_tbx" />
                              </div>
                              <div class="col-md-2 mt-4">
                                 <input class="btn btn-success" type="submit" id="submit_btn" value="Submit" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <!-- Right Column -->
                  <div class="col-md-6">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Summary</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div class="row">
                              <div class="col-md-6 col-lg-6">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div class="iq-card-body iq-bg-primary rounded">
                                       <h5 class="text-center text-white">Debit Balance</h5>
                                       <h2 class="mb-0 text-center"><span class="text-white" id="debit_balance_lbl">0.00</span></h2>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-6 col-lg-6">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                                    <div class="iq-card-body iq-bg-primary rounded">
                                       <h5 class="text-center text-white">Credit Balance</h5>
                                       <h2 class="mb-0 text-center"><span class="text-white" id="credit_balance_lbl">0.00</span></h2>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="row">
                              <div class="col-md-12 col-lg-12">
                                 <div class="iq-card iq-card-block iq-card-stretch iq-card-height mb-1 pb-2">
                                    <div class="iq-card-body iq-bg-primary rounded">
                                       <h5 class="text-center text-white">Balance</h5>
                                       <h2 class="mb-0 text-center"><span class="text-white" id="balance_lbl">0.00</span></h2>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-body">
                           <div class="table-responsive">
                              <table id="transaction_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>Transaction</th>
                                       <th>Account Name</th>
                                       <th>Debit</th>
                                       <th>Credit</th>
                                       <th>Description</th>
                                       <th>Date Time</th>
                                       <th>Branch</th>
                                       <th>User</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>Transaction</th>
                                       <th>Account Name</th>
                                       <th>Debit</th>
                                       <th>Credit</th>
                                       <th>Description</th>
                                       <th>Date Time</th>
                                       <th>Branch</th>
                                       <th>User</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>

<script>
import Select2 from 'vue3-select2-component'
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'
import { formatNumberWithCommas } from '../../config/number-converter'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'account', ['active', 'main-active'])
      this.updateElementClasses('sub', 'other-expenses', ['active'])

      $(document).ready(function () {
         // ? Function to call the getAllGLAccountByType API using your code pattern
         getAllGLAccountByType(2)

         // ? clear input data
         function clear_data() {
            $('#debit_balance_lbl').text('0.00')
            $('#credit_balance_lbl').text('0.00')
            $('#balance_lbl').text('0.00')

            $('#cash_tbx').val('0.00')
            $('#bank_tbx').val('0.00')

            $('#cheque_no_tbx').val('')
            $('#description_tbx').val('')
         }

         // ? Function to call the getAllGLAccountByType API using your code pattern
         function getAllGLAccountByType(typeId) {
            $.ajax({
               url: `${API_URL}account/all-gl-account/${typeId}`,
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  if (data && Array.isArray(data)) {
                     const selectDropdown = $('#acc_cmb')

                     // ? Clear existing options
                     selectDropdown.empty()

                     // ? Append new options
                     data.forEach(type => {
                        const option = new Option(type.glacc_name_eng, type.glacc_id)
                        selectDropdown.append(option)
                     })

                     // ? Initialize or refresh the Select2 dropdown
                     selectDropdown.select2()

                     $('#acc_cmb').val(null).trigger('change.select2')
                  }
               },
               error: function (error) {
                  console.error('Error fetching GL account types:', error)
               },
            })
         }

         // ? get gl account summery data
         function get_gl_account_summery_data(gl_acc) {
            $.ajax({
               url: `${API_URL}` + 'account/gl-account-summery-balance-by-id/' + gl_acc, // replace with the actual API endpoint
               method: 'GET',
               dataType: 'json',
               success: function (data) {
                  $('#debit_balance_lbl').text(formatNumberWithCommas(data['totalDebit']))
                  $('#credit_balance_lbl').text(formatNumberWithCommas(data['totalCredit']))
                  $('#balance_lbl').text(formatNumberWithCommas(data['expense_balance']))
               },
            })
         }

         // ? account select change event
         $('#acc_cmb').on('change', function () {
            // ? clear input data
            clear_data()

            // ? Your code for Select Patient select2 change event
            var acc_id = $(this).val()
            load_data_table(acc_id)
            get_gl_account_summery_data(acc_id)
            setTimeout(function () {
               $('#cash_tbx').focus().select()
            }, 0)
         })

         // ? Trigger action on "Enter" key press in cash_tbx
         $('#cash_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#bank_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in bank_tbx
         $('#bank_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#cheque_no_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_no_tbx
         $('#cheque_no_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               $('#cheque_post_date_pkr').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_post_date_pkr
         $('#cheque_post_date_pkr').on('keyup', function (event) {
            if (event.which === 13) {
               $('#description_tbx').focus().select()
            }
         })

         // ? Trigger action on "Enter" key press in cheque_post_date_pkr
         $('#description_tbx').on('keyup', function (event) {
            if (event.which === 13) {
               document.getElementById('submit_btn').click()
            }
         })

         // ? submit button click event
         $('#submit_btn').on('click', () => {
            var account_code = $('#acc_cmb').val()
            var cashValue = parseFloat($('#cash_tbx').val()) || 0
            var bankValue = parseFloat($('#bank_tbx').val()) || 0
            var cheque_no = $('#cheque_no_tbx').val()
            var cheque_post_date = $('#cheque_post_date_pkr').val()
            var description = $('#description_tbx').val()
            // Calculate total and assign it to the readonly input
            var totalPayment = cashValue + bankValue

            var requestData = {
               gl_tra_branch: window.globalData.branchId,
               amount: totalPayment,
               cash_bal: cashValue,
               bank_bal: bankValue,
               post_date: cheque_post_date,
               cheque_no: cheque_no,
               description: description,
               user_id: window.globalData.id,
               gl_tra_code: account_code,
            }

            if (!account_code) {
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please select an account!',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
               })
            } else if (totalPayment === 0) {
               // Display SweetAlert for entering Cash or Bank value
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Both cash and bank payments cannot be zero!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else if (!description) {
               // Display SweetAlert for entering a description
               Swal.fire({
                  icon: 'warning',
                  title: 'Oops!',
                  text: 'Please enter a description!',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
               })
            } else {
               if (bankValue > 0) {
                  if (cheque_no === '') {
                     Swal.fire({
                        icon: 'warning',
                        title: 'Oops!',
                        text: 'Cheque No can not be null!',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                     })
                  } else if (!cheque_post_date) {
                     Swal.fire({
                        icon: 'warning',
                        title: 'Oops!',
                        text: 'Cheque post-date can not be null!',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                     })
                  } else {
                     $('#submit_btn').prop('disabled', true).val('wait...')
                     // Make the API call using jQuery AJAX
                     $.ajax({
                        type: 'POST',
                        url: `${API_URL}account-transaction/other-expense-transaction-insert/`, // Replace with your actual API endpoint
                        contentType: 'application/json',
                        data: JSON.stringify(requestData),
                        success: function (response) {
                           $('#submit_btn').prop('disabled', false).val('Submit')
                           // Display SweetAlert for success
                           Swal.fire({
                              icon: 'success',
                              title: 'Success',
                              text: 'Other Expense Transaction successfully added.',
                              timer: 2000,
                              showConfirmButton: false,
                              timerProgressBar: true,
                           })

                           // ? load data to table
                           load_data_table(account_code)
                           get_gl_account_summery_data(account_code)
                           clear_data()
                           // You can perform additional actions after a successful API call if needed
                        },
                        error: function (error) {
                           $('#submit_btn').prop('disabled', false).val('Submit')
                           // Display SweetAlert for error
                           Swal.fire({
                              icon: 'error',
                              title: 'Error',
                              text: error.responseText,
                           })
                        },
                     })
                  }
               } else {
                  $('#submit_btn').prop('disabled', true).val('wait...')

                  $.ajax({
                     type: 'POST',
                     url: `${API_URL}account-transaction/other-expense-transaction-insert/`, // Replace with your actual API endpoint
                     contentType: 'application/json',
                     data: JSON.stringify(requestData),
                     success: function (response) {
                        $('#submit_btn').prop('disabled', false).val('Submit')
                        // Display SweetAlert for success
                        Swal.fire({
                           icon: 'success',
                           title: 'Success',
                           text: 'Other Expense Transaction successfully added.',
                           timer: 2000,
                           showConfirmButton: false,
                           timerProgressBar: true,
                        })

                        // ? load data to table
                        load_data_table(account_code)
                        get_gl_account_summery_data(account_code)
                        clear_data()
                        // You can perform additional actions after a successful API call if needed
                     },
                     error: function (error) {
                        $('#submit_btn').prop('disabled', false).val('Submit')
                        // Display SweetAlert for error
                        Swal.fire({
                           icon: 'error',
                           title: 'Error',
                           text: error.responseText,
                        })
                     },
                  })
               }
            }
         })

         // ? load data to table
         function load_data_table(gl_acc_code) {
            // ? DataTable options
            const dataTableOptions = {
               destroy: true,
               processing: true,
               serverSide: true,
               ajax: {
                  url: `${API_URL}account/all-gl-transaction-by-account-table-view/` + gl_acc_code,
                  type: 'GET',
               },
               columns: [
                  { data: 'gl_tra_id' },
                  { data: 'glacc_name_eng' },
                  {
                     data: 'gl_tra_debit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  {
                     data: 'gl_tra_credit',
                     render: function (data, type, row) {
                        return type === 'display' || type === 'filter'
                           ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                             })}</span>`
                           : data
                     },
                  },
                  { data: 'gl_tra_description' },
                  { data: 'gl_tra_date' },
                  { data: 'brach_name' },
                  { data: 'user_fname' },
               ],
               lengthMenu: [
                  [10, 25, 50, 10000000],
                  ['10 rows', '25 rows', '50 rows', 'Show all'],
               ],
            }
            // Modify your dataTableOptions to include initComplete
            dataTableOptions.initComplete = function (settings, json) {
               // Check if json exists and contains recordsTotal
               if (json && json.recordsTotal) {
                  // Access the recordsTotal value from the json response
                  const totalRecords = json.recordsTotal
               }
            }

            // ? Initialize DataTable
            $('#transaction_table').DataTable(dataTableOptions)
         }
      })
   },
   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // ?  Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },
   },
}
</script>

<style lang=""></style>
