import UserManagement from '../pages/Users/UserManagement.vue'

const UserRoutes = [
   // ? view user management page view rout
   {
      path: '/user-management',
      name: '/user-management',
      component: UserManagement,
      meta: { requiresAuth: true },
   },
]

export default UserRoutes
