<template lang="">
   <div>
      <div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Doctors</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div>
                              <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target=".bd-example-modal-lg">
                                 Add New Doctor's Details
                              </button>
                           </div>
                           <div class="table-responsive mt-3 mb-2">
                              <table id="doctor_data_table" class="table table-hover table-bordered">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Name</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Email</th>
                                       <th>Rate</th>
                                       <th>Other</th>
                                       <th>Edit</th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Name</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Email</th>
                                       <th>Rate</th>
                                       <th>Other</th>
                                       <th>Edit</th>
                                       <th>Status</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Add New Doctor</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="add_doctor_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="name_tbx" class="font-weight-bold"> Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Name" id="name_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Location" id="location_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Contact" id="contact_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="email_tbx" class="font-weight-bold"> Email </label>
                                    <input type="email" class="form-control form-control-sm" placeholder="Email" id="email_tbx" />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="commission_rate_tbx" class="font-weight-bold">
                                       Commission Rate <span class="text-danger">*</span></label
                                    >
                                    <input
                                       type="number"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       class="form-control form-control-sm"
                                       placeholder="0"
                                       id="commission_rate_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="commission_other_rate_tbx" class="font-weight-bold">
                                       Commission Rate (Other Lab) <span class="text-danger">*</span></label
                                    >
                                    <input
                                       type="number"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       class="form-control form-control-sm"
                                       placeholder="0"
                                       id="commission_other_rate_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-sm btn-primary" type="submit" id="doctor_add_btn" value="Submit" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="update_doctor_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Update Existing Doctor</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="update_doctor_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_name_tbx" class="font-weight-bold"> Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Name" id="upd_name_tbx" required />
                                    <input
                                       type="hidden"
                                       class="form-control form-control-sm"
                                       placeholder="First Name"
                                       id="upd_doctor_id_tbx"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Location" id="upd_location_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Contact" id="upd_contact_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_email_tbx" class="font-weight-bold"> Email </label>
                                    <input type="email" class="form-control form-control-sm" placeholder="Email" id="upd_email_tbx" />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_commission_rate_tbx" class="font-weight-bold">
                                       Commission Rate <span class="text-danger">*</span></label
                                    >
                                    <input
                                       type="number"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       class="form-control form-control-sm"
                                       placeholder="0"
                                       id="upd_commission_rate_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_commission_other_rate_tbx" class="font-weight-bold">
                                       Commission Rate (Other Lab) <span class="text-danger">*</span></label
                                    >
                                    <input
                                       type="number"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       class="form-control form-control-sm"
                                       placeholder="0"
                                       id="upd_commission_other_rate_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_status_cmb" class="font-weight-bold">Status <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="upd_status_cmb" required>
                                       <option value="Y">Active</option>
                                       <option value="N">Block</option>
                                    </select>
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-sm btn-primary" type="submit" id="doctor_update_btn" value="Update" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
// Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'doctor', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-doctor', ['active'])

      // ? Load data table when the page loads
      this.$nextTick(() => {
         this.load_data_table()
      })

      // ? Attach click event to the add doctor button using jQuery
      $('#doctor_add_btn').on('click', () => {
         const form = document.getElementById('add_doctor_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#doctor_add_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const name = $('#name_tbx').val()
            const location = $('#location_tbx').val()
            const contact = $('#contact_tbx').val()
            const email = $('#email_tbx').val()
            const commission_rate = $('#commission_rate_tbx').val()
            const commission_other_rate = $('#commission_other_rate_tbx').val()

            // ? Create doctor data object
            const doctorData = {
               doct_name: name,
               doct_location: location,
               doct_contact: contact,
               doct_email: email,
               doct_commission_rate: commission_rate,
               doct_other_commission_rate: commission_other_rate,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}doctor/`,
               type: 'POST',
               contentType: 'application/json',
               data: JSON.stringify(doctorData),
               success: response => {
                  // ? Reset the form
                  $('#add_doctor_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'New doctor added successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#doctor_add_btn').prop('disabled', false).val('Submit')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#doctor_add_btn').prop('disabled', false).val('Submit')
               },
            })
         }
      })

      // ? data table update button click event handler
      $('#doctor_data_table').on('click', '#edit_btn', function () {
         // ? Get the data-id attribute of the clicked button
         var doctorId = $(this).data('id')

         // ? get doctor data to edit and update
         get_doctor_details(doctorId)

         const modalElement = document.getElementById('update_doctor_model')
         const modal = new bootstrap.Modal(modalElement)
         modal.show()
      })

      // ? get doctor data to edit and update
      function get_doctor_details(doct_id) {
         $.ajax({
            url: `${API_URL}` + 'doctor/' + doct_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#upd_doctor_id_tbx').val(doct_id)
               $('#upd_name_tbx').val(data['doct_name'])
               $('#upd_location_tbx').val(data['doct_location'])
               $('#upd_contact_tbx').val(data['doct_contact'])
               $('#upd_email_tbx').val(data['doct_email'])
               $('#upd_commission_rate_tbx').val(data['doct_commission_rate'])
               $('#upd_commission_other_rate_tbx').val(data['doct_other_commission_rate'])
               $('#upd_status_cmb').val(data['doct_active_status'])
            },
         })
      }

      // ? update doctor data click button handler
      $('#doctor_update_btn').on('click', () => {
         const form = document.getElementById('update_doctor_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#doctor_update_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const doctor_id = $('#upd_doctor_id_tbx').val()
            const name = $('#upd_name_tbx').val()
            const location = $('#upd_location_tbx').val()
            const contact = $('#upd_contact_tbx').val()
            const email = $('#upd_email_tbx').val()
            const commission_rate = $('#upd_commission_rate_tbx').val()
            const commission_other_rate = $('#upd_commission_other_rate_tbx').val()
            const status = $('#upd_status_cmb').val()

            // ? Create doctor data object
            const doctorData = {
               doct_name: name,
               doct_location: location,
               doct_contact: contact,
               doct_email: email,
               doct_commission_rate: commission_rate,
               doct_other_commission_rate: commission_other_rate,
               doct_active_status: status,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}doctor/` + doctor_id,
               type: 'PUT',
               contentType: 'application/json',
               data: JSON.stringify(doctorData),
               success: response => {
                  // ? Reset the form
                  $('#update_doctor_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'Doctor data updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#doctor_update_btn').prop('disabled', false).val('Update')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#doctor_update_btn').prop('disabled', false).val('Update')
               },
            })
         }
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },

      load_data_table() {
         // ? DataTable options
         const dataTableOptions = {
            destroy: true,
            processing: true,
            serverSide: true,
            ajax: {
               url: `${API_URL}doctor/`,
               type: 'GET',
            },
            columns: [
               { data: 'doct_id' },
               { data: 'doct_name' },
               { data: 'doct_location' },
               { data: 'doct_contact' },
               { data: 'doct_email' },
               {
                  data: 'doct_commission_rate',
                  render: function (data, type, row) {
                     if (type === 'display' || type === 'filter') {
                        // Check if Bootstrap classes are applied correctly
                        return '<span class="pull-right">' + parseFloat(data).toFixed(2) + ' %' + '</span>'
                     }
                     return data
                  },
               },
               {
                  data: 'doct_other_commission_rate',
                  render: function (data, type, row) {
                     if (type === 'display' || type === 'filter') {
                        // Check if Bootstrap classes are applied correctly
                        return '<span class="pull-right" >' + parseFloat(data).toFixed(2) + ' %' + '</span>'
                     }
                     return data
                  },
               },
               {
                  render: function (data, type, row) {
                     // ? You can customize the button or link here
                     return (
                        '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                        row.doct_id +
                        '" ><i class="fa-solid fa-pen-to-square"></i></button>'
                     )
                  },
               },
               {
                  data: 'doct_active_status',
                  render: function (data, type, row) {
                     if (data === 'Y') {
                        return '<span class="badge badge-success">Active</span>'
                     } else if (data === 'N') {
                        return '<span class="badge badge-danger">Blocked</span>'
                     } else {
                        // Handle other cases if needed
                        return data
                     }
                  },
               },
            ],
            lengthMenu: [
               [10, 25, 50, 10000000],
               ['10 rows', '25 rows', '50 rows', 'Show all'],
            ],
         }

         // ? Initialize DataTable
         $('#doctor_data_table').DataTable(dataTableOptions)
      },
   },
}
</script>
<style lang=""></style>
