<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Lab Test</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div>
                              <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target=".bd-example-modal-lg">
                                 Add New Lab Test's Details
                              </button>
                              <br />
                           </div>
                           <div class="table-responsive mt-3 mb-2">
                              <table id="lab_test_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Edit</th>
                                       <th>Test</th>
                                       <th>Price</th>
                                       <th>Rate</th>
                                       <th>Income</th>
                                       <th>Lab</th>
                                       <th>Hospital</th>
                                       <th>Test Print Name</th>
                                       <th>Analyzer Machine</th>
                                       <th>Status</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Edit</th>
                                       <th>Test</th>
                                       <th>Price</th>
                                       <th>Rate</th>
                                       <th>Income</th>
                                       <th>Lab</th>
                                       <th>Hospital</th>
                                       <th>Test Print Name</th>
                                       <th>Analyzer Machine</th>
                                       <th>Status</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Add New Lab Test</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="add_lab_test_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="test_name_tbx" class="font-weight-bold">Test Name <span class="text-danger"> *</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Name" id="test_name_tbx" required />
                                    <div class="dropdown-menu mt-1 pl-3" id="searchResults" style="width: 100%"></div>
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="test_print_name_tbx" class="font-weight-bold">Print Name <span class="text-danger"> *</span> </label>
                                    <input
                                       type="text"
                                       class="form-control form-control-sm"
                                       placeholder="Print Name"
                                       id="test_print_name_tbx"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="test_s_name_tbx" class="font-weight-bold">Test Short Name <span class="text-danger"> *</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Short Name" id="test_s_name_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="test_analyzer_tbx" class="font-weight-bold">Test Category<span class="text-danger"> *</span> </label>
                                    <select class="form-control form-control-sm" id="test_category_cmb" name="test_category_cmb"> </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="test_analyzer_tbx" class="font-weight-bold"
                                       >Test Analyzer Machine<span class="text-danger"> *</span>
                                    </label>
                                    <select class="form-control form-control-sm" id="test_analyzer_tbx" name="test_analyzer_tbx"> </select>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-4 mb-3">
                                    <label for="price_tbx" class="font-weight-bold">Price <span class="text-danger">*</span> </label>
                                    <input
                                       type="number"
                                       class="form-control form-control-sm"
                                       min="0"
                                       max="1000000"
                                       step="0.01"
                                       id="price_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-4 mb-3">
                                    <label for="commission_rate_tbx" class="font-weight-bold"
                                       >Commission Rate <span class="text-danger">*</span>
                                    </label>
                                    <input
                                       type="number"
                                       class="form-control form-control-sm"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       id="commission_rate_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-4 mb-3">
                                    <label for="margin_tbx" class="font-weight-bold">Margin <span class="text-danger">*</span> </label>
                                    <input
                                       type="number"
                                       class="form-control form-control-sm"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       id="margin_tbx"
                                       value="0"
                                       readonly
                                    />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="hospital_cmb" class="font-weight-bold">Hospital <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="hospital_cmb" required> </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="lab_cmb" class="font-weight-bold">Lab <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="lab_cmb" required>
                                       <option value="A">Own Lab</option>
                                       <option value="O">Other Lab</option>
                                    </select>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="type_cmb" class="font-weight-bold">Type <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="type_cmb" required>
                                       <option value="T">Test</option>
                                       <option value="P">Profile</option>
                                    </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="test_completion_time_tbx" class="font-weight-bold"
                                       >Completion Time <span class="text-danger">*</span></label
                                    >
                                    <input
                                       type="text"
                                       class="form-control form-control-sm"
                                       placeholder="Completion Time"
                                       id="test_completion_time_tbx"
                                       required
                                    />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="sample_container_cmb" class="font-weight-bold"
                                       >Test Sample Container Type <span class="text-danger">*</span></label
                                    >
                                    <select class="form-control form-control-sm" id="sample_container_cmb" required> </select>
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-primary" type="submit" id="lab_test_add_btn" value="Submit" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="update_lab_test_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Update Existing Lab Test</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="update_lab_test_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_test_name_tbx" class="font-weight-bold">Test Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Name" id="upd_test_name_tbx" required />
                                    <input type="hidden" class="form-control" placeholder="First Name" id="upd_lab_test_id_tbx" required />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_test_print_name_tbx" class="font-weight-bold"
                                       >Print Name <span class="text-danger">*</span>
                                    </label>
                                    <input
                                       type="text"
                                       class="form-control form-control-sm"
                                       placeholder="Print Name"
                                       id="upd_test_print_name_tbx"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_test_s_name_tbx" class="font-weight-bold"
                                       >Test Short Name <span class="text-danger">*</span>
                                    </label>
                                    <input
                                       type="text"
                                       class="form-control form-control-sm"
                                       placeholder="Short Name"
                                       id="upd_test_s_name_tbx"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_test_analyzer_tbx" class="font-weight-bold"
                                       >Test Category<span class="text-danger"> *</span>
                                    </label>
                                    <select class="form-control form-control-sm" id="upd_test_category_cmb" name="upd_test_category_cmb"> </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_test_analyzer_tbx" class="font-weight-bold"
                                       >Test Analyzer Machine<span class="text-danger"> *</span>
                                    </label>
                                    <select class="form-control form-control-sm" id="upd_test_analyzer_tbx" name="upd_test_analyzer_tbx"> </select>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-4 mb-3">
                                    <label for="upd_price_tbx" class="font-weight-bold">Price <span class="text-danger">*</span> </label>
                                    <input
                                       type="number"
                                       class="form-control form-control-sm"
                                       min="0"
                                       max="1000000"
                                       step="0.01"
                                       id="upd_price_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-4 mb-3">
                                    <label for="upd_commission_rate_tbx" class="font-weight-bold"
                                       >Commission Rate <span class="text-danger">*</span>
                                    </label>
                                    <input
                                       type="number"
                                       class="form-control form-control-sm"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       id="upd_commission_rate_tbx"
                                       value="0"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-4 mb-3">
                                    <label for="upd_margin_tbx" class="font-weight-bold">Margin <span class="text-danger">*</span> </label>
                                    <input
                                       type="number"
                                       class="form-control form-control-sm"
                                       min="0"
                                       max="100"
                                       step="0.01"
                                       id="upd_margin_tbx"
                                       value="0"
                                       readonly
                                    />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_hospital_cmb" class="font-weight-bold">Hospital <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="upd_hospital_cmb" required> </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_lab_cmb" class="font-weight-bold">Lab <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="upd_lab_cmb" required>
                                       <option value="A">Own Lab</option>
                                       <option value="O">Other Lab</option>
                                    </select>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_type_cmb" class="font-weight-bold">Type <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="upd_type_cmb" required>
                                       <option value="T">Test</option>
                                       <option value="P">Profile</option>
                                    </select>
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_status_cmb" class="font-weight-bold">Status <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="upd_status_cmb" required>
                                       <option value="Y">Active</option>
                                       <option value="N">Blocked</option>
                                    </select>
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_test_completion_time_tbx" class="font-weight-bold"
                                       >Completion Time <span class="text-danger">*</span></label
                                    >
                                    <input
                                       type="text"
                                       class="form-control form-control-sm"
                                       placeholder="Completion Time"
                                       id="upd_test_completion_time_tbx"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_sample_container_cmb" class="font-weight-bold"
                                       >Test Sample Container Type <span class="text-danger">*</span></label
                                    >
                                    <select class="form-control form-control-sm" id="upd_sample_container_cmb" required> </select>
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-primary" type="submit" id="lab_test_update_btn" value="Submit" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
import Select2 from 'vue3-select2-component'
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5'

export default {
   emits: ['select2'],
   components: {
      Select2,
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'test', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-test', ['active'])

      // ? Load data table when the page loads
      this.$nextTick(() => {
         // ? view table data
         this.load_data_table()

         // ? view all lab test name
         get_lab_test_names()

         // ? get all hospital names and ids into select hospital dropdown
         get_hospital_names_id()

         // ? Function to get the details of the analyzer names and their IDs
         get_analyzer_details_names_id()

         // ? Function to get lab test sample container names and ids
         get_lab_test_sample_container_names_id()

         // ? Function to get lab test category names and ids
         get_lab_test_category_names_id()
      })

      const items = []

      // ? get lab test name to show in insert select box
      function get_lab_test_names() {
         $.ajax({
            url: `${API_URL}lab-test/view-all-test/`, // corrected the URL concatenation
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               // ? Extract and push each lab_test_name into the items array
               data.forEach(item => {
                  items.push(item.lab_test_name)
               })
            },
            error: function (error) {
               console.error('Error fetching lab test details:', error)
            },
         })
      }

      // ? get all hospital names and ids into select hospital dropdown
      function get_hospital_names_id() {
         $.ajax({
            url: `${API_URL}hospital/hospital-names/`, // corrected the URL concatenation
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               const selectElement = $('#hospital_cmb')
               const selectElement1 = $('#upd_hospital_cmb') // Get the select element by its ID

               // Clear any existing options
               //selectElement.empty()

               // Append each hospital as an option to the select element
               data.forEach(hospital => {
                  const option = $('<option></option>')
                     .attr('value', hospital.hos_id) // Set the value attribute of the option
                     .text(hospital.hos_name)

                  const option1 = $('<option></option>')
                     .attr('value', hospital.hos_id) // Set the value attribute of the option
                     .text(hospital.hos_name) // Set the text content of the option

                  selectElement.append(option) // Append the option to the select element
                  selectElement1.append(option1)
               })
            },
            error: function (error) {
               console.error('Error fetching hospital names:', error)
            },
         })
      }

      // ? Function to get the details of the analyzer names and their IDs
      function get_analyzer_details_names_id() {
         // Make an AJAX request to the API to get the details of the analyzer names and their IDs
         $.ajax({
            url: `${API_URL}lab-test/view-all-lab-test-analyzer-data/`,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               // Get the select elements where the analyzer names and IDs will be appended
               const selectElement = $('#test_analyzer_tbx')
               const selectElement1 = $('#upd_test_analyzer_tbx')

               // Clear any existing options
               //selectElement.empty()

               // Loop through the data and append the analyzer names and IDs to the select elements
               data.forEach(analyzer => {
                  // Create an option element with the analyzer ID and name
                  const option = $('<option></option>').attr('value', analyzer.test_analyzer_id).text(analyzer.test_analyzer_name)

                  const option1 = $('<option></option>').attr('value', analyzer.test_analyzer_id).text(analyzer.test_analyzer_name)

                  // Append the option element to the select elements
                  selectElement.append(option)
                  selectElement1.append(option1)
               })
            },
            error: function (error) {
               // Log any errors that occur during the AJAX request
               console.error('Error fetching analyzer details:', error)
            },
         })
      }

      // ? Function to get lab test category names and ids
      function get_lab_test_category_names_id() {
         // Make an AJAX request to the API to get all lab test category data
         $.ajax({
            url: `${API_URL}lab-test/view-all-lab-test-category-data/`,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               // Get the select elements to append the options to
               const selectElement = $('#test_category_cmb')
               const selectElement1 = $('#upd_test_category_cmb')

               // Loop through the data and create an option for each category
               data.forEach(category => {
                  const option = $('<option></option>').attr('value', category.lab_cat_id).text(category.lab_cat_name)

                  const option1 = $('<option></option>').attr('value', category.lab_cat_id).text(category.lab_cat_name)

                  // Append the options to the select elements
                  selectElement.append(option)
                  selectElement1.append(option1)
               })
            },
            error: function (error) {
               // Log any errors that occur
               console.error('Error fetching category names:', error)
            },
         })
      }

      // ? Function to get lab test sample container names and ids
      function get_lab_test_sample_container_names_id() {
         // Make an AJAX request to the API to get all lab test container type data
         $.ajax({
            url: `${API_URL}lab-test/view-all-lab-test-container-type-data/`,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               // Get the select elements to append the options to
               const selectElement = $('#sample_container_cmb')
               const selectElement1 = $('#upd_sample_container_cmb')

               // Loop through the data and create an option for each container sample
               data.forEach(container_sample => {
                  const option = $('<option></option>').attr('value', container_sample.lab_container_id).text(container_sample.lab_container_type)

                  const option1 = $('<option></option>').attr('value', container_sample.lab_container_id).text(container_sample.lab_container_type)

                  // Append the options to the select elements
                  selectElement.append(option)
                  selectElement1.append(option1)
               })
            },
            error: function (error) {
               // Log any errors that occur
               console.error('Error fetching container sample names:', error)
            },
         })
      }

      let timeoutId

      $('#test_name_tbx').on('input', function () {
         clearTimeout(timeoutId)
         timeoutId = setTimeout(() => {
            const query = $(this).val().toLowerCase()
            const matchedItems = items.filter(item => item.toLowerCase().includes(query))
            displayResults(matchedItems)
         }, 300)
      })

      $('#test_name_tbx').on('blur', function () {
         // ? Hide the dropdown when the input loses focus
         $('.dropdown-menu').removeClass('show')
      })

      function displayResults(matchedItems) {
         const resultsDiv = $('#searchResults')
         resultsDiv.empty()

         const itemsToShow = matchedItems.slice(0, 5) // Get the first 5 items

         if (itemsToShow.length) {
            itemsToShow.forEach(item => {
               const listItem = $('<a href="#" class="dropdown-item"></a>').text(item)
               listItem.on('click', function (e) {
                  e.preventDefault()
                  $('#test_name_tbx').val(item)

                  $('.dropdown-menu').removeClass('show')
               })
               resultsDiv.append(listItem)
            })
            $('.dropdown-menu').addClass('show')
         } else {
            $('.dropdown-menu').removeClass('show')
         }
      }

      // ?  price and rate input change event
      $('#price_tbx, #commission_rate_tbx').on('change input', function (event) {
         // Get the values from the input fields
         const priceValue = parseFloat($('#price_tbx').val()) || 0 // Ensure it's a number or default to 0
         const commissionRateValue = parseFloat($('#commission_rate_tbx').val()) || 0 // Ensure it's a number or default to 0

         // Perform the calculation
         const result = (priceValue / 100) * commissionRateValue

         // Display or use the result as needed
         $('#margin_tbx').val(result)
      })

      // ?  price and rate input change event
      $('#upd_price_tbx, #upd_commission_rate_tbx').on('change input', function (event) {
         // Get the values from the input fields
         const priceValue = parseFloat($('#upd_price_tbx').val()) || 0 // Ensure it's a number or default to 0
         const commissionRateValue = parseFloat($('#upd_commission_rate_tbx').val()) || 0 // Ensure it's a number or default to 0

         // Perform the calculation
         const result = (priceValue / 100) * commissionRateValue

         // Display or use the result as needed
         $('#upd_margin_tbx').val(result)
      })

      // ? Attach click event to the add lab_test button using jQuery
      $('#lab_test_add_btn').on('click', () => {
         const form = document.getElementById('add_lab_test_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#lab_test_add_btn').prop('disabled', true).val('wait...')

            // ? Extract values from various input fields
            const testName = $('#test_name_tbx').val()
            const testShortName = $('#test_s_name_tbx').val()
            const testPrintName = $('#test_print_name_tbx').val()
            const AnalyzerMachine = $('#test_analyzer_tbx').val()
            const price = $('#price_tbx').val()
            const commissionRate = $('#commission_rate_tbx').val()
            const hospital = $('#hospital_cmb').val()
            const lab = $('#lab_cmb').val()
            const type = $('#type_cmb').val()
            const category = $('#test_category_cmb').val()
            const container = $('#sample_container_cmb').val()
            const period = $('#test_completion_time_tbx').val()

            // ? Create an object with the extracted values
            const testData = {
               lab_test_name: testName,
               lab_test_short_name: testShortName,
               lab_test_print_name: testPrintName,
               lab_test_analyzer: AnalyzerMachine,
               lab_test_price: price,
               lab_test_code: type,
               lab_test_com_rate: commissionRate,
               lab_test_hospital_id: hospital,
               lab_test_type: lab,
               lab_test_variasion: type,
               lab_test_active: 'Y',
               lab_test_category: category,
               lab_test_container_type: container,
               lab_test_completion_period: period,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}lab-test/`,
               type: 'POST',
               contentType: 'application/json',
               data: JSON.stringify(testData),
               success: response => {
                  // ? Reset the form
                  $('#add_lab_test_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'New lab test added successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#lab_test_add_btn').prop('disabled', false).val('Submit')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#lab_test_add_btn').prop('disabled', false).val('Submit')
               },
            })
         }
      })

      // ? data table update button click event handler
      $('#lab_test_data_table').on('click', '#edit_btn', function () {
         // ? Get the data-id attribute of the clicked button
         var lab_testId = $(this).data('id')

         // ? get lab_test data to edit and update
         get_lab_test_details(lab_testId)

         const modalElement = document.getElementById('update_lab_test_model')
         const modal = new bootstrap.Modal(modalElement)
         modal.show()
      })

      // ? get lab test data to edit and update
      function get_lab_test_details(lab_test_id) {
         $.ajax({
            url: `${API_URL}` + 'lab-test/' + lab_test_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#upd_lab_test_id_tbx').val(lab_test_id)
               $('#upd_test_name_tbx').val(data['lab_test_name'])
               $('#upd_test_s_name_tbx').val(data['lab_test_short_name'])
               $('#upd_price_tbx').val(data['lab_test_price'])
               $('#upd_commission_rate_tbx').val(data['lab_test_com_rate'])
               $('#upd_margin_tbx').val(data['lab_test_profit'])
               $('#upd_hospital_cmb').val(data['lab_test_hospital_id'])
               $('#upd_lab_cmb').val(data['lab_test_type'])
               $('#upd_type_cmb').val(data['lab_test_variasion'])
               $('#upd_status_cmb').val(data['lab_test_active'])
               $('#upd_test_print_name_tbx').val(data['lab_test_print_name'])
               $('#upd_test_analyzer_tbx').val(data['lab_test_analyzer'])
               $('#upd_test_category_cmb').val(data['lab_test_category'])
               $('#upd_sample_container_cmb').val(data['lab_test_container_type'])
               $('#upd_test_completion_time_tbx').val(data['lab_test_completion_period'])
            },
         })
      }

      // ? update lab test data click button handler
      $('#lab_test_update_btn').on('click', () => {
         const form = document.getElementById('update_lab_test_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#lab_test_update_btn').prop('disabled', true).val('wait...')

            // ? Extract values from various input fields
            const testID = $('#upd_lab_test_id_tbx').val()
            const testName = $('#upd_test_name_tbx').val()
            const testShortName = $('#upd_test_s_name_tbx').val()
            const testPrintName = $('#upd_test_print_name_tbx').val()
            const AnalyzerMachine = $('#upd_test_analyzer_tbx').val()
            const price = $('#upd_price_tbx').val()
            const commissionRate = $('#upd_commission_rate_tbx').val()
            const hospital = $('#upd_hospital_cmb').val()
            const lab = $('#upd_lab_cmb').val()
            const type = $('#upd_type_cmb').val()
            const status = $('#upd_status_cmb').val()
            const category = $('#upd_test_category_cmb').val()
            const container = $('#upd_sample_container_cmb').val()
            const period = $('#upd_test_completion_time_tbx').val()

            // ? Create an object with the extracted values
            const testData = {
               lab_test_id: testID,
               lab_test_name: testName,
               lab_test_short_name: testShortName,
               lab_test_print_name: testPrintName,
               lab_test_analyzer: AnalyzerMachine,
               lab_test_price: price,
               lab_test_code: type,
               lab_test_com_rate: commissionRate,
               lab_test_hospital_id: hospital,
               lab_test_type: lab,
               lab_test_variasion: type,
               lab_test_active: status,
               lab_test_category: category,
               lab_test_container_type: container,
               lab_test_completion_period: period,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}lab-test/` + testID,
               type: 'PUT',
               contentType: 'application/json',
               data: JSON.stringify(testData),
               success: response => {
                  // ? Reset the form
                  $('#update_lab_test_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'Lab test data updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#lab_test_update_btn').prop('disabled', false).val('Update')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#lab_test_update_btn').prop('disabled', false).val('Update')
               },
            })
         }
      })

      $('#test_name_tbx').on('input', function () {
         var enteredText = $(this).val()
         $('#test_print_name_tbx').val(enteredText)
      })

      $('#upd_test_name_tbx').on('input', function () {
         var enteredText = $(this).val()
         $('#upd_test_print_name_tbx').val(enteredText)
      })
      // // ? add data to temp bill print data table
      // function insert_data_print_bill_temp(){

      // }
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },

      load_data_table() {
         // ? DataTable options
         const dataTableOptions = {
            destroy: true,
            processing: true,
            serverSide: true,
            ajax: {
               url: `${API_URL}lab-test/`,
               type: 'GET',
            },
            columns: [
               { data: 'lab_id' },
               {
                  render: function (data, type, row) {
                     // ? You can customize the button or link here
                     return (
                        '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                        row.lab_id +
                        '" ><i class="fa-solid fa-pen-to-square"></i></button>'
                     )
                  },
               },
               { data: 'lab_test_name' },
               {
                  data: 'lab_test_price',
                  render: function (data, type, row) {
                     return type === 'display' || type === 'filter'
                        ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                             minimumFractionDigits: 2,
                             maximumFractionDigits: 2,
                          })}</span>`
                        : data
                  },
               },
               {
                  data: 'lab_test_com_rate',
                  render: function (data, type, row) {
                     if (type === 'display' || type === 'filter') {
                        return '<span class="pull-right">' + parseFloat(data).toFixed(2) + ' %' + '</span>'
                     }
                     return data
                  },
               },
               {
                  data: 'lab_test_profit',
                  render: function (data, type, row) {
                     return type === 'display' || type === 'filter'
                        ? `<span class="pull-right">Rs. ${parseFloat(data).toLocaleString(undefined, {
                             minimumFractionDigits: 2,
                             maximumFractionDigits: 2,
                          })}</span>`
                        : data
                  },
               },
               {
                  data: 'lab_test_type',
                  render: function (data, type, row) {
                     if (data === 'O') {
                        return '<span class="badge badge-warning">Other Lab</span>'
                     } else if (data === 'A') {
                        return '<span class="badge badge-primary">Own Lab</span>'
                     } else {
                        // Handle other cases if needed
                        return data
                     }
                  },
               },
               { data: 'hos_name' },
               { data: 'lab_test_print_name' },
               { data: 'test_analyzer_name' },
               {
                  data: 'lab_test_active',
                  render: function (data, type, row) {
                     if (data === 'Y') {
                        return '<span class="badge badge-success">Active</span>'
                     } else if (data === 'N') {
                        return '<span class="badge badge-danger">Blocked</span>'
                     } else {
                        // Handle other cases if needed
                        return data
                     }
                  },
               },
            ],
            lengthMenu: [
               [10, 25, 50, 10000000],
               ['10 rows', '25 rows', '50 rows', 'Show all'],
            ],
         }

         // ? Initialize DataTable
         $('#lab_test_data_table').DataTable(dataTableOptions)
      },
   },
}
</script>
<style lang=""></style>
