export const dateTimeMixin = {
   methods: {
      getCurrentDateTime() {
         const options = {
            timeZone: 'Asia/Colombo', // Set the time zone to Colombo, Sri Lanka
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
         }
         const formattedDateTime = new Date().toLocaleString('en-US', options)
         return formattedDateTime.replace(
            /(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/,
            '$3-$1-$2 $4:$5:$6'
         )
      },

      getCurrentDate() {
         const options = {
            timeZone: 'Asia/Colombo', // Set the time zone to Colombo, Sri Lanka
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
         }
         const formattedDate = new Date().toLocaleString('en-US', options)
         return formattedDate.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
      },

      getDateWithOffset(offset) {
         const options = {
            timeZone: 'Asia/Colombo', // Set the time zone to Colombo, Sri Lanka
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
         }

         const currentDate = new Date()
         currentDate.setDate(currentDate.getDate() + offset)

         const formattedDate = currentDate.toLocaleString('en-US', options)
         return formattedDate.replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
      },

      getCurrentTime() {
         const options = {
            timeZone: 'Asia/Colombo', // Set the time zone to Colombo, Sri Lanka
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
         }
         const formattedTime = new Date().toLocaleString('en-US', options)
         return formattedTime
      },

      getCurrentTimeS() {
         const options = {
            timeZone: 'Asia/Colombo', // Set the time zone to Colombo, Sri Lanka
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
         }
         const formattedTime = new Date().toLocaleString('en-US', options)
         return formattedTime
      },
   },
}
