<template lang="">
   <div
      ><div class="wrapper">
         <!-- Sidebar  -->
         <LayoutManu></LayoutManu>

         <!-- Page Content  -->
         <div id="content-page" class="content-page">
            <!-- Page Content  -->
            <LayoutNavbar></LayoutNavbar>

            <div class="container-fluid">
               <div class="row">
                  <div class="col-sm-12">
                     <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Manage Patients</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <div>
                              <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target=".bd-example-modal-lg">
                                 Add New Patient's Details
                              </button>
                              <br />
                           </div>
                           <div class="table-responsive mt-3 mb-2">
                              <table id="patient_data_table" class="table table-hover table-bordered display nowrap">
                                 <thead>
                                    <tr>
                                       <th>ID</th>
                                       <th>Edit</th>
                                       <th>Name</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Email</th>
                                       <th>Birthday</th>
                                       <th>Age</th>
                                       <th>Gender</th>
                                    </tr>
                                 </thead>
                                 <tbody> </tbody>
                                 <tfoot>
                                    <tr>
                                       <th>ID</th>
                                       <th>Edit</th>
                                       <th>Name</th>
                                       <th>Location</th>
                                       <th>Contact</th>
                                       <th>Email</th>
                                       <th>Birthday</th>
                                       <th>Age</th>
                                       <th>Gender</th>
                                    </tr>
                                 </tfoot>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Add New Patient</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="add_patient_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="name_tbx" class="font-weight-bold"> Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Name" id="name_tbx" required />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Location" id="location_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Contact" id="contact_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="email_tbx" class="font-weight-bold"> Email </label>
                                    <input type="email" class="form-control form-control-sm" placeholder="Email" id="email_tbx" />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="birthday_tbx" class="font-weight-bold"> Birthday <span class="text-danger">*</span> </label>
                                    <input
                                       type="date"
                                       class="form-control form-control-sm"
                                       placeholder="Contact"
                                       id="birthday_tbx"
                                       :value="getCurrentDate()"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="gender_cmb" class="font-weight-bold">Gender <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="gender_cmb" required>
                                       <option value="M">Male</option>
                                       <option value="F">Female</option>
                                    </select>
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-sm btn-primary" type="submit" id="patient_add_btn" value="Submit" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="modal fade bd-example-modal-lg" id="update_patient_model" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                     <div class="modal-content">
                        <div class="modal-header">
                           <h4 class="modal-title font-weight-bold">Update Existing patient</h4>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <form class="needs-validation" id="update_patient_data_form" onsubmit="return false" novalidate>
                              <div class="form-row">
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_name_tbx" class="font-weight-bold"> Name <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Name" id="upd_name_tbx" required />
                                    <input
                                       type="hidden"
                                       class="form-control form-control-sm"
                                       placeholder="First Name"
                                       id="upd_patient_id_tbx"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-12 mb-3">
                                    <label for="upd_location_tbx" class="font-weight-bold"> Location <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Location" id="upd_location_tbx" required />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_contact_tbx" class="font-weight-bold"> Contact <span class="text-danger">*</span> </label>
                                    <input type="text" class="form-control form-control-sm" placeholder="Contact" id="upd_contact_tbx" required />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_email_tbx" class="font-weight-bold"> Email </label>
                                    <input type="email" class="form-control form-control-sm" placeholder="Email" id="upd_email_tbx" />
                                 </div>
                              </div>
                              <div class="form-row">
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_birthday_tbx" class="font-weight-bold"> Birthday <span class="text-danger">*</span> </label>
                                    <input
                                       type="date"
                                       class="form-control form-control-sm"
                                       placeholder="Contact"
                                       id="upd_birthday_tbx"
                                       :value="getCurrentDate()"
                                       required
                                    />
                                 </div>
                                 <div class="col-md-6 mb-3">
                                    <label for="upd_gender_cmb" class="font-weight-bold">Gender <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" id="upd_gender_cmb" required>
                                       <option value="M">Male</option>
                                       <option value="F">Female</option>
                                    </select>
                                 </div>
                              </div>
                              <div class="modal-footer">
                                 <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal"> Close </button>
                                 <input class="btn btn-sm btn-primary" type="submit" id="patient_update_btn" value="Update" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <LayoutFooterVue></LayoutFooterVue>
         </div>
      </div>
   </div>
</template>
<script>
// ? Import the LayoutMenu component
import LayoutManu from '../../components/LayoutManu.vue'
import LayoutNavbar from '../../components/LayoutNavbar.vue'
import LayoutFooterVue from '../../components/LayoutFooter.vue'

import $ from 'jquery'
/* eslint-disable */
// ? import config file
import { API_URL } from '../../config/config.js'
import { dateTimeMixin } from '../../config/datetime'

import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import DataTable from 'datatables.net-vue3'
import '../../assets/css/bootstrap.min.css' // Bootstrap 5 CSS
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css' // DataTables with Bootstrap 5 CSS
import 'datatables.net-bs5' // DataTables with Bootstrap 5 JS

export default {
   mixins: [dateTimeMixin],
   components: {
      LayoutManu,
      LayoutNavbar,
      LayoutFooterVue,
   },
   mounted() {
      this.updateElementClasses('main', 'patient', ['active', 'main-active'])
      this.updateElementClasses('sub', 'add-patient', ['active'])

      // ? Load data table when the page loads
      this.$nextTick(() => {
         this.load_data_table()
      })

      // ? Attach click event to the add patient button using jQuery
      $('#patient_add_btn').on('click', () => {
         const form = document.getElementById('add_patient_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#patient_add_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const name = $('#name_tbx').val()
            const location = $('#location_tbx').val()
            const contact = $('#contact_tbx').val()
            const email = $('#email_tbx').val()
            const birthday = $('#birthday_tbx').val()
            const gender = $('#gender_cmb').val()

            // ? Create patient data object
            const patientData = {
               pat_name: name,
               pat_city: location,
               pat_contact: contact,
               pat_email: email,
               pat_birthday: birthday,
               pat_gender: gender,
               pat_branch: window.globalData.branchId,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}patient/`,
               type: 'POST',
               contentType: 'application/json',
               data: JSON.stringify(patientData),
               success: response => {
                  // ? Reset the form
                  $('#add_patient_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'New patient added successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#patient_add_btn').prop('disabled', false).val('Submit')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#patient_add_btn').prop('disabled', false).val('Submit')
               },
            })
         }
      })

      // ? data table update button click event handler
      $('#patient_data_table').on('click', '#edit_btn', function () {
         // ? Get the data-id attribute of the clicked button
         var patientId = $(this).data('id')

         // ? get patient data to edit and update
         get_patient_details(patientId)

         const modalElement = document.getElementById('update_patient_model')
         const modal = new bootstrap.Modal(modalElement)
         modal.show()
      })

      // ? get patient data to edit and update
      function get_patient_details(pat_id) {
         $.ajax({
            url: `${API_URL}` + 'patient/' + pat_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               $('#upd_patient_id_tbx').val(pat_id)
               $('#upd_name_tbx').val(data['pat_name'])
               $('#upd_location_tbx').val(data['pat_city'])
               $('#upd_contact_tbx').val(data['pat_contact'])
               $('#upd_email_tbx').val(data['pat_email'])
               $('#upd_birthday_tbx').val(data['pat_birthday'])
               $('#upd_gender_cmb').val(data['pat_gender'])
            },
         })
      }

      // ? update patient data click button handler
      $('#patient_update_btn').on('click', () => {
         const form = document.getElementById('update_patient_data_form')
         const isValid = form.checkValidity()

         if (isValid) {
            $('#patient_update_btn').prop('disabled', true).val('wait...')

            // ? Get values from the input fields
            const patient_id = $('#upd_patient_id_tbx').val()
            const name = $('#upd_name_tbx').val()
            const location = $('#upd_location_tbx').val()
            const contact = $('#upd_contact_tbx').val()
            const email = $('#upd_email_tbx').val()
            const birthday = $('#upd_birthday_tbx').val()
            const gender = $('#upd_gender_cmb').val()

            // ? Create patient data object
            const patientData = {
               pat_name: name,
               pat_city: location,
               pat_contact: contact,
               pat_email: email,
               pat_birthday: birthday,
               pat_gender: gender,
            }

            // ? Make the POST request using $.ajax()
            $.ajax({
               url: `${API_URL}patient/` + patient_id,
               type: 'PUT',
               contentType: 'application/json',
               data: JSON.stringify(patientData),
               success: response => {
                  // ? Reset the form
                  $('#update_patient_data_form')[0].reset()

                  // ? Show success message
                  Swal.fire({
                     icon: 'success',
                     title: 'Patient data updated successfully!',
                     text: 'well done..',
                     timer: 2000,
                     showConfirmButton: true,
                     allowOutsideClick: true,
                  })

                  // ? Enable the button
                  $('#patient_update_btn').prop('disabled', false).val('Update')

                  // ? Reload the data table
                  this.load_data_table()

                  $('[data-dismiss="modal"]').click()
               },
               error: error => {
                  // ? Handle error
                  $('#patient_update_btn').prop('disabled', false).val('Update')
               },
            })
         }
      })
   },

   methods: {
      updateElementClasses(id, dataId, classesToRemove) {
         // Select li elements with specified id and data-id
         var elements = document.querySelectorAll(`li[id="${id}"][data-id="${dataId}"]`)

         // Select other li elements with specified id and remove the classes
         var otherElements = document.querySelectorAll(`li[id="${id}"]:not([data-id="${dataId}"])`)

         // Loop through each other element
         otherElements.forEach(function (otherElement) {
            // Remove the classes from other elements
            classesToRemove.forEach(function (className) {
               otherElement.classList.remove(className)
            })
         })

         // Loop through each element
         elements.forEach(function (element) {
            // Add the desired classes to the selected element
            classesToRemove.forEach(function (className) {
               element.classList.add(className)
            })
         })
      },

      load_data_table() {
         // ? DataTable options
         const dataTableOptions = {
            destroy: true,
            processing: true,
            serverSide: true,
            ajax: {
               url: `${API_URL}patient/`,
               type: 'GET',
            },
            columns: [
               { data: 'pat_id' },
               {
                  render: function (data, type, row) {
                     // ? You can customize the button or link here
                     return (
                        '<button class="btn btn-success btn-sm" id="edit_btn" data-id="' +
                        row.pat_id +
                        '" ><i class="fa-solid fa-pen-to-square"></i></button>'
                     )
                  },
               },
               { data: 'pat_name' },
               { data: 'pat_city' },
               { data: 'pat_contact' },
               { data: 'pat_email' },
               { data: 'pat_birthday' },
               {
                  data: 'pat_age',
               },
               {
                  data: 'pat_gender',
                  render: function (data, type, row) {
                     if (data === 'F') {
                        return '<i class="fa-sharp fa-solid fa-person-dress text-success mr-2"></i><span class="badge badge-success">Female</span>'
                     } else if (data === 'M') {
                        return '<i class="fa-sharp fa-solid fa-person text-danger mr-2"></i><span class="badge badge-danger">Male</span>'
                     } else {
                        // Handle other cases if needed
                        return data
                     }
                  },
               },
            ],
            lengthMenu: [
               [10, 25, 50, 10000000],
               ['10 rows', '25 rows', '50 rows', 'Show all'],
            ],
         }

         // ? Initialize DataTable
         $('#patient_data_table').DataTable(dataTableOptions)
      },
   },
}
</script>
<style lang=""></style>
