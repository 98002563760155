import HospitalManagement from '../pages/hospitals/HospitalManagement.vue'
import HospitalAllView from '../pages/hospitals/ViewAllHospitals.vue'
import HospitalLabTestTransfer from '../pages/hospitals/HospitalLabTestTransfer.vue'

const HospitalRoutes = [
   // ? hospital management page show rout
   {
      path: '/hospital-management',
      name: '/hospital-management',
      component: HospitalManagement,
      meta: { requiresAuth: true },
   },

   // ? view hospital lab test transfer page show rout
   {
      path: '/hospital-lab-test-transfer',
      name: '/hospital-lab-test-transfer',
      component: HospitalLabTestTransfer,
      meta: { requiresAuth: true },
   },

   // ? view all hospital page show rout
   {
      path: '/view-all-hospitals',
      name: '/view-all-hospitals',
      component: HospitalAllView,
      meta: { requiresAuth: true },
   },
]

export default HospitalRoutes
