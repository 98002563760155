<template lang="">
   <section class="sign-in-page">
      <div class="container sign-in-page-bg mt-5 p-0">
         <div class="row no-gutters">
            <div class="col-md-6 text-center">
               <div class="sign-in-detail text-white">
                  <h2 class="text-light">Hashloop IT Solutions</h2>
                  <a class="sign-in-logo mb-5" href="#"><img src="Assets/images/logo-white.png" class="img-fluid" alt="logo" /></a>
                  <div
                     class="owl-carousel"
                     data-autoplay="true"
                     data-loop="true"
                     data-nav="false"
                     data-dots="true"
                     data-items="1"
                     data-items-laptop="1"
                     data-items-tab="1"
                     data-items-mobile="1"
                     data-items-mobile-sm="1"
                     data-margin="0"
                  >
                     <div class="item">
                        <img src="Assets/images/login/1.png" class="img-fluid mb-4" alt="logo" />
                        <h4 class="mb-1 text-white">Manage your orders</h4>
                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                     </div>
                     <div class="item">
                        <img src="Assets/images/login/2.png" class="img-fluid mb-4" alt="logo" />
                        <h4 class="mb-1 text-white">Manage your orders</h4>
                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                     </div>
                     <div class="item">
                        <img src="Assets/images/login/3.png" class="img-fluid mb-4" alt="logo" />
                        <h4 class="mb-1 text-white">Manage your orders</h4>
                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-6 position-relative">
               <div class="sign-in-from">
                  <h1 class="mb-0">Sign in</h1>
                  <p>Enter your user name and password to access the system</p>
                  <form class="needs-validation mt-4" onsubmit="return false" novalidate>
                     <div class="form-row">
                        <div class="col-md-12 mb-3">
                           <label for="user_name_tbx">User Name</label>
                           <input type="text" class="form-control" id="user_name_tbx" required />
                           <div class="invalid-tooltip"> Please provide a valid user name. </div>
                        </div>
                        <div class="col-md-12 mb-3">
                           <label for="password_tbx">Password</label>
                           <input type="password" class="form-control" id="password_tbx" required />
                           <div class="invalid-tooltip"> Please provide a valid password. </div>
                        </div>
                     </div>
                     <div class="alert text-white bg-danger d-none" role="alert">
                        <div class="iq-alert-icon">
                           <i class="fa-solid fa-square-exclamation"></i>
                        </div>
                        <div class="iq-alert-text text-center"><b>User Name or Password </b> is incorrect.!</div>
                     </div>
                     <div class="d-inline-block w-100">
                        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                           <input type="checkbox" class="custom-control-input" id="customCheck1" />
                           <label class="custom-control-label" for="customCheck1">Remember Me</label>
                        </div>
                        <button type="submit" id="login_btn" class="btn btn-primary float-right">Login</button>
                     </div>
                     <div class="sign-info">
                        <span class="dark-color d-inline-block line-height-2">Don't have an issue? Contact us.</span>
                        <ul class="iq-social-media">
                           <li
                              ><a href="https://www.facebook.com/hashloop/" target="_blank"><i class="fa-brands fa-square-facebook"></i></a
                           ></li>
                           <li
                              ><a href="https://www.instagram.com/hashloop/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a
                           ></li>
                           <li
                              ><a href="https://www.linkedin.com/company/hashloop-it-solutions" target="_blank"
                                 ><i class="fa-brands fa-linkedin"></i></a
                           ></li>
                           <li
                              ><a href="tel:0703312480" target="_blank"><i class="fa-solid fa-square-phone"></i></a
                           ></li>
                        </ul>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>
<script>
// ? import jquery
import $ from 'jquery'
/* eslint-disable */

// ? import config file
import { API_URL } from '../config/config.js'

import store from '../config/store.js'
// ${API_URL}

export default {
   name: 'LoginPage',
   mounted() {
      const vm = this
      // Update the Vuex store states
      vm.$store.commit('setAuthenticated', false)

      // ? Attach click event to the login button using jQuery
      $('#login_btn').on('click', () => {
         // Get values from the input fields
         var userName = $('#user_name_tbx').val()
         var password = $('#password_tbx').val()
         // Make an AJAX request to the API
         // Validate inputs
         if (userName && password) {
            $.ajax({
               url: `${API_URL}` + 'users/login',
               method: 'POST',
               contentType: 'application/json',
               data: JSON.stringify({
                  user_id: userName,
                  user_password: password,
               }),
               success: response => {
                  // Handle the API response here
                  get_user_details(userName)
               },
               error: error => {
                  $('.alert.bg-danger').removeClass('d-none')
               },
            })
         }
      })

      function get_user_details(usr_id) {
         var user_fname = ''
         var user_gender = ''
         var user_id = ''
         var user_type = ''

         $.ajax({
            url: `${API_URL}` + 'users/' + usr_id, // replace with the actual API endpoint
            method: 'GET',
            dataType: 'json',
            success: function (data) {
               user_fname = data['user_fname']
               user_gender = data['user_gender']
               user_id = data['user_id']
               user_type = data['user_type']

               const userData = {
                  id: user_id,
                  username: user_fname,
                  gender: user_gender,
                  branchId: user_gender,
                  userType: user_type,
               }

               // Update the Vuex store states
               vm.$store.commit('setAuthenticated', true)
               vm.$store.commit('setUser', userData)

               vm.$router.push('/day-open')
            },
         })
      }

      // Text change event for both user name and password inputs
      $('#user_name_tbx, #password_tbx').on('input', function () {
         $('.alert.bg-danger').addClass('d-none')
      })
   },
}
</script>
<style lang=""></style>
