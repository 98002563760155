import CorrectionTransaction from '../pages/correction/CorrectionTransaction.vue'

const CorrectionRoutes = [
   // ? correction transaction show rout
   {
      path: '/add-correction',
      name: '/add-correction',
      component: CorrectionTransaction,
      meta: { requiresAuth: true },
   },
]

export default CorrectionRoutes
